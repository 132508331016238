import { createSelector } from 'reselect';
import { groupBy,isEmpty } from 'lodash';
import { isEmailValid, getCategories } from '../utils';

// Dumb selectors
export const getComponentsById = ({requests}) => requests.componentsById;
export const getBoMsByName = ({requests}) => requests.bomsByName;
export const getSelectedComponentsIds = ({requests}) => requests.selectedComponentsIds;
export const getUserEmail = ({requests}) => requests.userEmail;

// Memoized selectors
export const getComponents = createSelector(
  [getComponentsById],
  (componentsById) => Object.values(componentsById).sort((a,b) =>
    (a.blockId > b.blockId) ? 1 : (a.blockId < b.blockId) ? -1 : 0) // Sort by id
)

export const getComponentsGroupedByFilters = createSelector(
  [getComponents],
  (components) => {
    return components.reduce((res, curr)=> { 
      let cats = getCategories(curr);
      for (var cat of cats) {
        if (!res[cat]) res[cat] = [];
        res[cat].push(curr);
      }
      return res;
    }, {});
  }
)

export const getBoms = createSelector(
  [getBoMsByName],
  (bomsByName) => Object.values(bomsByName)
)

export const getSelectedComponents = createSelector(
  [getComponentsById, getSelectedComponentsIds],
  (componentsByIds, selectedComponentsIds) => {
    return selectedComponentsIds.map((id) => componentsByIds[id]);
  }
)

export const getSelectedComponentsGroupedByType = createSelector(
  [getSelectedComponents],
  (selectedComponents) => {
    var grouped = groupBy(selectedComponents, 'category');
    if(selectedComponents.includes(undefined)){
      return grouped;
    }
    var keys = Object.keys(grouped);
    var typeJson = {
      input:[],
      output:[],
      power:[],
      powerConnector:[],
      connectivity:[],
      controller:[]
    }
    keys.map( (item, indx) => {
      var types = item.split(',');
        for (var i = 0; i<types.length; i++){
          if(typeJson[types[i]]){
            typeJson[types[i]].push(grouped[item][0]);
          }
        }
    })
    return typeJson;
  }
)

export const getIsUserEmailValid = createSelector(
  [getUserEmail],
  (userEmail) => {
    return !userEmail || isEmailValid(userEmail);
  }
)

export const getIsSelectionValid = createSelector(
  [getSelectedComponentsGroupedByType],
  (selectedComponentsByType) => {
    return(
      !isEmpty(selectedComponentsByType['controller']) &&
      !isEmpty(selectedComponentsByType['power']) &&
      ((!isEmpty(selectedComponentsByType['input'])) || (!isEmpty(selectedComponentsByType['output'])))
    )
  }
)
