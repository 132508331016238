import React from 'react'

// TODO: This component still has it's own state.. We should move it to redux
class ComponentPin extends React.Component{

  _handlePinNameChange(e){
    this.props.updatePin({...this.props.pin, name: e.target.value})
  }

  _handlePinTypeChange(e){
    this.props.updatePin({...this.props.pin, type: e.target.value})
  }

  _handlePinVoltageChange(e){
    this.props.updatePin({...this.props.pin, voltage: e.target.value})
  }

  render() {
    return(
      <tr id="pinRow">
        <td>
          <input type="text" className="form-control" onChange={this._handlePinNameChange.bind(this)} 
            value={this.props.pin.name}/>
        </td>
        <td>
          <select className="form-control" id="pinType" value={this.props.pin.type}
            onChange={this._handlePinTypeChange.bind(this)}>
            <option value="PWM">PWM</option>
            <option value="Interrupt">Interrupt</option>
            <option value="DigitalIn">DigitalIn</option>
            <option value="DigitalOut">DigitalOut</option>
            <option value="ADC">ADC</option>
            <option value="Rx">Rx</option>
            <option value="Tx">Tx</option>
            <option value="GND">GND</option>
            <option value="VCC">VCC</option>
            <option value="SPI_MISO">SPI_MISO</option>
            <option value="SPI_MOSI">SPI_MOSI</option>
            <option value="SPI_SCK">SPI_SCK</option>
            <option value="SPI_SS">SPI_SS</option>
            <option value="I2C_SCL">I2C_SCL</option>
            <option value="I2C_SDA">I2C_SDA</option>
            <option value="value15">Item15</option>
          </select>
        </td>
        <td>
          <select className="form-control" id="Voltage" value={this.props.pin.voltage}
            onChange={this._handlePinVoltageChange.bind(this)}>
            <option value="5V">5V</option>
            <option value="3.3V">3.3V</option>
            <option value="12V">12V</option>
            <option value="9V">9V</option>
            <option value="7.4V">7.4V</option>
            <option value="(5V, 3.3V)">(5V, 3.3V)</option>
          </select>
        </td>
        <td>
          <a onClick={this.props.onRemovePin} className="btn btn-default" style={{color: 'red'}}>Remove</a>
        </td>
      </tr>
    );
  }
}


ComponentPin.displayName = "ComponentPin";
ComponentPin.propTypes = {
  pin: React.PropTypes.object,
  onRemovePin: React.PropTypes.func,
  updatePin: React.PropTypes.func,
}

export default ComponentPin;
