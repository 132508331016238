import React from 'react'

// TODO: This component still has it's own state.. We should move it to redux
class ComponentPeripheral extends React.Component{

  _handleValueChange(e){
    this.props.updatePeripheral({...this.props.peripheral, value: e.target.value})
  }

  _handleTypeChange(e){
    this.props.updatePeripheral({...this.props.peripheral, type: e.target.value})
  }

  _handlePinChange(e){
    this.props.updatePeripheral({...this.props.peripheral, pin: e.target.value})
  }

  render() {
    return(
      <tr id="pinRow">
        <td>
          <select className="form-control" name="pinType" id="peripheralTypes" value={this.props.peripheral.type}
            onChange={this._handleTypeChange.bind(this)}>
            <option value="Resistor">Resistor</option>
            <option value="Capacitor">Capacitor</option>
            <option value="Transistor">Transistor</option>
            <option value="Logic Level Converter">Logic Level Converter</option>
            <option value="External Power Supply">External Power Supply</option>
            <option value="Diode">Diode</option>
          </select>
        </td>
        <td>
          <input type="text" className="form-control" name="value" value={this.props.peripheral.value}
            onChange={this._handleValueChange.bind(this)} />
        </td>
        <td>
          <input type="text" className="form-control" name="pinName" value={this.props.peripheral.pin}
            onChange={this._handlePinChange.bind(this)} />
        </td>
        <td>
          <a onClick={this.props.onRemovePeripheral} className="btn btn-default" style={{color: 'red'}}>Remove</a>
        </td>
      </tr>
    );
  }
}


ComponentPeripheral.displayName = "ComponentPeripheral";
ComponentPeripheral.propTypes = {
  peripheral: React.PropTypes.object,
  onRemovePeripheral: React.PropTypes.func,
  updatePeripheral: React.PropTypes.func,
}

export default ComponentPeripheral;
