import React from 'react'
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import SearchBar from '../components/search_bar';
import InventoryComponent from '../components/inventory_component';
import { getInitialControllerName } from '../../utils';
import Btn from '../../app/components/form_controls/btn';

// Actions
import componentInfoModalOpen from '../actions/component_info_modal_open';
import newComponentModalOpen from '../actions/new_component_modal_open';
import searchInventory from '../actions/search_inventory';
import changeSearchBarFocus from '../actions/focus_search_bar';
import inventoryComponentDragStart from '../actions/inventory_component_drag_start';
import inventoryComponentDragEnd from '../actions/inventory_component_drag_end';
import filterInventoryType from '../actions/filter_inventory_type';
import editComponent from '../../component_requests/actions/open_edit_part_modal';

// Selectors
import { getComponents } from '../selectors';
import { getComponentsGroupedByFilters } from '../selectors';
import { getSelectedComponents } from '../selectors';
import { getSelectedComponentsGroupedByType } from '../selectors';

class Inventory extends React.Component{

  _resultTemplate(component, componentSize, ctrlName){
    let templateProps = {
      component,
      componentSize,
      ctrlName,
      onDragStart: this.props.onComponentDragStart,
      onDragEnd: this.props.onComponentDragEnd,
    }

    templateProps.onClick = () =>{
      this.props.onComponentClick(component, true);
    }

    return(
        <InventoryComponent {...templateProps} />
    )
  }

  

  _renderSuggestComponent(){
    return(<div className='inventory-footer' onClick={()=>{
            analyticsSimple('Suggest a Component', 'Click', 'button', 'Suggest a Component Click' , true); 
            this.props.onEditClick('components-list');
            }}>
          Create & Edit components
      </div>)
  }

  render(){
    let controller = this.props.blocksByName[getInitialControllerName()];
    let connectivity = null;

    if (this.props.selectedComponentsByType.controller) {
      controller = this.props.selectedComponentsByType.controller[0];
    }
    if (this.props.selectedComponentsByType.connectivity) {
      connectivity = this.props.selectedComponentsByType.connectivity[0];
    }

    return(
      <div className="main-side-bar line-seperator">
        <SearchBar items={this.props.components}
                 itemsByFilters={this.props.componentsByFilters}
                 filterByProps={['name','type','description']}
                 activeFilters={this.props.activeFilters}
                 onSetFilters={this.props.onFilterInventoryType}
                 valueProp='_id'
                 itemRenderer={this._resultTemplate.bind(this)}
                 resultsFooterRenderer={this._renderSuggestComponent.bind(this)}
                 inputValue={this.props.searchTerm}
                 controller={controller}
                 connectivity={connectivity}
                 selectedComponents={this.props.selectedComponents}
                 blocksByName={this.props.blocksByName}
                 onInputChange={this.props.onSearchTermChanged}
                 renderResults={this.props.isSideView || this.props.isSearchBarFocused}
                 isSideView={this.props.isSideView}
                 onFocusChange={this.props.onSearchBarFocusChanged}/>
      </div>
    )
  }
}
Inventory.displayName = 'Inventory';
Inventory.propTypes = {
  components: React.PropTypes.array.isRequired,
  onComponentClick: React.PropTypes.func,
  onComponentDragStart: React.PropTypes.func,
  onComponentDragEnd: React.PropTypes.func,
  onSuggestComponent: React.PropTypes.func,
  isSideView: React.PropTypes.bool,
  searchTerm: React.PropTypes.string,
  onSearchTermChanged: React.PropTypes.func,
  onSearchBarFocusChanged: React.PropTypes.func,
  isSearchBarFocused: React.PropTypes.bool,
  selectedComponentsByTags: React.PropTypes.object.isRequired,
  selectedComponents: React.PropTypes.array.isRequired,
  activeFilters: React.PropTypes.array.isRequired,
  onFilterInventoryType: React.PropTypes.func,
  blocksByName: React.PropTypes.object,
}

Inventory.defaultProps = {
  isSideView: true,
  isSearchBarFocused: false,
  activeFilters: [],
}

const mapStateToProps = (state) => {
  return{
    components: getComponents(state),
    componentsByFilters: getComponentsGroupedByFilters(state),
    searchTerm: state.requests.inventorySearchTerm,
    isSearchBarFocused: state.requests.isSearchBarFocused,
    selectedComponentsByType: getSelectedComponentsGroupedByType(state),
    selectedComponents: getSelectedComponents(state),
    blocksByName: state.requests.blocksByName,
    activeFilters: state.requests.inventoryTypeFilterArray,
  }
}

const mapDispatchToProps = {
  onComponentClick: componentInfoModalOpen,
  onSuggestComponent: newComponentModalOpen,
  onSearchTermChanged: searchInventory,
  onSearchBarFocusChanged: changeSearchBarFocus,
  onComponentDragStart: inventoryComponentDragStart,
  onComponentDragEnd: inventoryComponentDragEnd,
  onFilterInventoryType: filterInventoryType,
  onEditClick: editComponent,
}

export default connect(mapStateToProps, mapDispatchToProps)(Inventory);
