///
/// Tries to add a new component by validating first
///
import * as types from '../constants';
import validateComponents from './helpers/validate_components';
import { MAX_COMPONENTS_MAP } from '../../config';
import { getSelectedComponentsIds, getSelectedComponentsGroupedByType, getComponentsById } from '../selectors';
import showNotification, { NotificationTypes } from '../../app/actions/show_notification'
import { getCategories } from '../../utils';

/**
 * Adds a component to the current circuito
 * @param  {Object|Number} component A component object or its Id
 * @return {function} a function for redux-thunk middleware
 */
export default function(component){
  return (dispatch, getStateFunc) => {
    // validate IO size
    let selectedComponents = getSelectedComponentsGroupedByType(getStateFunc());
    let categories = getCategories(component);
    for (var category of categories) {
      // Check if we can add more components, if not, dispatch a message
      let selectedComponentsOfType = selectedComponents[category];
      if(selectedComponentsOfType && selectedComponentsOfType.length >= MAX_COMPONENTS_MAP[category]){
        // Display an alert message to the user
        dispatch(showNotification(
          NotificationTypes.NOTIFICATION_DANGER_TYPE,
          `Only ${MAX_COMPONENTS_MAP[category]} ${category} are allowed`,
          true
        ));

        analyticsSimple('Modify Component', 'Modify Component', 'AddTooMuch', component.name, true, true);
        return;
      }
    }
    
    analyticsSimple('Modify Component', 'Modify Component', 'Add', component.name, true, true);

    // combine selected components with the newly requested component, and validate them
    return validateComponents(dispatch, getStateFunc,
                              [...getSelectedComponentsIds(getStateFunc()), component.blockId], 
                              component, types.COMPONENT_ADDED)
    .catch((error)=> {});
  }
}
