import React from 'react';
import { connect } from 'react-redux';
import MaterialIcon from '../components/material_icon';
import Modal from '../components/modal';
import Btn from '../components/form_controls/btn';

// Actions
import notificationModalClose from '../actions/notification_modal_close';

class NotificationModal extends React.Component{
  constructor(props) {
    super(props);

    this._onClosed = this._onClosed.bind(this);
    this.isClosing = false;

    this.state = {
      isShowOutput: false,
      short: '',
      long: ''
    };
  }

  componentDidUpdate(prevProps){
    if(!prevProps.isOpen && this.props.isOpen && this.props.details){
      this.setState({
        short: (' ' + this.props.details.short).slice(1), // Deep-
        long: (' ' + this.props.details.long || '').slice(1),   // -Copy
      });
    }
  }

  _onClosed() {
    this.isClosing = false;
    this.props.onClosed();
  }

  render(){
    if (this.state.short === '') {
      return null;
    }

    return(
      <Modal modalClass="notifications"
             isOpen={this.props.isOpen && !this.isClosing}
             onClose={this._onClosed}
             fade={true}>
        <div className={`notification-modal modal-dialog modal-md
                          ${this.state.isShowOutput? 'output-visible' : ''}`}>
          <div className="modal-content">
            <div className="modal-header">
              <button id="modal-close" type="button" className="close"
                      data-dismiss="modal" aria-label="Close">
                <MaterialIcon name="close" />
              </button>
            </div>
            <div>
              <div className="modal-body">
                <div className="text-app-header-2-bold">
                  {this.props.details.short}
                </div>
                <br/>
                { this.state.isShowOutput ?
                  <div className="output-wrapper">
                    <div className="output text-app-body-2">
                      {this.state.long.split('\n').map((t)=> <div>{t}</div>)}
                    </div>
                  </div>
                : null }
                { this.state.long !== '' ? 
                  <button>
                    <Btn type="primary" isStretch={true} className='no-margin'
                         onClick={()=> this.setState({isShowOutput: !this.state.isShowOutput})}
                         label={ this.state.isShowOutput ? "HIDE OUTPUT" : "SHOW OUTPUT" } />
                  </button>
                : null }
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

NotificationModal.displayName = 'NotificationModal';
NotificationModal.propTypes = {
  isOpen: React.PropTypes.bool.isRequired,
  onClosed: React.PropTypes.func,
  details: React.PropTypes.object,
}

const mapStateToProps = (state)=> {
  return{
    isOpen: state.app.notificationModalIsOpen,
    details: state.app.notificationDetails,
  }
}

const mapDispatchToProps = {
  onClosed: notificationModalClose,
}

export default connect(mapStateToProps,mapDispatchToProps)(NotificationModal);
