import React from 'react';

const Loader = ({imgProps}) => {
  return(
    <div className='loader'>
      <img {...imgProps} src='/static/images/loader.svg' />
    </div>
  );
}

Loader.propTypes = {
  imgProps: React.PropTypes.object,
}

export default Loader;
