import * as types from '../constants';
import { getComponentRequests } from '../../api';

export default function(){
  // Fetch all components
  return (dispatch) => {
    dispatch({type: types.LOAD_COMPONENT_REQUESTS_REQUEST})
    return getComponentRequests()
      .then((componentRequests)=>{
        dispatch({type: types.LOAD_COMPONENT_REQUESTS_SUCCESS, componentRequests})
      })
      .catch((error) => {
        dispatch({type: types.LOAD_COMPONENT_REQUESTS_FAILURE, error});
      });
  }
}
