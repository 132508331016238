import React from 'react';
import Infinite from 'react-infinite';
import MaterialIcon from '../../app/components/material_icon';
import MediaQuery from 'react-responsive';
import { _ } from 'lodash';
import Btn from '../../app/components/form_controls/btn';
import {ON_MOBILE_MQ, ON_DESKTOP_MQ} from '../../config'
import ReactDOM from 'react-dom';

const componentTypes = ["input","output","connectivity","controller","power"];
const availableFilters = {
  "controller":   "Controllers",
  "input":        "Inputs",
  "output":       "Outputs",
  "connectivity":  "Connectivity/IoT",
  "power": "Power Supplies"
};

class SearchBar extends React.Component{
  constructor(props) {
    super(props);

    this._updateDimensions = this._updateDimensions.bind(this);
    this._getFullHeight = this._getFullHeight.bind(this);

    this.state = {rowSize: 5,  width: 468, componentSize: null, 
                  activeFilters:[], isFocus:false, desktopContainerHeight: null};

    this.maxContainerHeight = 9999; // Just an initial value
  }

  shouldComponentUpdate(nextProps, nextState){
    // TODO WHAT?! Checking every item to see if it changed, and if it did we don't update this?
    /*for (var i=0; i < nextProps.items.length; i++) {
      if (!_.isEqual(nextProps.items[i], this.props.items[i])) {
        return false;
      }
    }*/
    
    if (_.isEqual(this.state, nextState)
      && _.isEqual(this.props.inputValue, nextProps.inputValue)
      && _.isEqual(this.props.selectedComponents, nextProps.selectedComponents)
      && _.isEqual(this.props.renderResults, nextProps.renderResults)
      && _.isEqual(this.props.activeFilters, nextProps.activeFilters)) {
      return false;
    }
    return true;
  }

  _updateDimensions() {
    let width = ReactDOM.findDOMNode(this).getBoundingClientRect().width;

    // Desktop side by side mode
    if (this.props.isSideView) {//!window.isMobile && window.innerHeight > 991) {
      let componentSize = (width / 3) - 24;

      let containerElem = ReactDOM.findDOMNode(this.refs['search-container']);
      let sideContentHeight = document.getElementById('side-content').clientHeight;
      let computedStyle = window.getComputedStyle(containerElem);
      let height = sideContentHeight - 
                  (parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom));
      
      if (window.innerWidth < 1400) {
        componentSize = 100;
      }

      this.setState({rowSize: 3, width, componentSize,
                      desktopContainerHeight:  height
                                                - this._getFullHeight('search-input') 
                                                - this._getFullHeight('filters')
                    });
    }
    // Search bar is above schematics
    else if (width > 760) {
      this.setState({rowSize: 5, width, componentSize: (width / 5) - 18});
    }
    else if (width > 620) {
      this.setState({rowSize: 4, width, componentSize: (width / 4) - 22});
    }
    else if (width > 463) {
      this.setState({rowSize: 3, width, componentSize: (width / 3) - 24});
    }
    else {
      this.setState({rowSize: 2, width, componentSize: (width / 2) - 28});
    }
  }

  _getFullHeight(ref) {
    let el = ReactDOM.findDOMNode(this.refs[ref]);
    let styles = window.getComputedStyle(el);
    let margin = parseFloat(styles['marginTop']) + parseFloat(styles['marginBottom']);
    return el.offsetHeight + margin;
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this._updateDimensions);
  }

  componentDidMount() {
    this._updateDimensions();
    window.addEventListener("resize", this._updateDimensions);
  }

  componentDidUpdate(){
    const list = this.refs.searchList;
    if (typeof(list) !== 'undefined' && list.childNodes.length > 0) {
      list.childNodes[0].scrollTop = 0;
    }
  }

  _handleInputChange(e){
    this.props.onInputChange(e.target.value);
  }

  _handleKeyDown(e){
    switch(e.keyCode){
      case 27: // escape
        this._handleClose(e);
    }
  }

  _handleClose(e) {
    e.stopPropagation();
    this.props.onInputChange('');
    this.props.onFocusChange(false);
    this.setState({isFocus:false});
  }

  _handleFocused(e) {
    e.stopPropagation();
    this.setState({isFocus:true});
    this.props.onInputChange(this.props.inputValue);
    this.props.onFocusChange(true);
  }

  _onSearchBlur(e){
    analyticsSimple('Search Component', 'search', 'search', this.props.inputValue + " filters : " + this.props.activeFilters.toString() , true);
  }

  _getResults(options,searchText,maxRes){
    var zis = this;
    //const grouped = groupBy(results, r => r.region);
    var searchTextLowerCase = searchText.toLowerCase();
    searchTextLowerCase = searchTextLowerCase.replace(/\s\s+/g, ' '); //replace multiple spaces between words to single space only

    if(searchTextLowerCase[searchTextLowerCase.length - 1] === " "){
      searchTextLowerCase = searchTextLowerCase.substring(0, searchTextLowerCase.length - 1);
    }
    const _occurrences = function(string, searchString, allowOverlapping){
      string += "";
      searchString += "";
      if (searchString.length <= 0) return (string.length + 1);

      var n = 0,
          pos = 0,
          step = allowOverlapping ? 1 : searchString.length;

      while (true) {
          pos = string.indexOf(searchString, pos);
          if (pos >= 0) {
              ++n;
              pos += step;
          } else break;
      }
      return n;
    }

    const _itemIncludedInFilter = function(item){
        var res = false;
        _.each(zis.props.activeFilters,(filter)=>{
          if (item.category.includes(filter)){
            res = true;
            return false;
          }
        })
        return res;
    };

    var filteredOptions = _.filter(options,(item)=>{
      if( !_.isUndefined(item.app) && !_.isUndefined(item.app.appName) ){
        if(zis.props.activeFilters.length === 0){
          return item;
        }else if(_itemIncludedInFilter(item)){
          return item;
        }
      }
    })

    var resultsWithPriority = _.map(filteredOptions,(res) => {
        var nameCount = _occurrences(res.app.appName.toLowerCase() , searchTextLowerCase) * 100;
        var descCount = _occurrences(res.app.desc.toLowerCase() , searchTextLowerCase)
        var searchTermWords = searchTextLowerCase.split(" ");
        var wordsSplitRank = 0;
        _.each(searchTermWords, (word) => {
          if(word != " "){
            var wordNameCount = _occurrences(res.app.appName.toLowerCase() , word) * 100;
            var wordDescCount = _occurrences(res.app.desc.toLowerCase() , word)
            wordsSplitRank += (wordNameCount + wordDescCount);
          }
        });

        res.priority = nameCount + descCount + wordsSplitRank;
        return res;
    });

    var sortedRes = resultsWithPriority.sort(function(a,b){
      if (a.priority < b.priority)
        return 1;
      if (a.priority > b.priority)
        return -1;
      return 0;
    })

    if(!maxRes){
      return sortedRes;
    }else{
      return sortedRes.slice(0,maxRes);
    }

  }

  _getUnfilteredItems(category){
    if(this.props.itemsByFilters.hasOwnProperty(category)){
      return this.props.itemsByFilters[category]
    }

    return this.props.items.filter((item)=> !_.isUndefined(item.app))
  }

  _filterItemsBy(term){
    if(componentTypes.includes(term)){
      return this._getUnfilteredItems(term);
    }
    if(term !== "" || this.props.activeFilters.length != 0){
      //return this._getResults(this.props.items,term.toLowerCase());
      var res = this._getResults(this.props.items,term.toLowerCase()).filter((item)=>{
        if(item.priority > 0){
          return true;
        }
        // return this.props.filterByProps.some((prop) => {
        //   return(item[prop].toLowerCase().includes(term.toLowerCase()))
        // })
      })
      return res;
    }else{
      return this._getUnfilteredItems();
    }
  }

  _supportsController(item, controller) {
    if (item && !('supportedControllers' in item) && 'requiredBlocks' in item) {
      // Build supported controllers list from requiredBlocks children
      let supportedControllers = new Set();
      for (var requiredBlockArrayOfCost of item.requiredBlocks) {
        for (var requiredBlockName of requiredBlockArrayOfCost.blocks) {
          let requiredBlock = this.props.blocksByName[requiredBlockName];
          if ('supportedControllers' in requiredBlock) {
            for (var supportedController of requiredBlock.supportedControllers) {
              supportedControllers.add(supportedController);
            }
          }
        }
      }
      
      item.supportedControllers = [...supportedControllers];
    }

    if (item && 'supportedControllers' in item &&
        controller && 'supportedControllers' in controller &&
        (item.category.includes('input')
          || item.category.includes('output')
          || item.category.includes('connectivity')
          || item.category.includes('power'))) {
      return (item.supportedControllers.some((supportedController)=> 
        // To support a controller means to support the controllers' supportedControllers
        controller.supportedControllers.includes(supportedController)
      ));
    }

    return false;
  }

  _renderItems(){
    let rows = [];
    let row = [];
    if(this.props.selectedComponents.includes(undefined)){
      return [];
    }

    for (let item of this._filterItemsBy(this.props.inputValue)) {
      if(item.name === this.props.controller.name || _.isUndefined(item.app) || (this.props.selectedComponents.includes(item) && item.category.includes("power")) || !item.app.visible){
        continue; // No need to show the controller we already have in the circuit
      }

      item.disabledBy = null;
      item.isEnabled = true;
      if (!item.category.includes('controller')){
        if (!this._supportsController(item, this.props.controller)) {
          item.disabledBy = this.props.controller.name;
          item.isEnabled = false;
        }
      }
      else {
        for (var j = 0; j < this.props.selectedComponents.length; j++) {
          // It's ok for new controller to not support the power.. it'll be auto switched
          // And we don't check if contgrollers are supported by controllers cuz its stupid
          if (!this.props.selectedComponents[j].category.includes('power') &&
              !this.props.selectedComponents[j].category.includes('controller') &&
              !this._supportsController(this.props.selectedComponents[j], item)) { // But the others must support it
            item.isEnabled = false;
            item.disabledBy = this.props.selectedComponents[j].name;
            break;
          }
        }
      }

      if (this.props.connectivity && item.name === this.props.connectivity.name) {
        item.isEnabled = false;
        item.disabledBy = item.name;
      }

      row.push(this.props.itemRenderer(item, this.state.componentSize, this.props.controller.name));

      if (row.length === this.state.rowSize) {
        rows.push(
          <li className='components-row' key={`item-${item[this.props.valueProp]}`}>
            {row}
          </li>
        );

        row = [];
      }
    }

    if (row.length > 0) {
      // Filling up the row with empty divs so this final row will have correct spacing
      // between items just like a full row has
      while (row.length < this.state.rowSize) {
        row.push(<div key={'filler-'+row.length} style={{width: this.state.componentSize, height: this.state.componentSize}}/>)
      }
      rows.push(
        <li className='components-row' key={`item-${row[row.length - 1][this.props.valueProp]}`}>
          {row}
        </li>
      );
    }

    return rows;
  }

  /*
  Render the overall results box
   */
  _renderResults(){
    const items = this._renderItems();

    const dims = {
      itemHeight: this.state.componentSize,
      searchBar: 80,
      filters: 60,
      mobileFilters: 60,
      header: 40,
      footer: 40,
      generateBtn: 36,
      tabs:60,
      windowHeight: window.innerHeight
    }

    const mobileContainerHeight = ( (dims.generateBtn + dims.windowHeight) - (dims.header + dims.searchBar + dims.mobileFilters));

    if (window.isMobile) {
      items.unshift(<div className='mobile-search-bar-first-item-gap'/>);
    }

    items.push(<li key='footer' className="components-row">{this.props.resultsFooterRenderer()}</li>);

    return(
      <div className='search-bar-infinite-container' ref='infinite-container'>
        <MediaQuery query={ON_MOBILE_MQ}>
          <div className='search-bar-items' ref="searchList">
            <Infinite containerHeight={mobileContainerHeight} elementHeight={dims.itemHeight}>
              {items}
            </Infinite>
          </div>
        </MediaQuery>
        <MediaQuery query={ON_DESKTOP_MQ}>
          <div className='search-bar-items' ref="searchList">
            {this.state.desktopContainerHeight ? 
              <Infinite containerHeight={this.state.desktopContainerHeight} elementHeight={dims.itemHeight}>
                {items}
              </Infinite>
              : null}
          </div>
        </MediaQuery>
      </div>
    );
  }
  //
  _renderSearchClear(){
    var closeBtn = (
      <a href='javascript: void(0);' onClick={this._handleClose.bind(this)}>
        <MaterialIcon name='close' />
      </a>);

    return(
      <div>
        <MediaQuery query={ON_DESKTOP_MQ}>
          {this.props.inputValue ? closeBtn : <MaterialIcon name='search' />}
        </MediaQuery>
        <MediaQuery query={ON_MOBILE_MQ}>
          {closeBtn}
        </MediaQuery>
      </div>
    )
  }

  _onFilterClicked(e){
    let newFilter = e.currentTarget.getAttribute('data-filter');
    let filterIndx = this.props.activeFilters.indexOf(newFilter);
    let array = [...this.props.activeFilters];
    let addOrRemove = "added";
    if (filterIndx > -1) {
      array.splice(filterIndx, 1);
      addOrRemove = "removed";
    }else{
      array = [...array, newFilter];
    }

    analytics.track('Search Component', {
      'event category': 'search',
      'event action': 'search filter ' + addOrRemove + " " + array.toString(),
      'event label': this.props.inputValue
    });

    this.props.onSetFilters(array);
  }

  _getFilters(){
    var isActive = this.props.activeFilters;
    var zis = this;
    return(
      <div className="filters" ref="filters">
        {
          Object.keys(availableFilters).map((filter,indx)=> {
            let isActive = "";
            let filterText = availableFilters[filter];
            if(zis.props.activeFilters.includes(filter)){
              isActive = "active";
            }
            return (
              <span key={indx}>
                <MediaQuery query={ON_MOBILE_MQ}>
                  <div className="btn-container" data-filter={`${filter}`} onTouchEnd={zis._onFilterClicked.bind(zis)}>
                    <Btn type="secondary-outline-gray" className={`filter-item ${isActive}`} label={`${filterText}`}/>
                  </div>
                </MediaQuery>
                <MediaQuery query={ON_DESKTOP_MQ}>
                  <div className="btn-container" data-filter={`${filter}`} onClick={zis._onFilterClicked.bind(zis)}>
                    <Btn type="secondary-outline-gray" className={`filter-item ${isActive}`} label={`${filterText}`}/>
                  </div>
                </MediaQuery>
              </span>
            );
          })
        }
      </div>
    )
  }

  render(){
    let calcdVal = (componentTypes.includes(this.props.inputValue)) ? null : this.props.inputValue;
    let isFocusClass = (this.state.isFocus) ? "is-focus" : "";
    return(
      <div className="search-bar">
        <div className={`search-bar-container ${isFocusClass}`} ref='search-container' onKeyDown={this._handleKeyDown.bind(this)}
              onClick={this._handleFocused.bind(this)}>
          <div className='search-bar-input' ref='search-input'>
            <input className="text-app-searchbar" value={calcdVal} onChange={this._handleInputChange.bind(this)}
                    placeholder='Search for a component' onBlur={this._onSearchBlur.bind(this)} />
            {this.props.renderResults  ? this._renderSearchClear() : <MaterialIcon name='search' />}
          </div>
          {this._getFilters()}
          {this.props.renderResults ? this._renderResults(): null}
        </div>
      </div>
    )
  }
}
SearchBar.displayName = 'SearchBar';
SearchBar.propTypes = {
  items: React.PropTypes.array,
  itemsByFilters: React.PropTypes.object,
  itemRenderer: React.PropTypes.func.isRequired,
  filterByProps: React.PropTypes.array.isRequired,
  valueProp: React.PropTypes.string,
  resultsFooterRenderer: React.PropTypes.func,
  renderResults: React.PropTypes.bool,
  inputValue: React.PropTypes.string,
  onInputChange: React.PropTypes.func,
  onFocusChange: React.PropTypes.func,
  controller: React.PropTypes.object,
  selectedComponents: React.PropTypes.array,
  blocksByName: React.PropTypes.object,
  isFocused: React.PropTypes.bool,
  isSideView: React.PropTypes.bool,
  onSetFilters: React.PropTypes.func,
  activeFilters: React.PropTypes.array,
  connectivity: React.PropTypes.object,
}

SearchBar.defaultProps = {
  items: [],
  itemsByFilters: {},
  resultsFooter: null,
  renderResults: false,
  inputValue: '',
  isSideView: true,
  activeFilters: [],
}

export default SearchBar;
