import React from 'react';

const DesktopLoader = (props) => {
  return(
    <div className='loader-desktop'>
      <img src='/static/images/loader.svg' className='centered-loader-image'/>
    </div>
  );
}

export default DesktopLoader;