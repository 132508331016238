import * as types from '../constants';

export default function(isVisible, text){
  return (dispatch, getStateFunc) => {
  	if (!isVisible || !text) {
  		text = '';
  	} 
  	
  	if (getStateFunc().requests.isSchematicsTooltipVisible !== isVisible
  		|| getStateFunc().requests.schematicsTooltipText !== text) {
	    dispatch({
	      type: types.SCHEMATICS_TOOLTIP_UPDATED,
	      isVisible,
	      text,
	    });
	}
  }
}
