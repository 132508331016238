import React from 'react'
import {connect} from 'react-redux';
import { Btn } from '../../app/components/form_controls';
const _ = require('lodash');

class CartPreview extends React.Component{
  constructor(props) {
    super(props);
    this._renderMenuItem = this._renderMenuItem.bind(this);
  }

  render(){
    var visibility = (this.props.isVisible) ? "active" : "invisible";
    if ( _.isEmpty(this.props.validationData) ) {
      return null;
    }

    return (
      <div className={`cart-preview-container ${visibility}`} onMouseOver={this.props.onHover}  onMouseOut={this.props.onHoverOut}>
        <div className='cart-preview'>
          <div className="close-cart-preview " onClick={() => {
              this.props.onCloseCart();
            }}
            onTouchEnd={ ()=>{
              this.props.onCloseCart();
            } }>
            <i className="material-icons">close</i>
          </div>
          <div className="selected-comps">
            <div className="comps-header text-app-header-2-bold">
              Parts*
            </div>
            {Object.values(this.props.validationData.output.components).map((item) =>
              this._renderMenuItem(item))
            }
          </div>
          <div className="disclaimer">
              <span className="text-app-body-2">*All part prices are estimates</span>
          </div>
        </div>
      </div>
    );
  }

  _onCheckoutClick() {
    analyticsSimple('Hover Go To Cart', 'Button', 'Click', 'Hover Go To Cart');
  }

  _onBuyClick(item){
    var comp = this.props.componentsById[item.solverId];
    if(!comp){
      comp = this.props.componentsById[item.solverId.substr(1,item.solverId.length)];
    }

    analyticsSimple("Parts Buy", "Parts", "Buy", comp.name, true);
  }

  _renderMenuItem(item,bomObj) {
    return(
      <div className='cart-preview-item-container' id={'cart-'+item.name} key={item.name}>
        <div className='cart-preview-item-details'>
          <div className='text-app-sub-header-2'>{item.name}</div>
          <div className='text-app-comp-list-item'>
            <span className="text-app-body-2">{'$'+item.price+' x Qty: '+item.qty}</span>
          </div>
        </div>
        <a className="buy-btn" href={item.link} target="_blank" onClick={()=> this._onBuyClick(item)}>
            <Btn label="Buy" type="secondary-outline-yellow" icon="shopping_cart" className="action-btn"/>
        </a>
      </div>
    );
  }
}

CartPreview.displayName = 'CartPreview';
CartPreview.propTypes = {
  cart: React.PropTypes.object.isRequired,
  onCheckoutClick: React.PropTypes.func,
  onCloseCart:React.PropTypes.func,
  customClassName:React.PropTypes.string
}

const mapStateToProps = (state)=>{
  return{
    cart:       state.requests.cart,
    componentsById:state.requests.componentsById,
    validationData: state.app.validationData,
    bomsByName: state.requests.bomsByName,
  }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(CartPreview);
