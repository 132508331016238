import Cookies from 'universal-cookie';
import { AGREE_TO_TERMS } from '../constants';

export default function(){
	return (dispatch, getStateFunc) => {
		let state = getStateFunc();
		let isTermsAgreed = !state.app.isTermsAgreed;

		new Cookies().set('isTermsAgreed', isTermsAgreed, { path: '/' });
		analyticsSimple('Agree to Terms', 'Click', 'Checkbox Check');
  		dispatch({
    		type: AGREE_TO_TERMS
  		})
  	}
}
