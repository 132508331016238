import React from 'react';
import InputWrapper from './input_wrapper';
import { generateId } from '../../../utils';

class Input extends React.Component{
  constructor(props){
    super(props);
    this.htmlId = generateId();
  }

  _handleOnChange(e){
    this.props.onChange(e.target.value);
  }

  _onKeyPress(e){
    var EnterPressed = ( 'Enter' === e.key )  ? true : false;
    if( EnterPressed  ){
      this.props.onEnterPressed();
    }
  }

  render(){
    return(
      <InputWrapper label={this.props.label} value={this.props.value} icon={this.props.icon}>
        <input disabled={this.props.disabled} type={this.props.type} name={this.props.name}
               autoComplete={this.props.autocomplete}
               value={this.props.value} id={this.htmlId}
               className='form-control' placeholder={this.props.placeholder}
               onKeyPress={this._onKeyPress.bind(this)}
               onChange={this._handleOnChange.bind(this)}/>
      </InputWrapper>
    );
  }
}

Input.displayName = 'Input';
Input.propTypes = {
  onChange: React.PropTypes.func.isRequired,
  onEnterPressed:React.PropTypes.func,
  icon: React.PropTypes.string,
  value: React.PropTypes.string,
  type: React.PropTypes.string,
  name: React.PropTypes.string,
  autocomplete: React.PropTypes.string,
  placeholder: React.PropTypes.string,
  label: React.PropTypes.string,
  disabled: React.PropTypes.bool,
};
Input.defaultProps = {
  type: 'text',
  name: 'input',
  autocomplete: 'off',
  value: '',
  disabled: false
};

export default Input;
