import React from 'react'
import { SWITCHABLE_COMPONENT_TYPES } from '../../../config';
import { getCategories } from '../../../utils';
import { _ } from 'lodash';

class ComponentContextMenu extends React.Component{
  constructor(props) {
    super(props);

    this._renderMenuItem = this._renderMenuItem.bind(this);
    this._onInfoClick = this._onInfoClick.bind(this);
    this._onDuplicateClick = this._onDuplicateClick.bind(this);
    this._onRemoveClick = this._onRemoveClick.bind(this);
    this._onSwapClick = this._onSwapClick.bind(this);
    this._onEditClick = this._onEditClick.bind(this);
  }

  componentDidMount() {
    this.props.onIsClosable();
  }

  render(){
    if (this.props.component.category.indexOf('support') !== -1) {
      return(
        <div className='component-context-menu' style={{left: this.props.position.x, top: this.props.position.y}}>
          {this._renderMenuItem('VIEW INFO', 'info_outline', this._onInfoClick)}
        </div>
      );
    }
    else if ( _.intersection(SWITCHABLE_COMPONENT_TYPES,this.props.component.category).length != 0 ) {
      return(
        <div className='component-context-menu' style={{left: this.props.position.x, top: this.props.position.y}}>
          {this._renderMenuItem('VIEW INFO', 'info_outline', this._onInfoClick)}
          {this._renderMenuItem('SWAP', 'swap_horiz', this._onSwapClick)}
          {this._renderMenuItem('EDIT', 'edit', this._onEditClick)}
        </div>
      );
    }
    else {
      return(
        <div className='component-context-menu'
              style={{left: this.props.position.x, top: this.props.position.y}}>
          {this._renderMenuItem('VIEW INFO', 'info_outline', this._onInfoClick)}
          {!this.props.component.category.includes("connectivity") ? this._renderMenuItem('DUPLICATE', 'library_add', this._onDuplicateClick) : null}
          {this._renderMenuItem('REMOVE', 'delete', this._onRemoveClick)}
          {this._renderMenuItem('EDIT', 'edit', this._onEditClick)}
        </div>
      );
    }
  }

  _onInfoClick() {
    analyticsSimple("Schematic View Info", "Schematic", "View Info",
                    "Schematic View Info " + this.props.component.name, true);

    this.props.onInfoClick(this.props.component);
  }

  _onDuplicateClick() {
    analyticsSimple("Schematic Duplicate", "Schematic", "Duplicate",
                    "Schematic Duplicate " + this.props.component.name, true);

    this.props.onDuplicateClick();
  }

  _onRemoveClick() {
    analyticsSimple("Schematic Remove", "Schematic", "Remove",
                    "Schematic Remove " + this.props.component.name, true);

    this.props.onRemoveClick();
  }

  _onSwapClick() {
    analyticsSimple("Schematic Suggest Swap", "Schematic", "Suggest Swap",
                    "Schematic Suggest Swap " + this.props.component.name, true);

    this.props.onSwapClick();
  }

  _onEditClick(){
   analyticsSimple("Schematic Edit Click", "Schematic", "Edit Part",
                    "Schematic Edit Part " + this.props.component.name, true);

    this.props.onEditClick(); 
  }

  _renderMenuItem(text, icon, action) {
    //cant duplicate connectivity only one item allowed;
    if(text === "DUPLICATE" && this.props.component.category.indexOf("connectivity") != -1){
      return null;
    }else{
      return( 
        <div className='component-context-menu-item-container' onClick={action}>
          <div className='component-context-menu-item text-app-context-menu-item'>
            <i className='material-icons'>{icon}</i>
            <span> {text}</span>
          </div>
        </div>
      );
    }
  }
}

ComponentContextMenu.displayName = 'ComponentContextMenu';
ComponentContextMenu.propTypes = {
  component:        React.PropTypes.object.isRequired,
  position:         React.PropTypes.object.isRequired,
  onInfoClick:      React.PropTypes.func.isRequired,
  onDuplicateClick: React.PropTypes.func.isRequired,
  onRemoveClick:    React.PropTypes.func.isRequired,
  onSwapClick:      React.PropTypes.func.isRequired,
  onIsClosable:     React.PropTypes.func.isRequired,
  onEditClick:    React.PropTypes.func.isRequired,
}

export default ComponentContextMenu;
