import React from 'react';
import ReactDOM from 'react-dom';
import MediaQuery from 'react-responsive';
import {ON_DESKTOP_MQ, ON_MOBILE_MQ} from '../../config'
import { connect } from 'react-redux';
import Modal from '../../app/components/modal';
import MaterialIcon from '../../app/components/material_icon';
import WalkthroughBasicStep from '../components/walkthrough_basic_step';
import WalkthroughTermsStep from '../components/walkthrough_terms_step';
import walkthroughModalClose from '../actions/walkthrough_modal_close';
import agreeToTerms from '../../app/actions/agree_to_terms';

const _ = require('lodash');
const STEPS_AMOUNT = 4;

class WalkthroughModal extends React.Component{
  _updateDimensions() {
    let height = ReactDOM.findDOMNode(this).getBoundingClientRect().height;

    this.setState({illustrationSize: Math.min(240, height - 285)});
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this._updateDimensions);
  }

  constructor(props){
    super(props);

    this.state = {
      showTermsError: false,
      isClosing: false,
      curStep: 1,
    };

    this._updateDimensions = this._updateDimensions.bind(this);

    this._renderStep = this._renderStep.bind(this);
    this._renderProgress = this._renderProgress.bind(this);
    this._onBackSkip = this._onBackSkip.bind(this);
    this._onNext = this._onNext.bind(this);
    this._onClose = this._onClose.bind(this);
    this._onFinishedClosing = this._onFinishedClosing.bind(this);
    this._canAdvance = this._canAdvance.bind(this);
    this._getStepName = this._getStepName.bind(this);
    this._onNavigate = this._onNavigate.bind(this);
  }

  componentDidMount() {
    this._updateDimensions();
    window.addEventListener("resize", this._updateDimensions);

    if (this.props.isOpen) {
      analyticsSimple('Walkthrough ' + this._getStepName(), 'Walkthrough', 'View');
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen == true && this.props.isOpen == false) {
      this.setState({
        showTermsError: false,
        isClosing: false,
        curStep: 1,
      });

      return;
    }
    if(this.props.isOpen && this.props.isTermsAgreed && this.state.curStep === 1){
      this.setState({curStep: 2});
    }
  }

  _getStepName(step) {
    switch(step || this.state.curStep) {
      case 1:
        return 'Welcome';
      case 2:
        return 'Drag-N-Drop';
      case 3:
        return 'Default State';
      case 4:
        return 'BoM';
      case 5:
        return 'Project Guide';
    }
  }

  _onFinishedClosing(){
    this.props.onClose();
  }

  _onClose(XClicked){
    if (XClicked) {
      analyticsSimple('Close Walkthrough', 'Walkthrough', 'Close',
                      'Closed from ' + this._getStepName());
    }

    if (this._canAdvance()) {
      this.setState({isClosing: true})
    }

  }

  _canAdvance() {
    if (!this.props.isTermsAgreed) {
      if (!this.state.showTermsError) {
        this.setState({showTermsError: !this.state.showTermsError});
      }

      return false;
    }

    return true;
  }

  _onNext() {
    if (this.state.curStep === STEPS_AMOUNT) {
      analyticsSimple('Walkthrough - Done', 'Walkthrough', 'Click');

      this._onClose(false);
    }
    else {
      analyticsSimple('Next Walkthrough', 'Walkthrough', 'Next',
                      'From '+this._getStepName()+' to '+this._getStepName(this.state.curStep + 1));

      analyticsSimple('Walkthrough ' + this._getStepName(this.state.curStep + 1), 'Walkthrough', 'View');

      if (this._canAdvance()) {
        this.setState({curStep: this.state.curStep + 1});
      }
    }
  }

  _onBackSkip() {
    if (this.state.curStep === 1) {
      analyticsSimple('Skip Walkthrough', 'Walkthrough', 'Skip');

      if (this._canAdvance()) {
        this._onClose(false);
      }
    }
    else {
      analyticsSimple('Back Walkthrough', 'Walkthrough', 'Back',
                      'From '+this._getStepName()+' to '+this._getStepName(this.state.curStep - 1));

      analyticsSimple('Walkthrough ' + this._getStepName(this.state.curStep - 1), 'Walkthrough', 'View');

      this.setState({curStep: this.state.curStep - 1});
    }
  }

  _onNavigate(navigateTo) {
    analyticsSimple('Navigate to Walkthrough', 'Walkthrough', 'Navigate',
                    'From '+this._getStepName()+' to '+this._getStepName(navigateTo));

    analyticsSimple('Walkthrough ' + this._getStepName(navigateTo), 'Walkthrough', 'View');

    if (this._canAdvance()) {
      this.setState({curStep: navigateTo})
    }
  }

  render(){
    return(
        <Modal modalClass={`walkthrough-modal ${window.isMobile ? '' : 'walkthrough-modal-desktop'}`} isOpen={this.props.isOpen && !this.state.isClosing}
                onClose={this._onFinishedClosing} fade={false} unDismissable={true}>
          <div className={`modal-dialog modal-md ${window.isMobile ? 'mobile' : ''}`}>
            <div className={`modal-content ${window.isMobile ? 'mobile' : ''}`}>
              <react-comment>googleoff: all</react-comment>
              <div className="modal-header">
                {this.state.curStep != 1 ?
                  <button id="walkthrough-close" type="button" className="close orange" aria-label="Close" onClick={()=>this._onClose(true)}>
                    <MaterialIcon name="close" />
                  </button>
                  : null}
              </div>
              <div className={`modal-body ${window.isMobile ? 'mobile' : ''}`}>
                  {this._renderStep()}
                  {this._renderFooter()}
              </div>
              <react-comment>googleon: all</react-comment>
            </div>
          </div>
        </Modal>
    );
  }

  _renderFooter() {
    if(this.state.curStep !== 1){
      return(
        <div className='walkthough-modal-footer-container'>
          <button onClick={this._onBackSkip} className="walkthrough-btn">
            {this.state.curStep === 2 ? '' : 'Back'}
          </button>
          {this._renderProgress()}
          <button onClick={this._onNext} className="walkthrough-btn bold">
            {this.state.curStep < STEPS_AMOUNT ? 'Next' : "Done"}
          </button>
        </div>
      )
    }
  }

  _renderProgress() {
    let dotImg = '/static/images/walkthrough-progress-dot';
    let dotImgCur = '/static/images/walkthrough-progress-dot-current';

    return (
      <div>
        {_.times(STEPS_AMOUNT, (i)=> {
          // We only draw dots for the second step onwards. welcome step has no dot
          if (i == 0) {
            return null;
          }

          return (
            <span>
              <MediaQuery query={ON_MOBILE_MQ}>
                <img key={i.toString()} className="walkthrough-modal-progress-dot" onClick={()=>this._onNavigate(i+1)}
                     src={this.state.curStep === i+1 ? dotImgCur+'-mobile.svg' : dotImg+'-mobile.svg'}/>
              </MediaQuery>
              <MediaQuery query={ON_DESKTOP_MQ}>
                <img key={i.toString()} className="walkthrough-modal-progress-dot" onClick={()=>this._onNavigate(i+1)}
                     src={this.state.curStep === i+1 ? dotImgCur+'.svg' : dotImg+'.svg'}/>
              </MediaQuery>
            </span>
          );
        })}
      </div>
    );
  }

  _renderStep() {
    let step = this.state.curStep;
    switch (step) {
      case 1:
        return <WalkthroughTermsStep isTermsAgreed={this.props.isTermsAgreed} showTermsError={this.state.showTermsError}
                                      onTermsAgreement={this.props.onTermsAgreement} step={step} illustrationSize={this.state.illustrationSize}
                                      title="Hi There!"
                                      text={"circuito.io is here to help you plan\n and shop for your electronic circuit.\n Before you get started, you must agree to\n circuito.io’s"}/>
      case 2:
        return <WalkthroughBasicStep step={step} illustrationSize={this.state.illustrationSize}
                text={"Drag & drop components from\nthe panel on the left to see\nyour schematic come to life!"}
                textMobile={"Find components in the\nsearch bar to get started. After\nadding a component, your\nschematic will come to life!"}/>

      case 3:
        return <WalkthroughBasicStep step={step} illustrationSize={this.state.illustrationSize}
                text={"We’ve started you off with popular\ncontroller and power source components.\nYou can swap these out at any time."}/>
      case 4:
        return <WalkthroughBasicStep step={step} illustrationSize={this.state.illustrationSize}
                text={"In the parts list you can view\na list of components in your project\nand purchase the ones you need."}/>
    }
  }
}

WalkthroughModal.displayName = 'WalkthroughModal';
WalkthroughModal.propTypes = {
  isOpen: React.PropTypes.bool.isRequired,
  isTermsAgreed: React.PropTypes.bool,
  onClose: React.PropTypes.func,
  onTermsAgreement: React.PropTypes.func,
}

const mapStateToProps = (state)=>{
  return{
    isOpen: state.requests.walkthroughModalIsOpen,
    isTermsAgreed: state.app.isTermsAgreed,
  }
}

const mapDispatchToProps = {
  onClose: walkthroughModalClose,
  onTermsAgreement: agreeToTerms,
}

export default connect(mapStateToProps,mapDispatchToProps)(WalkthroughModal);
