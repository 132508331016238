import React from 'react';
import InputWrapper from './input_wrapper';
import { generateId } from '../../../utils';

class TextAreaInput extends React.Component{
  constructor(props){
    super(props);
    this.htmlId = generateId();
  }

  render(){
    return(
      <InputWrapper label={this.props.label} value={this.props.value} icon={this.props.icon}>
        <textarea id={this.htmlId} value={this.props.value} className="form-control" onChange={(e) => {this.props.onChange(e.target.value)}} />
      </InputWrapper>
    );
  }
}
TextAreaInput.propTypes = {
  icon: React.PropTypes.string.isRequired,
  onChange: React.PropTypes.func.isRequired,
  label: React.PropTypes.string,
  value: React.PropTypes.string,
}

export default TextAreaInput;
