import React from 'react';

const IconSwitch = ()=>{
  return(
    <svg className='icon icon-switch' viewBox="0 0 24 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Card" transform="translate(-112.000000, -115.000000)">
                <g id="switch_button" transform="translate(112.000000, 112.000000)">
                    <path d="M8.28454545,14.75 L0,14.75 L0,17.25 L8.28454545,17.25 L8.28454545,21 L13,16 L8.28454545,11 L8.28454545,14.75 L8.28454545,14.75 Z M15.7154545,13 L15.7154545,9.25 L24,9.25 L24,6.75 L15.7154545,6.75 L15.7154545,3 L11,8 L15.7154545,13 L15.7154545,13 Z" id="Icon_Switch" fill="#FFFFFF"></path>
                    <rect id="bg" x="0" y="0" width="24" height="24"></rect>
                </g>
            </g>
        </g>
    </svg>
  )
}

export default IconSwitch;
