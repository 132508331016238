import React from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import MaterialIcon from '../components/material_icon';
import { Button } from '../components/form_controls';
import headerVideoOpen from '../actions/header_video_open';
import { ON_MAX_MD_WIDTH, ON_MIN_MD_WIDTH } from '../../config';
import { analyticsLinkClick } from '../../utils';

const HomepagePlayButton = ({onClick}) => {
  let sharedProps = {
    icon: '/static/images/play_button.svg',
    id: 'landing-page-play',
  }

  return(
    <div className='homepage-video-container'>
      <img className='homepage-video-preview-image' src='/static/images/video-preview.png'/>
      <MediaQuery query={ON_MIN_MD_WIDTH}>
        <img className='play-button' onClick={onClick} src={sharedProps.icon} />
      </MediaQuery>

      <MediaQuery query={ON_MAX_MD_WIDTH}>
        <a href='https://www.youtube.com/watch?v=mPmrB0HklTA&feature=youtu.be&autoplay=1&output=embed' 
           onClick={() => analyticsLinkClick("Video view")}
           rel='nofollow'
           id={sharedProps.id}>
          <img className='play-button' src={sharedProps.icon} />
        </a>
      </MediaQuery>
    </div>
  )
}

HomepagePlayButton.propTypes = {
  onClick: React.PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return {
    onClick: ()=>{
      dispatch(headerVideoOpen());
    }
  }
}

export default connect(null, mapDispatchToProps)(HomepagePlayButton);
