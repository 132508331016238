import React from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import {ON_MOBILE_MQ, ON_DESKTOP_MQ} from '../../config.js'

class BasicTab extends React.Component{
  render() {
    return (
      <div className='empty-page-container'>
        <div className='empty-page'>
          {this.props.image}
          <h2 className="text-app-header-1" dangerouslySetInnerHTML={{__html:this.props.title}}></h2>
          <p className="text-app-body-2"  dangerouslySetInnerHTML={{__html:this.props.text}}></p>
          {this.props.button}
          {this.props.children}
        </div>
      </div>
    );
  }
}

BasicTab.displayName = 'BasicTab';
BasicTab.propTypes = {
  title: React.PropTypes.string.isRequired,
  text: React.PropTypes.string.isRequired,
  image: React.PropTypes.element,
  button: React.PropTypes.element,
  children: React.PropTypes.array
}

BasicTab.defaultProps = {
  title: null,
  text: null,
  image: null,
  button: null,
  children: null,
}

export default BasicTab;
