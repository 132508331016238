import * as types from '../constants';
import { saveComponentRequest } from '../../api';
import { browserHistory } from 'react-router';

export default function(data){

  analytics.track('Component request updated', {
      'event category': 'Save Click',
      'event action': 'Click',
      'event label': `${data.componentRequest.name}`
    }, {
      integrations: {
        'All': true,
      }
    });

  // Fetch all components
  return (dispatch) => {
    dispatch({type: types.SAVE_COMPONENT_REQUEST_REQUEST})
    return saveComponentRequest(data)
      .then(()=>{
        //dispatch({type: types.SAVE_COMPONENT_REQUEST_SUCCESS});
        // Navigate back to request list
        browserHistory.push('/component_requests');
      })
      .catch((error) => {
        //dispatch({type: types.SAVE_COMPONENT_REQUEST_FAILURE, error});
        browserHistory.push('/component_requests');
      });
  }
}
