export const SIDEBAR_TOGGLE = 'SIDEBAR_TOGGLE';
export const SIDEBAR_CLOSE = 'SIDEBAR_CLOSE';

export const HEADER_VIDEO_OPEN = 'HEADER_VIDEO_OPEN';
export const HEADER_VIDEO_CLOSE = 'HEADER_VIDEO_CLOSE';

export const LOAD_STATIC_PAGE_CONTENT_REQUEST = 'LOAD_STATIC_PAGE_CONTENT_REQUEST';
export const LOAD_STATIC_PAGE_CONTENT_FAILURE = 'LOAD_STATIC_PAGE_CONTENT_FAILURE';
export const LOAD_STATIC_PAGE_CONTENT_SUCCESS = 'LOAD_STATIC_PAGE_CONTENT_SUCCESS';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';

export const NOTIFICATION_MODAL_OPEN = 'NOTIFICATION_MODAL_OPEN';
export const NOTIFICATION_MODAL_CLOSE = 'NOTIFICATION_MODAL_CLOSE';

export const SHOW_DESKTOP_LOADER = 'SHOW_DESKTOP_LOADER';

export const AGREE_TO_TERMS = 'AGREE_TO_TERMS';
