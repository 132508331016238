import React from 'react';
import WalkthroughBasicStep from './walkthrough_basic_step';
import CheckBox from '../../app/components/form_controls/check_box';
import Btn from '../../app/components/form_controls/btn';

class WalkthroughTermsStep extends React.Component{

  render(){
    return(
      <WalkthroughBasicStep {...this.props} isTermsStep={true}>
        <div className="walkthrough-modal-error" style={!this.props.showTermsError ? {visibility: 'hidden'} : {}}>
            {"You must agree to the Terms Of Service before entering the site."}
        </div>
        <Btn type="primary" className="agreeBtn" label='Agree to terms' onClick={this.props.onTermsAgreement}></Btn>
      </WalkthroughBasicStep>
    );
  }
}

WalkthroughTermsStep.displayName = 'WalkthroughTermsStep';
WalkthroughTermsStep.propTypes = {
  title: React.PropTypes.string,
  text: React.PropTypes.string.isRequired,
  isTermsAgreed: React.PropTypes.bool.isRequired,
  showTermsError: React.PropTypes.bool.isRequired,
  onTermsAgreement: React.PropTypes.func.isRequired,
}

export default WalkthroughTermsStep;
