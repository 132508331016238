import React, { Component } from 'react';
import { render } from 'react-dom';
// import Form from 'react-jsonschema-form';

// import MxGraph from './mxgraph';

class MessForm extends React.Component{
  constructor(props) {
    super(props);

    this.state = {formData: props.data || ''};
    //this.data = props.data || '';

    const definitions = {
      intStringTupleArray: {
        type:        "array",
        title:       "Int-String Tuple Array",
        //minItems:    1,
        //uniqueItems: true,
        items: {
          type: "array",
          minItems:    1,
          items: [
            { type: "integer" },
            { type: "array",
              minItems:    1,
              items: { type: "string" }
            }
          ],
        }
      },
      MultipleintStringTupleArray: {
        type:        "array",
        //uniqueItems: true,
        items: {
          type: "array",
          minItems:    1,
          items: [
            { "$ref": "#/definitions/intStringTupleArray" }
          ],
          //"additionalItems": false
        }
      },

      pinTypes: {
        type: "string",
        title: 'Pin Types',
        enum: [
          "ArduinoUno",           "ArduinoMega",      "ArduinoProMini5v",   "ArduinoProMini3v3",
          "ArduinoMicro",         "ArduinoNano",      "ArduinoLeonardo",    "ArduinoGemma",
          "Arduino101",           "NodeMCU",          "RPI3B",              "ESP32DevKitC",
          "ATMega328P",           "ATMega32U4",       "GND",                "Battery",
          "12v",                  "9v",               "7.2v",               "7.4v",
          "6v",                   "5v",               "3.7v",               "3.3v",
          "Barrel",               "USB",              "Int",                "PWM",
          "IORef",                "ADC",              "DAC",                "MISO",
          "MOSI",                 "SCK",              "MISO1",              "MOSI1",
          "SCK1",                 "SDA",              "SCL",                "SS",
          "DigitalOut",           "DigitalIn",        "GndLoadSwitching",   "Speaker",
          "RX",                   "TX",               "RX2",                "TX2",                
          "ESPSoftwareSerialTag", "Timer0",           "SRAMLoad",,          "SoftwareSerial",
          "ESPSoftwareSerialTX",  "Timer1",           "CTS",                "DTR",
          "ESPSoftwareSerialTX1", "Timer2",           "DCMA1",              "DCMA2",
          "ESPSoftwareSerialRX",  "DCMB1",            "DCMB2",              "USB_B",
          "ESPSoftwareSerialRX1", "USB_MINI", "USB_MICRO",  "BARREL_JACK",  "FTDI",
        ]
      }
    }
    this.schema = {
      symbol: {
        definitions,
        title: 'symbol',
        type:  'object',
        required: ['name', 'path', 'svg'],
        properties: {
          name: { type: 'string', title: 'Name' },
          path: { type: 'string', title: 'Path' },
          svg:  { type: 'string', title: 'SVG' },
        }
      },

      part: {
        definitions,
        title:    "Part",
        type:     "object",
        properties: {
          name:     { type: "string", title: 'Name' },
          path:     { type: "string", title: 'Path' },
          symbol: {
            title: 'Symbol',
            type: "object",
            required: ["ref"],
            properties: {
              ref: { type: "string", title: 'Reference' },
              param: { type: "string", title: 'Param' },
            },
          },
          category:     {
            type: "array",
            items: { type: "string" },
            enum: ["output", "input", "controller", "power", "connectivity"]
          },
          desc: {type: "string", title: "Description"},
          bom: { 
            type: "object",
            title: "BoM",
            required: ["name", "img", "price", "SKU"],
            properties: {
              name:         { type: "string" },
              image:        { type: "string" },
              price:        { type: "string" },
              SKU:          { type: "string" },
              packOf:       { type: "integer" },
              headers: {
                type: "array",
                items: [ 
                  { type: "integer" },
                  { type: "string" }
                ]
              }
            }
          },
          bomAux:     {
            type: "array",
            items: { type: "string" }
          },
          placing: {
            title: "Placing",
            type: "object",
            properties: {
              onBreadboard: { type: "boolean", title: 'On Breadboard', default: 'true' },
              row:          { type: "integer", title: 'Row' },
              rotation:     { type: "number",  title: 'Rotation', enum: [0, 90, 180, 270] }
            },
          },
          info: {
            title: "Info",
            type: "object",
            properties: {
              setup:    { type: "string", title: 'Setup' },
              test:     { type: "string", title: 'Test' }
            },
          }
        }
      },

      block: {
        definitions,
        title:    "Block",
        type:     "object",
        //required: ["name", "blockId", "path", "app", "type", "coders", "circuit"],
        properties: {
          name:     { type: "string" },
          blockId:  { type: "string" },
          path:     { type: "string" },
          app: {
            type: "object",
            required: ["appName", "tags", "indicators", "desc", "image"],
            properties: {
              appName: { type: "string" },
              numericName: { type: "string" },
              shortName: { type: "string" },
              tags:     {
                type: "array",
                items: { type: "string" }
              },
              indicators:     {
                type: "object",
                required: ["code", "solder", "verified"],
                properties: {
                  solder: { type: "string" },
                  code: { type: "string" },
                  verified: { type: "string" }
                },
              },
              desc: { type: "string" },
              image: { type: "string" },
              visible: { type: "boolean" }
            },
          },
          type: {
            type: "array",
            items: { type: "string" },
            enum: ["output", "input", "controller", "power", "connectivity"]
          },
          coders:     {
            type: "array",
            items: { type: "string" }
          },
          references: {
            type: "array",
            items: { type: "string" }
          },
          requires:     { "$ref": "#/definitions/intStringTupleArray" },
          requiresExpansion:    { "$ref": "#/definitions/intStringTupleArray" },
          chain:      { type: "boolean" },                
          supportedPowerSupply:{
            type: "array",
            items: { type: "string" }
          },
          supportedControllers:{
            type: "array",
            items: { type: "string" }
          },
          requiredBy: {
            type: "array",
            items: { type: "string" }
          },
          derivesFrom:  { type: "string"  },
          /*circuit: {
            type: "object",
            properties: {
              parts: {
                type: "array",
                items: {
                  type: "object",
                  required: ["name", "part", "pos", "rotation"],
                  properties: {
                    name:     {type: "string"},
                    part:     {type: "boolean"},
                    pos:      {type: "boolean"},
                    rotation: {type: "boolean"}
                  },
                }
              },
              blocks: {
                type: "array",
                items: {
                  type: "object",
                  required: ["name", "part", "pos", "rotation"],
                  properties: {
                    name:     {type: "string"},
                    part:     {type: "boolean"},
                    pos:      {type: "boolean"},
                    rotation: {type: "boolean"}
                  }
                }
              },
              wires: {
                type: "array",
                items: {
                  type: "object",
                  required: ["from", "to"],
                  properties: {
                    from:       {type: "string"},
                    to:         {type: "boolean"},
                    waypoints:  {type: "boolean"}
                  },
                }
              },
              ports: {
                type: "array",
                items: {
                  type: "object",
                  required: ["name", "part", "pos", "rotation"],
                  properties:  {
                    name:       {type: "string"},
                    type:       {type: "boolean"},
                    pos:        {type: "boolean"},
                    rotation:   {type: "boolean"},
                    interface:  {
                      type: "array",
                      items: {
                        type: "object",
                        required: ["name", "part", "pos", "rotation"],
                        properties: {
                          unique:   {type: "boolean"},
                          isBus:    {type: "boolean"},
                          chain:    {type: "boolean"},
                          chainTo:  {type: "string"},
                          requires: {
                            type:         "array",
                            minItems:     1,
                            uniqueItems:  true,
                            items: {
                              type: "array",
                              items: [
                                { type: "integer" },
                                {
                                  type: "array",
                                  items: { "$ref": "#/definitions/pinTypes" }
                                }
                              ],
                            }
                          },
                          provides: {
                            type: "array",
                            items: {
                              type:     "array",
                              items:    { "$ref": "#/definitions/pinTypes" }
                            }
                          },
                          providesLoad:     { type: "integer"},
                          requiresLoad:     { type: "integer"},
                          redirectLoad:     { type: "string" },
                          codename:         { type: ["string", "integer"] },
                          codenameRedirect: { type: "string" }
                        }
                      }
                    }
                  }
                }
              }
            }
          }*/
        }
      }
    };

    this.uiSchema = {
      placing: {
        rotation: {
          "ui:widget": "radio",
          "ui:options": {
            "inline": true
          }
        }
      }
    };
  }

  componentDidMount() {
      this.setState({formData: this.props.data});
    //this.data = this.props.data;
  }

  componentWillUpdate(nextProps){
    if (nextProps.schemaType !== this.props.schemaType ||
        nextProps.data !== this.props.data) {
      this.setState({formData: {...nextProps.data}});
      //this.data = nextProps.data;
    }
  }

  render() {
    return null // DORON - disable comp render - cause it makes errors
    if (this.props.schemaType === null) {
      return null;
    }

    const formData = {...this.state.formData};

    return (
      <div className=''>
        <Form schema={this.schema[this.props.schemaType]} uiSchema={this.uiSchema}
              formData={formData} onSubmit={this.props.onSubmit}
              onChange={this._onChange.bind(this)}/>
      </div>
    );
  }

  _onChange({formData}) {
     this.setState({formData});
  }
}

MessForm.displayName = 'MessForm';

MessForm.propTypes = {
  schemaType: React.PropTypes.string,
  data: React.PropTypes.object,
  onSubmit: React.PropTypes.function,
}

export default MessForm;