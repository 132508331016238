import React from 'react';

const IconRobo = ()=>{
  return(
    <svg className='icon icon-robo' viewBox="0 0 45 45">
        <defs>
            <filter x="-50%" y="-50%" width="200%" height="200%" filterUnits="objectBoundingBox" id="filter-1">
                <feOffset dx="0" dy="-3" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                <feGaussianBlur stdDeviation="3" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.16 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
                <feMerge>                    
                    <feMergeNode in="SourceGraphic"></feMergeNode>
                </feMerge>
            </filter>
            <path d="M16.464525,32.97195 C15.870525,32.97195 14.8764,32.97195 13.251975,32.13045 C11.412225,31.1784 9.10635,29.32545 6.397875,26.613675 C3.6894,23.9019 1.833975,21.60015 0.881925,19.7604 C0.04125,18.1368 0.04125,17.141025 0.04125,16.5429 C0.04125,15.944775 0.04125,14.95395 0.881925,13.33035 C1.833975,11.4906 3.686925,9.184725 6.397875,6.47625 C9.108825,3.767775 11.412225,1.91235 13.251975,0.9603 C14.8764,0.119625 15.870525,0.119625 16.464525,0.119625 C17.57415,0.119625 20.177025,0.119625 26.529525,6.47625 C32.882025,12.832875 32.888625,15.433275 32.888625,16.54125 C32.888625,17.13525 32.888625,18.129375 32.047125,19.75875 C31.09425,21.597675 29.242125,23.90355 26.529525,26.612025 C23.816925,29.3205 21.516825,31.180875 19.677075,32.13045 C18.05265,32.97195 17.058525,32.97195 16.464525,32.97195 L16.464525,32.97195 Z" id="path-2"></path>
            <mask id="mask-3" maskContentUnits="userSpaceOnUse" maskUnits="objectBoundingBox" x="0" y="0" width="32.847375" height="32.852325" fill="white">
                <use xlinkHref="#path-2"></use>
            </mask>
        </defs>
        <g id="Desktop-list" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Desktop-25/7-#2-with-Matan-inputs" transform="translate(-938.000000, -1031.000000)">
                <g id="footerDesktop" transform="translate(0.000000, 1032.000000)">
                    <g id="footer" filter="url(#filter-1)">
                        <g id="roboplan_stamp_logo_font_updated" transform="translate(944.000000, 8.000000)">
                            <g id="Layer_1">
                                <path d="M22.9482,12.700875 C22.9445578,12.2919032 22.7249034,11.9153528 22.3707,11.710875 L22.3707,11.710875 L22.3707,11.710875 L22.3344,11.69025 L17.0478,8.65425 L17.0478,8.65425 C16.7124501,8.46602888 16.3054158,8.45705928 15.9621,8.630325 L15.9621,8.630325 L15.949725,8.63775 C15.92745,8.6493 15.906825,8.661675 15.8862,8.67405 L10.59795,11.68695 C10.2193867,11.8873487 9.98281292,12.2807915 9.983325,12.709125 L9.983325,22.460625 L9.983325,22.460625 C9.99256965,22.8820614 10.2306752,23.2649907 10.60455,23.4597 L10.60455,23.4597 L12.707475,24.51075 L12.707475,24.51075 C12.8021429,24.5615296 12.9077752,24.5884333 13.0152,24.589125 C13.1935053,24.5906642 13.3650488,24.520966 13.4917524,24.3955015 C13.618456,24.270037 13.6898368,24.0991868 13.69005,23.920875 L13.69005,23.920875 L13.69005,22.901175 L13.69005,22.901175 C13.69005,22.5316551 13.9896051,22.2321 14.359125,22.2321 C14.5091303,22.232217 14.6546209,22.2834297 14.771625,22.3773 L14.771625,22.3773 L17.52465,24.44805 L17.52465,24.44805 C17.7255929,24.6051291 17.9986141,24.6336052 18.2276462,24.5213724 C18.4566783,24.4091397 18.6014633,24.1759257 18.60045,23.920875 L18.60045,23.920875 L18.60045,22.53075 L18.60045,22.53075 C18.6246746,22.1361026 18.8490898,21.7812714 19.195275,21.59025 L22.32285,19.802475 C22.6942517,19.6095495 22.9322302,19.2308038 22.9449,18.812475 L22.9449,18.812475 L22.9449,12.700875 L22.9449,12.700875 L22.9482,12.700875 Z M16.9686,18.612825 C15.3839531,18.8907125 13.8740656,17.83139 13.5961518,16.2467476 C13.3182379,14.6621053 14.3775353,13.1522003 15.9621729,12.87426 C17.5468106,12.5963197 19.0567333,13.655592 19.3347,15.240225 C19.611642,16.8241702 18.5524434,18.3328264 16.9686,18.61035 L16.9686,18.612825 Z" id="Combined-Shape" fill="#808080"></path>
                                <use id="Shape" stroke="#808080" mask="url(#mask-3)" strokeWidth="4" xlinkHref="#path-2"></use>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
  )
}

export default IconRobo;
