import React from 'react';
import {Link} from 'react-router';
import { connect } from 'react-redux';
import { analyticsLinkClick } from '../../utils';

//actions
import editComponent from '../../component_requests/actions/open_edit_part_modal';

    
// This one changes between mobile and desktops
class MainNavButtons extends React.Component{
  render(){
    let gistLinks = [];
    // On Edge we open new window for embedded gist content like privacy and terms
    if (window.userAgent.browser.name === "Edge" || window.userAgent.browser.name === "IE") {
      gistLinks.push(
        <a className="btn btn-flat menu btn-secondary" key="terms" href="https://s3-eu-west-1.amazonaws.com/storage.circuito.io/terms.html" target="_blank" 
            rel="nofollow" onClick={()=> analyticsLinkClick("terms")}>Terms of service
        </a>);
      gistLinks.push(
        <a className="btn btn-flat menu btn-secondary" key="privacy" href="https://s3-eu-west-1.amazonaws.com/storage.circuito.io/privacy.html" target="_blank" 
            rel="nofollow" onClick={()=> analyticsLinkClick("provacy")}>Privacy
        </a>);
    }
    else {
      gistLinks.push(<Link to="/terms" key="terms" className="btn btn-flat menu btn-secondary" onClick={()=> analyticsLinkClick("terms")}>Terms of service</Link>);
      gistLinks.push(<Link to="/privacy" key="privacy" className="btn btn-flat menu btn-secondary" onClick={()=> analyticsLinkClick("provacy")}>Privacy</Link>);
    }

    return(
      <nav className="main-nav-container">
        <Link to="/" className="btn btn-flat menu" onClick={()=> analyticsLinkClick("")}>Homepage</Link>
        <div className="btn btn-flat menu" onClick={()=>{ analyticsLinkClick("app"); this.props.onAppRootClicked();}}>App</div>
        <Link to="/about" className="btn btn-flat menu" onClick={()=> analyticsLinkClick("about")}>About us</Link>
        <Link to="/faq" className="btn btn-flat menu" onClick={()=> analyticsLinkClick("faq")}>F.A.Q</Link>
        <a className='btn btn-flat menu' href="http://talk.circuito.io" target="_blank" 
          rel="nofollow" onClick={()=>analyticsLinkClick("talk")}>
          Talk
        </a>
        <a className='btn btn-flat menu' href="/blog" onClick={()=>{analyticsLinkClick("blog")}}>
          Blog
        </a>
        <div className="btn btn-flat menu" onClick={ ()=> {analyticsLinkClick("componentsEditor"); this.props.openEditorModal('sidemenu')} }>Components Editor</div>
        <a className="btn btn-flat menu" key="partners" href="/partners" target="_self"  rel="nofollow" onClick={()=> analyticsLinkClick("partners")}>Partners </a>
        {gistLinks}
      </nav>
    )
  }
}

MainNavButtons.displayName = 'MainNavButtons';

MainNavButtons.propTypes = {
  onAppRootClicked: React.PropTypes.func,
};


const mapStateToProps = (state)=> {
  return{}
}

const mapDispatchToProps = {
  openEditorModal: editComponent,
}

export default connect(mapStateToProps,mapDispatchToProps)(MainNavButtons);
