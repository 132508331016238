import React from 'react';
import { connect } from 'react-redux';
import { DropTarget } from 'react-dnd';
import { withRouter } from 'react-router';
import editComponent from '../../component_requests/actions/open_edit_part_modal';
import componentInfoModalOpen from '../actions/component_info_modal_open';
import secondaryComponentInfoModalOpen from '../actions/checkout_secondary_info_modal_open';
import exportComingSoonModalOpen from '../actions/export_coming_soon_modal_open';
import updateSchematicsTooltip from '../actions/update_schematics_tooltip';
import zoomSchematics from '../actions/zoom_schematics';
import addComponent from '../actions/add_component';
import removeComponent from '../actions/remove_component';
import switchComponent from '../actions/switch_component';
import filterInventoryType from '../actions/filter_inventory_type';
import openComponentContextMenu from '../actions/open_component_context_menu';
import toggleSchematicsSettingsMenu from '../actions/toggle_schematics_settings_menu';
import closeComponentContextMenu from '../actions/close_component_context_menu';
import { DRAG_SOURCE_INVENTORY_COMPONENT } from '../constants';
import { SWITCHABLE_COMPONENT_TYPES } from '../../config';
import ReactDOM from 'react-dom';
import { isMagicLink, getTrackDebouncePanZoomFunc } from '../../utils.js';
import BuilderDropOverlay from '../components/builder/builder_drop_overlay';
import ComponentContextMenu from '../components/builder/component_context_menu';
import componentContextMenuIsClosable from '../actions/component_context_menu_is_closable';
import SchematicsControls from '../components/builder/schematics_controls';
import schematicsSettingsMenuIsClosable from '../actions/schematics_settings_menu_is_closable';
import SVGBuilder from './svg_builder';
import validateComponents from '../actions/helpers/validate_components';
import { getInitialComponentIds, getCategories } from '../../utils';
import Btn from '../../app/components/form_controls/btn';

const _ = require('lodash');

class Schematics extends React.Component {
  constructor() {
    super();

    this.isMobile = window.isMobile;
    this._onComponentClicked = this._onComponentClicked.bind(this);
    this._onInfoClick = this._onInfoClick.bind(this);
    this._onMouseMove = this._onMouseMove.bind(this);
    this._updateDimensions = this._updateDimensions.bind(this);
    this._onZoomIn = this._onZoomIn.bind(this);
    this._onZoomOut = this._onZoomOut.bind(this);
    this._onResetZoom = this._onResetZoom.bind(this);
    this._onClearCircuitClick = this._onClearCircuitClick.bind(this);
    this._onUndoClick = this._onUndoClick.bind(this);
    this._onRedoClick = this._onRedoClick.bind(this);
    this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    this.curPos = {x: 0, y: 0};
    this.trackPanZoomDebounceFn = getTrackDebouncePanZoomFunc();
    this.state = {tooltipPos: {x: 0, y: 0}};
  }

  _updateDimensions() {
     this.builderSize = ReactDOM.findDOMNode(this)
      .getBoundingClientRect();
     //Doron : fix for mobile, height was always 0; so need to sample it explicitly.
     if(!this.builderSize.height){
       this.builderSize.height = document.body.offsetHeight;
     }
     if(!this.builderSize.y){
       this.builderSize.y = this.builderSize.top;
     }
     if(!this.builderSize.x){
       this.builderSize.x = this.builderSize.left;
     }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this._updateDimensions);
  }

  componentDidMount() {
    this._updateDimensions();

    window.addEventListener("resize", this._updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  _onMouseMove(pos) {
    this.curPos = {
      x: (pos.x - this.builderSize.x + 15),
      y: (pos.y - this.builderSize.y + 15),
    };

    let tooltipPos = {
      x: this.curPos.x,
      y: this.curPos.y
    };

    // Limiting pos to not overflow the tooltip and context menu
    if (this.builderSize.width - this.curPos.x < 125) {
      this.curPos.x -= (125 - (this.builderSize.width - this.curPos.x));
      tooltipPos.x = this.curPos.x;
    }
    if (this.builderSize.height - this.curPos.y < 125) {
      if (this.builderSize.height - this.curPos.y < 40) {
        tooltipPos.y -= (40 - (this.builderSize.height - this.curPos.y));
      }

      this.curPos.y -= (125 - (this.builderSize.height - this.curPos.y));
    }

    this.setState({tooltipPos});
  }

  _onComponentClicked(component, shouldPropagate,d3Event) {
    if(_.isUndefined(event)){
      var event = d3Event;
    }

    var part = this.props.bomsByName[component.name];
    if (!part) {
      console.error("No such part in parts dict - " + component.name);
      return;
    }

    if (!shouldPropagate) {
         event.stopPropagation();
    }

    analyticsSimple("Schematic Click", "Schematic", "Click",
                    "Schematic Click " + component.name, true);

    if (part.category.includes('support')) {
      // We'll use the part
      this.props.onComponentClicked(part, this.curPos);
    }
    else {
      var beautifulId = null;
      if(component.hasOwnProperty('blockId') && component.blockId !== null){
        beautifulId = component.blockId.replace('!', '').split('_')[0];
      }
      // Reaching blockId from searching the block name
      else {
        var compInfo = null;
        _.map(this.props.componentsById,(item,indx) =>{
          if(item.name === component.name){
            compInfo = item;  
          }
        });

        if (compInfo !== null && compInfo.blockId !== null) {
          beautifulId = compInfo.blockId.replace('!', '').split('_')[0];
        }
      }

      if (beautifulId !== null && this.props.componentsById.hasOwnProperty(beautifulId)) {
        this.props.onComponentClicked(this.props.componentsById[beautifulId], this.curPos);
      }
      else {
        console.error("No such id of component - " + component);
      }
    }
  }

  _onInfoClick() {
    if (!this.props.validationData) {
      return;
    }

    for (var item of this.props.validationData.output.components) {
      if (item.solverId && this.props.contextMenuComponent.blockId) {
        let blockId = item.solverId.replace('!','').split('_')[0];
        if (blockId === this.props.contextMenuComponent.blockId) {
          this.props.onComponentInfoClicked({
            ...item,
            ...this.props.componentsById[blockId]
          }, true);

          return;
        }

      }

      if (item.name === this.props.contextMenuComponent.displayName) {
        this.props.onSecondaryComponentInfoClick({
          ...item,
          ...this.props.contextMenuComponent
        }, true);

        return;
      }
    }
  }

  _onZoomIn(type) {
    this._zoom(Math.min(2, this.props.zoom * 1.1), type);
  }

  _onZoomOut(type) {
    this._zoom(Math.max(0.5, this.props.zoom * 0.9), type);
  }

  _onResetZoom(isFromClick) {
    if (isFromClick) {
      analyticsSimple('Zoom Reset', 'SVG viewer', 'Click', 'Reset zoom', true);
    }

    this.props.onCloseContextMenu();

    this.props.onZoom(1);
  }

  _zoom(zoom, type) {
    this.trackPanZoomDebounceFn('Zoom In/Out', 'SVG viewer', 'Click', `Use zoom in/out (${type})`, true);

    this.props.onCloseContextMenu();

    this.props.onZoom(zoom);
  }

  _onClearCircuitClick() {
    this.props.validate();
  }

  _onUndoClick() {
    this.props.router.goBack();
  }

  _onRedoClick() {
    this.props.router.goForward();
  }

  getIntroText(){
    if(this.isMobile){
        return(<div className='DnDtutorial-container'>
          <div className='DnDtutorial-header text-app-header-1'>Hi there!</div>
          <div className='DnDtutorial-content text-app-header-2'>Find a component from the<br/>search bar above to get started.</div>
        </div>)
    }
    return(<div className='DnDtutorial-container'>
      <div className='DnDtutorial-header text-app-header-1'>Hi there!</div>
      <div className='DnDtutorial-content text-app-header-2'>Drag & drop a component from the panel<br/>on the left to get started!</div>
    </div>)
  }

  render() {
    return(
      this.props.connectDropTarget(
        <div className="work-view-container" style={this.props.isWalkthroughOpen ? {"visibility": "hidden"} : {}}>
          <div className='work-view'>
            <SchematicsControls onZoomInClick={this._onZoomIn}
                                onZoomOutClick={this._onZoomOut}
                                onResetZoomClick={this._onResetZoom}
                                onClearCircuitClick={this._onClearCircuitClick}
                                onUndoClick={this._onUndoClick}
                                isUndoAvailable={true}
                                onRedoClick={this._onRedoClick}
                                isRedoAvailable={true}
                                isSchematicsSettingsMenuOpen={this.props.isSchematicsSettingsMenuOpen}
                                onToggleSchematicsSettingsClick={this.props.onToggleSchematicsSettingsClick}
                                onIsClosable={this.props.onSchematicsSettingsMenuIsClosable}/>
            {this.props.isComponentContextMenuOpen ?
              <ComponentContextMenu component={this.props.contextMenuComponent}
                position={this.props.componentContextMenuPos}
                onInfoClick={this._onInfoClick}
                onEditClick={()=>this.props.onEditClick('canvas')}
                onDuplicateClick={()=>this.props.onComponentAdd(this.props.contextMenuComponent)}
                onRemoveClick={()=>this.props.onComponentRemove(this.props.contextMenuComponent)}
                onSwapClick={()=>this.props.onFilterInventoryType(getCategories(this.props.contextMenuComponent))}
                onIsClosable={this.props.onComponentContextMenuIsClosable}
              />
            :
              (this.props.showTooltip ?
                <span className='builder-alt-text text-app-body-2' style={{left: this.state.tooltipPos.x, top: this.state.tooltipPos.y}}>
                  {this.props.schematicsTooltipText}
                </span>
              : null)
            }
            <BuilderDropOverlay isVisible={this.props.isDropOverlayVisible}/>
            { (this.props.fadeCanvasOpacity && !isMagicLink()) ?
                this.getIntroText()
              : null }
            <SVGBuilder fadeCanvasOpacity={this.props.fadeCanvasOpacity}
                        validationData={this.props.validationData}
                        svgHoverIds={this.props.svgHoverIds}
                        onComponentClicked={this._onComponentClicked}
                        zoom={this.props.zoom}
                        onZoomIn={this._onZoomIn}
                        onZoomOut={this._onZoomOut}
                        onZoomPinch={this._zoom}
                        onResetZoom={this._onResetZoom}
                        onMouseMove={this._onMouseMove}
                        builderSize={this.builderSize}
                        componentsById={this.props.componentsById}
                        bomsByName={this.props.bomsByName}
                        onShowTooltip={this.props.onShowTooltip}/>
          </div>
        </div>
      )
    )
  }
}

Schematics.displayName = 'Schematics';

Schematics.propTypes = {
  validationData: React.PropTypes.object,
  connectDropTarget: React.PropTypes.func, // For react dnd
  isDropOverlayVisible: React.PropTypes.bool,
  onComponentClicked: React.PropTypes.func,
  onSecondaryComponentInfoClick: React.PropTypes.func,
  isComponentContextMenuOpen: React.PropTypes.bool,
  contextMenuComponent: React.PropTypes.object,
  componentContextMenuPos: React.PropTypes.object,
  onComponentInfoClicked: React.PropTypes.func,
  onComponentAdd: React.PropTypes.func,
  onComponentRemove: React.PropTypes.func,
  onFilterInventoryType: React.PropTypes.func,
  componentsById: React.PropTypes.object,
  bomsByName: React.PropTypes.object,
  onComponentContextMenuIsClosable: React.PropTypes.func,
  showTutorial: React.PropTypes.bool,
  isWalkthroughOpen: React.PropTypes.bool,
  svgHoverIds: React.PropTypes.object,
  validationPeripheralComponentsData: React.PropTypes.array,
  onShowTooltip: React.PropTypes.func,
  zoom: React.PropTypes.number,
  showTooltip: React.PropTypes.bool,
  schematicsTooltipText: React.PropTypes.string,
  onToggleSchematicsSettingsClick: React.PropTypes.func,
  fadeCanvasOpacity: React.PropTypes.bool,
  isSchematicsSettingsMenuOpen: React.PropTypes.bool,
  validate: React.PropTypes.func,
  onZoom: React.PropTypes.func,
  onSchematicsSettingsMenuIsClosable: React.PropTypes.func,
  onCloseContextMenu: React.PropTypes.func,
}

// Wrap for react dnd
// specs

// Return the dragged data so we can add it the circuit
const drop = (props, monitor, component) => {
  const item = monitor.getItem().component;

  // Check if the component shuold be switched
  if(SWITCHABLE_COMPONENT_TYPES.some(switchable => item.category.includes(switchable))){
    props.onComponentSwitch(item);
  } else {
    props.onComponentAdd(item);
  }
}

const collect = (connect, monitor) => {
  return({
    connectDropTarget: connect.dropTarget(),
  })
}

const mapStateToProps = (state) => {
  return({
    validationData: state.app.validationData,
    fadeCanvasOpacity: state.requests.fadeCanvasOpacity,
    isDropOverlayVisible: state.requests.isDropOverlayVisible,
    componentsById: state.requests.componentsById,
    bomsByName: state.requests.bomsByName,
    isComponentContextMenuOpen: state.requests.isComponentContextMenuOpen,
    isSchematicsSettingsMenuOpen: state.requests.isSchematicsSettingsMenuOpen,
    contextMenuComponent: state.requests.contextMenuComponent,
    componentContextMenuPos: state.requests.componentContextMenuPos,
    showTutorial: state.app.showTutorial,
    isWalkthroughOpen: state.requests.walkthroughModalIsOpen,
    svgHoverIds: state.requests.svgHoverIds,
    showTooltip: state.requests.isSchematicsTooltipVisible,
    schematicsTooltipText: state.requests.schematicsTooltipText,
    zoom: state.requests.schematicsZoom,
  })
}

const mapDispatchToProps = {
  onComponentAdd: addComponent,
  onComponentRemove: removeComponent,
  onEditClick:  editComponent,
  onComponentSwitch: switchComponent,
  onFilterInventoryType: filterInventoryType,
  onComponentClicked: openComponentContextMenu,
  onToggleSchematicsSettingsClick: toggleSchematicsSettingsMenu,
  onComponentInfoClicked: componentInfoModalOpen,
  onSecondaryComponentInfoClick: secondaryComponentInfoModalOpen,
  onComponentContextMenuIsClosable: componentContextMenuIsClosable,
  onSchematicsSettingsMenuIsClosable: schematicsSettingsMenuIsClosable,
  onCloseContextMenu:closeComponentContextMenu,
  onShowTooltip: updateSchematicsTooltip,
  onZoom: zoomSchematics,
  validate: ()=> (dispatch, getStateFunc) => validateComponents(dispatch, getStateFunc, getInitialComponentIds()),
}

// Wrap the builder in a dnd HOC
Schematics = DropTarget(DRAG_SOURCE_INVENTORY_COMPONENT, {drop}, collect)(Schematics);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Schematics));
