import React from 'react';
import ImageLoader from 'react-imageloader';
import Loader from './loader';

const Image = ({className, src,effect,loader}) =>{
  const imgLoader = loader || ( () => <Loader imgProps={{className}} /> )
  return(
    <ImageLoader src={src}
             preloader={imgLoader}
             imgProps={{className: `${effect || 'fadein'} ${className || ''}`}} />
  )
}

Image.displayName = 'Image';
Image.propTypes = {
  src: React.PropTypes.string.isRequired,
  className: React.PropTypes.string,
}

export default Image;
