import * as types from '../constants';

export default function(zoom){
  return (dispatch, getStateFunc) => {
  	if (getStateFunc().requests.schematicsZoom !== zoom) {
      dispatch({
        type: types.SCHEMATICS_ZOOM,
        zoom
      });
	}
  }
}
