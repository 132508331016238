import {campaignParams} from "./utils";
import Cookies from 'universal-cookie';
import createFetch from 'fetch-ponyfill';

let ip = require('ip');
const uuid = require('uuid');
const {fetch} = createFetch();

let analytics = analytics=window.analytics=window.analytics||[];

let cookies = new Cookies();
let hasRobotsCookie = (cookies.get('circuitoRobot')) ? true : false;
if(window.location.href.indexOf('circuitoRobot') != -1){
    cookies.set('circuitoRobot', true);
    hasRobotsCookie = true;
}

window.country = null;

window.userId = cookies.get('userId');

if (window.userId === "undefined"
    || window.userId === "null"
    || window.userId === null
    || typeof(window.userId) === "undefined"
    || !window.userId) {
  window.userId = uuid.v4();
}

if (window.userCountry) {
  windpw.userCountry = window.userCountry.country;
}

if (window.segmentAPIKey && !hasRobotsCookie) {
  !function(){if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","page","once","off","on"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t){var e=document.createElement("script");e.type="text/javascript";e.async=!0;e.src=("https:"===document.location.protocol?"https://":"http://")+"cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(e,n)};analytics.SNIPPET_VERSION="3.1.0";
    analytics.load(window.segmentAPIKey);
    analytics.identify(window.userId);
    analytics.page(document.title);
    if (window.userId === "undefined"
    || window.userId === "null"
    || window.userId === null
    || typeof(window.userId) === "undefined"
    || !window.userId) {
      window.userId = uuid.v4();
    }
    cookies.set('userId', window.userId, { path: '/' });
  }}();
}
else {
  console.log('Segment analytics is disabled, stubbing object. You can enable it by setting the SEGMENT_CLIENT_API_KEY env var');

  // Stub analytics
  ['identify', 'track', 'page', 'alias', 'group', 'trackLink', 'trackForm', 'reset', 'debug', 'on', 'timeout'].forEach((method)=>{
    analytics[method] = (() =>{
      console.log('An attempt has been made to call a stubbed segment analytics object');
    });
  })
}

window.analyticsSimple = function(name, category, action, label, isTrackMode, isLegacyLabel) {
  let props = {
    'event category': category,
    'event action': action
  };
  if (typeof(isLegacyLabel) === 'undefined' || !isLegacyLabel) {
    props['event label'] = label;
  }
  else {
   props['label'] = label; 
  }

  window.analytics.track(
    name,
    props,
    {
      integrations: {
        'All': true,
        'Intercom': false,
      }
    }
  );
}

setTimeout(()=>{
  campaignParams();

  //cross-domain tracking for foxyCart
  if(typeof(ga) != 'undefined'){
    ga('create', 'UA-58275254-7', 'auto', {'allowLinker': true});
    ga('require', 'linker');
    ga('linker:autoLink', []);

    console.log('cross domain tracking set')
  }
}, 10000);

export default analytics;
