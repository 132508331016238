import React from 'react';
import { connect } from 'react-redux';
import closeSidebar from '../actions/header_sidebar_close'
import Overlay from './overlay';

// This one changes between mobile and desktops
const SideBar = ({onCloseClick, isSidebarOpen, children}) =>{
  return(
    <div className={isSidebarOpen ? "sidebar-container" : "sidebar-container sidebar-container-closed"}>
      <Overlay isVisible={false} isClickable={true} onClick={onCloseClick} />
      <div className={isSidebarOpen ? "sidebar-content" : "sidebar-content sidebar-content-closed"}>
        <button type="button" className="sidebar-close-button close" onClick={onCloseClick}>
          <i className='material-icons icon'>close</i>
        </button>
        {children}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCloseClick: ()=>{
      dispatch(closeSidebar());
    }
  }
};

const mapStateToProps = ({app})=>{
  return{
    isSidebarOpen: app.isSidebarOpen,
  }
}


SideBar.displayName = 'SideBar';
SideBar.propTypes = {
  onCloseClick: React.PropTypes.func,
  isSidebarOpen: React.PropTypes.bool,
  children: React.PropTypes.node.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
