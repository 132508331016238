//
// Wrap inputs once and for all
// 
import React from 'react';
import MaterialIcon from '../material_icon';
import { generateId } from '../../../utils';

class InputWrapper extends React.Component{
  constructor(props){
    super(props)
    this.htmlId = generateId();
  }

  render(){
    return(
      <div className={`form-group ${this.props.value === undefined || this.props.value === "" ? "is-empty" : null} ${this.props.label && this.props.isFloating ? "label-floating" : "label-placeholder"}`}>
        <div className="input-group">
          <div className="input-group-addon">
            {this.props.icon ? <MaterialIcon name={this.props.icon} /> : null}
          </div>
          {this.props.label ? this._renderLabel() : null}
          {this.props.children}
        </div>
      </div>
    );
  }

  _renderLabel(){
    return(
      <label htmlFor={this.htmlId} className="control-label">
        {this.props.label}
      </label>
    );
  }
}
InputWrapper.displayName = "InputWrapper";
InputWrapper.propTypes = {
  icon: React.PropTypes.string,
  isFloating: React.PropTypes.bool,
  value: React.PropTypes.string,
  label: React.PropTypes.string,
}
InputWrapper.defaultProps = {
  isFloating: false,
}
export default InputWrapper;
