import React from 'react';
import Overlay from '../../../app/components/overlay';
import IconAddCircle from '../../../app/components/icons/icon_add_circle';

const BuilderDropOverlay = ({isVisible}) => {
  return(
    <Overlay isVisible={isVisible} className='builder-drop-overlay'>
      <div className='builder-drop-overlay-border' />
      <IconAddCircle />
      <h1 className="text-app-header-1">
        Drag and drop components here
      </h1>
    </Overlay>
  )
}

BuilderDropOverlay.propTypes = {
  isVisible: React.PropTypes.bool.isRequired,
}

export default BuilderDropOverlay;
