import React from 'react';
import { DragSource } from 'react-dnd';
import Image from '../../app/components/image';
import { DRAG_SOURCE_INVENTORY_COMPONENT, COMPONENT_DEFAULT_IMAGE } from '../constants';
import ComponentStatus from './component_status';
import MediaQuery from 'react-responsive';
import { ON_MOBILE_MQ, ON_DESKTOP_MQ, SWITCHABLE_COMPONENT_TYPES } from '../../config'
import { getCategories } from '../../utils'

// The component's display box
class InventoryComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {isHovered: false};
  }

  _handleOnInfoClick(e){
    e.stopPropagation();

    analyticsSimple('Component List Click', 'Component List',
                    'Click', 'Component List Click ' + this.props.component.app.appName, true);

    this.props.onClick(this.props.component);
  }

  _renderComponent(component) {
    
    //DORON: temp fix until we have images data 

    let preLoader = () => { return <img className='inventory-component-image component-sm-image default-image' src="/static/images/component-default-image-blue.svg"/> }
    return(
      <div>
        <MediaQuery query={ON_MOBILE_MQ}>
          <figure className={`inventory-component ${component.isEnabled ? '' : 'disabled'} `}
                  onClick={this._handleOnInfoClick.bind(this)}>
            <Image loader={preLoader} effect="" className={`inventory-component-image component-sm-image ${component.isEnabled ? '' : 'disabled-image'} ${component.app.image ? 'default-image' : null}`}
              src={(!component.app.image) ? COMPONENT_DEFAULT_IMAGE : component.app.image.replace('/upload/','/upload/w_75,h_75,c_scale/')} />
            <span className="inventory-component-text">{component.app.appName}</span>
          </figure>
        </MediaQuery>
        <MediaQuery query={ON_DESKTOP_MQ}>
          <Hover onHover={this._getOnHover(component)}>
            <figure className={`inventory-component ${component.isEnabled ? '' : 'disabled'} `}
                    onClick={this._handleOnInfoClick.bind(this)} onTouchEnd={this._handleOnInfoClick.bind(this)} >
              <Image loader={preLoader} effect="" className={`inventory-component-image component-sm-image ${component.isEnabled ? '' : 'disabled-image'} ${component.app.image ? 'default-image' : null}`}
                src={(!component.app.image) ? COMPONENT_DEFAULT_IMAGE : component.app.image.replace('/upload/','/upload/w_75,h_75,c_scale/')} />
              <span className="inventory-component-text">{component.app.appName}</span>
            </figure>
          </Hover>
        </MediaQuery>
      </div>
    );
  }

  _getSupportedCtrlMsg(component, ctrlName){
    if(!component){
      return null;
    }

    var compIsSwappable = component.category.some(cat=> SWITCHABLE_COMPONENT_TYPES.includes(cat));

    if(component.isEnabled && compIsSwappable){
      for (var cat of component.category) {
        if (SWITCHABLE_COMPONENT_TYPES.includes(cat)) {
          return(`Only one ${cat} is allowed. Selecting this ${cat} will swap existing ${cat}.`);
        }
      }
    }
    else if (!component.isEnabled) {
      if(component.disabledBy === ctrlName){
        let isOnlySupportedByRpi = ( Object.keys(component.supportedControllers).length === 1 && component.supportedControllers['Rpi'] );
        if( ctrlName === 'Rpi'){
          if(!component.supportedControllers['Rpi']){
                return("This part is not compatible with Raspberry Pi");
          }
        }
        else{
          if(isOnlySupportedByRpi){
            return("This part is only compatible with Raspberry Pi");
          }
          else {
            return('This component cannot work with the current controller');
          }
        }
      }
      else if (component.category.includes("connectivity")
                && component.disabledBy === component.name) {
        return("Can't have duplicated of connectivity components (You already have this one)");
      }
    }

    return('');
  }

  _getOnHover(component){
    let msg = this._getSupportedCtrlMsg(component,this.props.ctrlName);
    if(!msg){
      return(
        <figure className={`inventory-component ${component.isEnabled ? '' : 'disabled'} `} onClick={this._handleOnInfoClick.bind(this)}>
          <span className="inventory-component-text">{component.app.appName}</span>
          <div className='inventory-component-status-container'>
            <img className='grip' src='/static/images/grip.svg'/>
            <ComponentStatus component={component} align='vertical' isBlue={true}
                              controllerName={this.props.ctrlName}/>
          </div>
        </figure>);
    }else{
      return(
        <figure className={`inventory-component with-msg ${component.isEnabled ? '' : 'disabled'} `} onClick={this._handleOnInfoClick.bind(this)}>
          <span className="inventory-component-text">{msg}</span>
        </figure>
      );
    }
  }

  _getDefaultCompBody(component){
    return(
      <div className={`inventory-component-caption ${component.isEnabled ? '' : 'inventory-component-caption-disabled'}`}>
        <h3>
          {component.app.appName}
        </h3>
        <p dangerouslySetInnerHTML={{__html: component.description}}>
        </p>
    </div>)
  }

  render() {
    if (this.props.component.isEnabled) {
      return(this.props.connectDragSource(
        <div id={this.props.component.name} className="inventory-component-container" style={this.props.componentSize ? {width: this.props.componentSize, height: this.props.componentSize} : null}>
          {this._renderComponent(this.props.component)}
        </div>)
      );
    }
    else {
      return(
        <div id={this.props.component.name} className="inventory-component-container" style={this.props.componentSize ? {width: this.props.componentSize, height: this.props.componentSize} : null}>
          {this._renderComponent(this.props.component)}
        </div>
      )
    }
  }
}

InventoryComponent.displayName = 'InventoryComponent';
InventoryComponent.propTypes = {
  ctrlName:React.PropTypes.string.isRequired,
  component: React.PropTypes.object.isRequired,
  connectDragSource: React.PropTypes.func.isRequired, // Handled by react dnd
  connectDragPreview: React.PropTypes.func.isRequired, // Handled by react dnd
  onDragStart: React.PropTypes.func.isRequired,
  onDragEnd: React.PropTypes.func.isRequired,
  onClick: React.PropTypes.func,
  onActionClick: React.PropTypes.func,
}

const Hover = ({onHover, children}) => (
    <div className="hover">
        <div className="hover__no-hover">{children}</div>
        <div className="hover__hover">{onHover}</div>
    </div>
)

// Dnd specs
const beginDrag = (props) => {
  analyticsSimple('Component List Drag', 'Component List', 'Drag',
                  'Component List Drag ' + props.component.app.appName, true);

  props.onDragStart();

  return({
    component:  props.component,
  })
};

const endDrag = (props) => {
  props.onDragEnd();
}

const collect = (connect) => {
  return({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview()
  })
}

export default DragSource(DRAG_SOURCE_INVENTORY_COMPONENT, {beginDrag, endDrag}, collect)(InventoryComponent);
