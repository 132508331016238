import * as types from '../constants';

/**
 * Opens the component context menu for a component
 * @param  {object} component A component object
 * @param  {x, y object} componentContextMenuPos the position the context menu should open at
 * @return {function} a function for redux-thunk middleware
 */
export default function(component, componentContextMenuPos){
  return (dispatch) => {
    dispatch({
      type: types.COMPONENT_CONTEXT_MENU_OPEN,
      component,
      componentContextMenuPos,
    });
  }
}
