import { SEARCH_INVENTORY, TOGGLE_ACTIVE_TAB } from '../constants';
import focusSearchBar from './focus_search_bar';

export default function(searchTerm) {
	return (dispatch) => {
		dispatch({
				type: TOGGLE_ACTIVE_TAB,
				actionType: "DESIGN",
		});
		// First lets focus on the search bar tab
		dispatch(focusSearchBar(true));

		dispatch({
    		type: SEARCH_INVENTORY,
    		searchTerm
  		});
	}
}
