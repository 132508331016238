/**
 * Created by roboamir on 07/08/16.
 */
///
/// Manages the notification bar
///
import React from 'react';
import { connect } from 'react-redux';
import hideNotification from '../actions/hide_notification';
import openNotificationModal from '../actions/notification_modal_open';
import { NotificationTypes } from '../actions/show_notification';
import { NOTIFICATION_AUTO_DISMISS_TIME } from '../../config';

class Notification extends React.Component {

  constructor(props) {
    super(props);
    this.notificationTimeout = null;
  }

  componentDidMount(){
    this._handleTimeout();
  }

  componentDidUpdate(prevProps) {
    // Procceed only if we have a new notification and it's auto-dismissed
    if (this.props.notificationID !== prevProps.notificationID) {
      this._handleTimeout();
    }
  }

  _handleTimeout(){
    // Remove last timeout
    if (!this.notificationTimeout) {
      window.clearTimeout(this.notificationTimeout);
    }

    if(this.props.isAutoDismissed){
      // We will set a timeout that dismisses it if no new notifications were shown beyond this. cuz otherwise the timeout will get cleared like the above
      this.notificationTimeout = setTimeout(() => {
        // If when the timeout ends the latest notification is visible, we dismiss it
        if (this.props.isVisible) {
          this.props.onDismissClick();
        }
      },
      this.props.dismissIntervalMs);
    }
  }

  render() {
    return (
      <div className={this.props.isVisible ? `notification alert ${this.props.notificationType} text-app-body-2` : `notification alert notification-invisible ${this.props.notificationType}`}>
        <a href="#" onClick={this.props.onDismissClick} className="close" aria-label="close">&times;</a>
        {this.props.notificationText}
        {(window.username !== process.env.MAIN_USER)
            && this.props.notificationDetails && this.props.notificationDetails.short ?
          <a href="#" onClick={this.props.onDetailsClick}> (Details...)</a>
        : null}
      </div>
    );
  }
}

Notification.displayName = 'Notification';
Notification.propTypes = {
  notificationID: React.PropTypes.number.isRequired,
  isVisible: React.PropTypes.bool,
  notificationType: React.PropTypes.oneOf(Object.values(NotificationTypes)),
  notificationText: React.PropTypes.string,
  notificationDetails: React.PropTypes.string,
  onDismissClick: React.PropTypes.func,
  onDetailsClick: React.PropTypes.func,
  dismissIntervalMs: React.PropTypes.number,
  isAutoDismissed: React.PropTypes.bool,
};

Notification.defaultProps = {
  dismissIntervalMs: NOTIFICATION_AUTO_DISMISS_TIME,
}

const mapDispatchToProps = (dispatch) => {
  return {
    onDismissClick: ()=>{
      dispatch(hideNotification());
    },

    onDetailsClick: ()=>{
      dispatch(openNotificationModal());
    }
  }
};

const mapStateToProps = ({app})=>{
  return{
    isVisible: app.isNotificationVisible,
    notificationType: app.notificationType,
    notificationText: app.notificationText,
    notificationDetails: app.notificationDetails,
    onDismissClick: app.onNotificationDismissClick,
    isAutoDismissed: app.isNotificationAutoDismissed,
    notificationID: app.notificationID,
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Notification);
