/**
 * Created by roboamir on 10/08/16.
 */
///
/// removes a component. validating first
///
import * as types from '../constants';
import validateComponents from './helpers/validate_components';
import { getSelectedComponentsIds } from '../selectors';

export default function(componentToRemove) {
    return (dispatch, getStateFunc) => {
        // First we remove the component from our selected components
        let selectedComponentsMinusRemoved = [...getSelectedComponentsIds(getStateFunc())];

        var i = selectedComponentsMinusRemoved.indexOf(componentToRemove.blockId);
        if (i === -1) {
            throw new Error(`Trying to delete an unselected item (id: ${componentToRemove.blockId})`);
        }

        analyticsSimple('Modify Component', 'Modify Component', 
                            'Remove', componentToRemove.app.appName, true, true);

        // Removing
        selectedComponentsMinusRemoved.splice(i, 1);

        // Validate our new selected components
        return validateComponents(dispatch, getStateFunc, selectedComponentsMinusRemoved, 
                                    types.COMPONENT_REMOVED)
        .catch((error) => {});
    }
}