import React from 'react'
import { connect } from 'react-redux';

// Actions
import getCodeContent from '../actions/get_code_content';
// Components
import BasicTab from './basic_tab';
import AceEditor from 'react-ace';
import brace from 'brace';
import 'brace/mode/csharp';
import 'brace/mode/python';
import 'brace/theme/monokai';

import { Btn } from '../../app/components/form_controls';
import ReactTooltip from 'react-tooltip'
import MediaQuery from 'react-responsive';
import FixedCard from '../components/fixed_card';
import {ON_MOBILE_MQ, ON_DESKTOP_MQ} from '../../config.js'

// Utils
const _ = require('lodash');


class CodeTab extends React.Component{
  constructor(props){
    super(props);
    this.isRpi = (this.props.selectedComponentsIds.indexOf('200000') != -1);
    this.firmwareNames = ["Code.py","Firmware.ino"];
    this.state = {
      activeFile:(this.isRpi) ? this.firmwareNames[0] : this.firmwareNames[1],
      fileSelected:false
    }
  }

  _renderComponents(){
    return(
      <div className='code upper-desktop-container'>
        <FixedCard styleName="code-card">
          <MediaQuery query={ON_DESKTOP_MQ}>
            {this._getCodeList()}
          </MediaQuery>
          <MediaQuery query={ON_MOBILE_MQ}>
            {this._getMobileCodeList()}
          </MediaQuery>
        </FixedCard>
        <MediaQuery query={ON_MOBILE_MQ}>
          {this._getMobileWorkView()}
        </MediaQuery>
        <MediaQuery query={ON_DESKTOP_MQ}>
          {this._getWorkView()}
        </MediaQuery>
      </div>
    );
  }

  _onMobileBackBtn(){
      this.setState({fileSelected:false});
  }

  _getMobileWorkView(){
    if(this.state.fileSelected){
      return(
        <div className="code-mobile-workview">
          <div className="work-view-container">
            <div className="work-view-mobile">
              <div className="code-tab-mobile-back code-header" onClick={ () => this._onMobileBackBtn() }>
                <i className="material-icons">keyboard_arrow_left</i>
                <span>Back</span>
              </div>
              {this._getWorkView()}
              {this._getDownloadBtn()}
            </div>
          </div>
        </div>
      )
    }
  }
  _getWorkView(){
    const isFirwareActiveFile = (this.firmwareNames.indexOf(this.state.activeFile) != -1);
    const editorVal = (this.props.codeFile && !isFirwareActiveFile) ? this.props.codeFile.codeFile :  (this.isRpi) ? this.props.validationData.output.code.mainFileContent : this.props.validationData.output.code.mainFileContent;
    var lang = (this.state.activeFile.indexOf(".py") !== -1) ? "python" : "csharp";
    return(
      <div className="work-view-container">
        <div className="work-view-desktop">
          <div className="code-workspace-container">
            <div className="preview-bnr">
              <span className="text-app-body-2">
                This is a preview, to use the code please download and extract it.
              </span>
            </div>
            <div className="code-editor">
              <AceEditor
                readOnly={true}
                mode={lang}
                theme="monokai"
                name="ace_editor_container"
                width="100%"
                height="100%"
                highlightActiveLine={false}
                value={editorVal}
                showGutter={true}
                showPrintMargin={false}
                editorProps={{$blockScrolling: true}}/>
            </div>
          </div>
        </div>
      </div>
    );
  }

  trackCodeFileClicked(fileName){
    analyticsSimple("Code File Click", "Code", "Click", fileName, true);
  }

  _onFirmwareClicked(){
    let fileName = (this.isRpi) ? this.firmwareNames[0] : this.firmwareNames[1];
    this.trackCodeFileClicked(fileName);
    this.setState({activeFile:fileName,fileSelected:true})
  }

  _onFileClick(item,fileName){
    this.trackCodeFileClicked(fileName);
    this.props.onGetCodeContent(item,fileName);
    this.setState({activeFile:fileName,fileSelected:true})
  }

  _getMobileCodeList(){
    if(this.state.fileSelected){
        return null;
    }
    return this._getCodeList();
  }

  _getCodeFilesList(){
    if(this.props.validationData && this.props.validationData.output.code){
      const jsonObj = this.props.validationData.output.code.additionalFiles;
      const jsonKeys = Object.keys(jsonObj);
      var codeItems = [];
      jsonKeys.map( (item,index) => {
            codeItems.push(
              jsonObj[item].map( (codeItem,indx) => {
                let fileClassList = ['h','cpp']
                let fileType = codeItem.substring(codeItem.indexOf('.') + 1, codeItem.length)
                let fileClass = (fileClassList.indexOf(fileType) !== -1) ? fileType : 'txt';
                let isActiveFile = (this.state.activeFile === codeItem) ? "active" : " ";
                return(<div key={indx} className={`code-item text-app-body-2 file-${fileClass} ${isActiveFile}`} onClick={() => {this._onFileClick(item,codeItem)} }>{codeItem}</div>);
            })
          )
      })
    }
    return codeItems;
  }

  _getCodeList(){
    const imgUrl =  `static/images/walkthough_step_5_mobile.svg`;
    const isFirwareActiveFile = (this.firmwareNames.indexOf(this.state.activeFile) !== -1) ? "active" : " ";
    const firmwareText = (this.isRpi) ? this.firmwareNames[0] : this.firmwareNames[1];
    const zipFileName = (this.isRpi) ? "Code.zip" : "Firmware.zip";
    return(
      <div className="CodeTab-container line-seperator">
        <div className="CodeTab-html">
            <div className="code-header text-app-header-2">
              Files:
            </div>
            <div className={`code-item file-folder text-app-body-2 main-code firmware`}>
                {zipFileName}
            </div>
            <div className={`code-item file-ino text-app-body-2 ${isFirwareActiveFile}`}>
              <div className="firmware" onClick={ () => {this._onFirmwareClicked()} }>
                {firmwareText}
              </div>
            </div>
            {this._getCodeFilesList()}
        </div>
          {this._getDownloadBtn()}
      </div>
    )
  }

  _getDownloadBtn(){
    const codeFileName = (this.isRpi) ? "Code.zip" : "Firmware.zip";
    const projectLink = `${location.protocol}//${this.props.validationData.solutionPath}/circuitos/${this.props.validationData.solutionId}/${codeFileName}`;
    const onClick = () => { analyticsSimple('Download zip','Action Click','Download','Download zip (code-in-app)', true) };
    const label = (this.isRpi) ? "DOWNLOAD CODE.ZIP" : "DOWNLOAD FIRMWARE.ZIP";
    return(<div className="CodeTab-footer">
            <div className="link">
                <a href={projectLink} target="_self">
                   <Btn type="primary" icon="file_download" onClick={onClick} label={label} isValid={true}></Btn>
               </a>
             </div>
          </div>)
  }

  render(){
    // if(this.props.selectedComponentsIds.indexOf('200000') != -1 ){
    //   return (
    //     <BasicTab title="Raspberry Pi Beta Release does not yet <br/> support code libraries for Raspberry Pi."
    //               text="To set up your Raspberry Pi and start working with <br/> component libraries, consult <a href='https://www.raspberrypi.com' target='_blank' class='more-info'>www.raspberrypi.com</a>"
    //               image={<img src='static/images/circuito_Raspberry_Pi_3B.svg'/>}/>
    //   );
    // }
    if(this.props.selectedComponentsIds.length > 2){
        return this._renderComponents()
    }
    return (
      <BasicTab
          title="You don't have a project to code yet"
          text="Add components to your schematic in the Design step to start your project."/>
    );
  }
}

CodeTab.displayName = 'CodeTab';
CodeTab.propTypes = {
  validationData: React.PropTypes.object,
  codeFile:React.PropTypes.object,
  selectedComponentsIds: React.PropTypes.array,
}

CodeTab.defaultProps = {
}

const mapStateToProps = (state) => {
  return({
    validationData:state.app.validationData,
    codeFile:state.requests.codeFile,
    selectedComponentsIds: state.requests.selectedComponentsIds,
  })
}

const mapDispatchToProps = {
  onGetCodeContent: getCodeContent,
}

export default connect(mapStateToProps, mapDispatchToProps)(CodeTab);
