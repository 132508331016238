import React from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router';
import Loader from '../../app/components/loader';
import {Btn} from '../../app/components/form_controls';
import ListComponentRequestItem from '../components/list_component_request_item';

// Action creators
import loadComponentRequests from '../actions/load_component_requests';

class ListComponentRequestsPage extends React.Component{

  componentDidMount(){
    this.props.onLoad();
  }

  render(){
    if(!this.props.componentRequests){
      return(
        <Loader />
      )
    } else
      return(
        <div className='static-page text-center'>
          <div className='container'>
            <h1 className="text-app-sub-header-1">Add a part, be a part.</h1>
            <div>
              <p className="text-app-body-2">circuito.io is growing! We aim to connect more and more electronics with the help of our devoted community. You're welcome to add a part using the form below. If you know how to wire or code for the component - fill in as many details as possible. We'll review your contribution & introduce it into circuito.io as soon as we can.</p>
            </div>
            <br />
            <div className='list-component-requests-list-actions text-app-header-2'>
              <Link to='/component_request'>
                <Btn type="primary" label="Create a part"></Btn>
              </Link>
            </div>
            <br />
            <div className='divider'>
            </div>
            <div className='component-requests-list'>
              {this.props.componentRequests.map((comp)=>{
                return(<ListComponentRequestItem
                  key={comp.circuitoId}
                  componentRequest={comp}/>);
              })}
            </div>
          </div>
        </div>
      )
  }
}
ListComponentRequestsPage.propTypes = {
  onLoad: React.PropTypes.func,
  componentRequests: React.PropTypes.object,
}

const mapStateToProps = ({componentRequests}) => {
  return({
    ...componentRequests,
  });
};

const mapDispatchToProps = {
  onLoad: loadComponentRequests,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListComponentRequestsPage);
