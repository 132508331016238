import React from 'react';

const ToggleButton = ({label, isToggled, onToggle})=>{
  return(
    <div className="togglebutton">
      <label>
        {label}
        <input type="checkbox" checked={isToggled} onChange={onToggle} />
        <span className="toggle"/>
      </label>
    </div>
  )
}

ToggleButton.displayName = "ToggleButton";
ToggleButton.propTypes = {
  onToggle: React.PropTypes.func.isRequired,
  isToggled: React.PropTypes.bool,
  label: React.PropTypes.string
}
ToggleButton.defaultProps = {
  isToggled: true
}

export default ToggleButton;
