import React from 'react'

class SchematicsControls extends React.Component{
  constructor(props) {
    super(props);

    this._onUndoClick           =           this._onUndoClick.bind(this);
    this._onRedoClick           =           this._onRedoClick.bind(this);
    this._renderMenuItem        =        this._renderMenuItem.bind(this);
    this._onResetZoomClick      =      this._onResetZoomClick.bind(this);
    this._onClearCircuitClick   =   this._onClearCircuitClick.bind(this);
    this._openSettingsMenuClick = this._openSettingsMenuClick.bind(this);
  }

  render(){
    return (
      <div className={`pan-zoom-ctrl ${window.isMobile ? 'in-mobile' : 'in-desktop'}`}>
        {window.isMobile ? null
          : <img onClick={()=>this.props.onZoomInClick('button')} src='/static/images/zoom-in.svg'/>}
        {window.isMobile ? null
          : <img onClick={()=>this.props.onZoomOutClick('button')} src='/static/images/zoom-out.svg'/>}
        <div>
          <img className='settings-button' onClick={this._openSettingsMenuClick} src='/static/images/settings.svg'/>
          {this.props.isSchematicsSettingsMenuOpen ?
            this._renderSchematicsSettingsMenu()
          : null}
        </div>
      </div>
    );
  }

  componentWillUpdate(nextProps) {
    // If the menu was just opened, it is now closable (the DEFAULT_CLICK can work now)
    if (!this.props.isSchematicsSettingsMenuOpen && nextProps.isSchematicsSettingsMenuOpen) {
      this.props.onIsClosable();
    }
  }

  _openSettingsMenuClick() {
    analyticsSimple('Settings', 'SVG viewer', 'Click', 'Settings', true);

    this.props.onToggleSchematicsSettingsClick(true);
  }

  _renderSchematicsSettingsMenu() {
    return(
      <div className='component-context-menu schematics-settings-menu'>
        {window.isMobile ? null
          : this._renderMenuItem('RESET ZOOM', 'refresh', this._onResetZoomClick, true)}
        {/*this._renderMenuItem('UNDO', 'undo', this._onUndoClick, this.props.isUndoAvailable)*/}
        {/*this._renderMenuItem('REDO', 'redo', this._onRedoClick, this.props.isRedoAvailable)*/}
        {this._renderMenuItem('CLEAR CIRCUIT', 'cancel', this._onClearCircuitClick, true)}
      </div>
    );
  }

  _onResetZoomClick() {
    this.props.onResetZoomClick(true);
    this.props.onToggleSchematicsSettingsClick(false);
  }

  _onUndoClick() {
    analyticsSimple('Undo', 'SVG viewer', 'Click', 'Undo', true);

    this.props.onUndoClick();
    this.props.onToggleSchematicsSettingsClick(false);
  }

  _onRedoClick() {
    analyticsSimple('Redo', 'SVG viewer', 'Click', 'Redo', true);

    this.props.onRedoClick();
    this.props.onToggleSchematicsSettingsClick(false);
  }

  _onClearCircuitClick() {
    analyticsSimple('Clear Circuit', 'SVG viewer', 'Click', 'Clear Circuit', true);

    this.props.onClearCircuitClick();
    this.props.onToggleSchematicsSettingsClick(false);
  }

  _renderMenuItem(text, icon, action, isEnabled) {
    isEnabled = (isEnabled ? '' : 'disabled');

    return(
      <div className={'component-context-menu-item-container ' + isEnabled} onClick={action}>
        <div className='component-context-menu-item text-app-context-menu-item'>
          <i className='material-icons'>{icon}</i>
          <span> {text}</span>
        </div>
      </div>
    );
  }
}

SchematicsControls.displayName = 'SchematicsControls';
SchematicsControls.propTypes = {
  onUndoClick:                    React.PropTypes.func.isRequired,
  onRedoClick:                    React.PropTypes.func.isRequired,
  onIsClosable:                   React.PropTypes.func.isRequired,
  onZoomInClick:                  React.PropTypes.func.isRequired,
  onZoomOutClick:                 React.PropTypes.func.isRequired,
  isUndoAvailable:                React.PropTypes.bool.isRequired,
  isRedoAvailable:                React.PropTypes.bool.isRequired,
  onResetZoomClick:               React.PropTypes.func.isRequired,
  onClearCircuitClick:            React.PropTypes.func.isRequired,
  isSchematicsSettingsMenuOpen:   React.PropTypes.bool.isRequired,
  onToggleSchematicsSettingsClick: React.PropTypes.func.isRequired,
}

export default SchematicsControls;
