import React from 'react';

const IconTrash = ()=>{
  return(
    <svg className='icon icon-trash' viewBox="0 0 15 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="final-assets" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Card-trash" transform="translate(-116.000000, -114.000000)" fill="#FFFFFF">
                <path d="M117,131.714286 C117,132.977142 117.969583,134 119.166667,134 L127.833333,134 C129.030417,134 130,132.977142 130,131.714286 L130,118 L117,118 L117,131.714286 L117,131.714286 Z M131,115 L127.25,115 L126.178571,114 L120.821429,114 L119.75,115 L116,115 L116,117 L131,117 L131,115 L131,115 Z" id="Icon_trash"></path>
            </g>
        </g>
    </svg>
  )
}

export default IconTrash;
