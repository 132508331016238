import * as types from './constants';

const initialState = {
  isEditPartModalOpen:false,
  componentRequests: [],
  selectedId: null,
  selectedComponentRequest: {},
};

export default function(state = initialState, action){
  switch(action.type){
    case types.LOAD_COMPONENT_REQUEST_SUCCESS:
      return({
        ...state,
        selectedComponentRequest: action.selectedComponentRequest,
      })
    case types.LOAD_COMPONENT_REQUESTS_SUCCESS:
      return({
        ...state,
        selectedComponentRequest: {},
        componentRequests: action.componentRequests,
      });
    case types.CLOSE_COMPONENT_EDITOR_MODAL:
      return({
        ...state,
        isEditPartModalOpen: false
      });
      case types.OPEN_COMPONENT_EDITOR_MODAL:
      return({
        ...state,
        isEditPartModalOpen: true,
        eventSrc: action.openModalFrom
      });
    default:
      return({
        ...state,
      });
  }
}
