import React from 'react';
import { connect } from 'react-redux';
import MaterialIcon from '../../app/components/material_icon';
import Modal from '../../app/components/modal';
import Btn from '../../app/components/form_controls/btn';

// Actions
import thumbDownModalClose from '../actions/thumb_down_modal_close';
//import sendThumbDown from '../actions/sendThumbDown';

class ThumbDownModal extends React.Component{
  constructor(props) {
    super(props);

    this._handleSubmit = this._handleSubmit.bind(this);
    this._onClosed = this._onClosed.bind(this);

    this.isClosing = false;

    this.state = {excuse: ''};
  }

  _handleSubmit(e) {
    e.preventDefault();

    this.isClosing = true;
    
    analyticsSimple('Rating Report', 'Rating', 'Rating Report', 
                    `${this.props.tabName.toLowerCase()}: ${this.state.excuse === '' ? 
                                                            'None' : this.state.excuse}`, true);
    
    this.forceUpdate();
  }

  _onClosed() {
    this.isClosing = false;
    this.setState({excuse: ''});
    this.props.onClosed();
  }

  render(){
    return(
      <Modal modalClass="thumb-down"
             isOpen={this.props.isOpen && !this.isClosing}
             onClose={this._onClosed}
             fade={true}>
        <div className="thumb-down-modal modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <button id="modal-close" type="button" className="close"
                      data-dismiss="modal" aria-label="Close">
                <MaterialIcon name="close" />
              </button>
            </div>
            <div>
              <div className="modal-body">
                <form onSubmit={this._handleSubmit}>
                  <div className="text-app-header-2-bold">
                    Please tell us what went wrong
                  </div>
                  <textarea rows='9' className="text-app-body-2" value={this.state.excuse}
                            placeholder='Write your comments here.'
                            onChange={(e)=> this.setState({excuse: e.target.value || ''})}/>
                  <button type='submit'>
                    <Btn label="SUBMIT" type="primary" isStretch={true} className='no-margin'/>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

ThumbDownModal.displayName = 'ThumbDownModal';
ThumbDownModal.propTypes = {
  isOpen: React.PropTypes.bool.isRequired,
  onClosed: React.PropTypes.func,
  tabName: React.PropTypes.string,
}

const mapStateToProps = (state)=> {
  return{
    isOpen: state.requests.thumbDownModalIsOpen,
    tabName: state.requests.activeTab,
  }
}

const mapDispatchToProps = {
  onClosed: thumbDownModalClose,
}

export default connect(mapStateToProps,mapDispatchToProps)(ThumbDownModal);
