import React, { PropTypes } from 'react'
import ComponentPeripheral from './component_peripheral'

const ComponentPeripherals = ({ componentperipherals, onAddPeripheral, onRemovePeripheral, updatePeripheral }) => (
<div className="well">
  <h3>Component Compatibility to Arduino Uno</h3>
  <p></p>
  <p>
    Please list any peripheral component required to wire your component to
    Arduino Uno, like: resistor, capacitor, transistor, LLC, etc...
  </p>
  <table className="table" style={{'margin-bottom': 0}}>
    <tbody id="peripheralTable">
      <tr>
        <th>
          Type
        </th>
        <th>
          Value
        </th>
        <th>
          Wire To Pin Name
        </th>
      </tr>
      {componentperipherals.map((peripheral, i) =>
        <ComponentPeripheral
          key={i}
          onRemovePeripheral={()=>onRemovePeripheral(i)}
          updatePeripheral={(updatedPeripheral) => {updatePeripheral(i, updatedPeripheral)}}
          peripheral={peripheral}
        />
      )}
    </tbody>
  </table>
  <div className="btn-group">
    <a onClick={onAddPeripheral} className="btn btn-default">Add Peripheral</a>
  </div>
</div>
)

ComponentPeripherals.propTypes = {
  componentperipherals: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired).isRequired,
  onAddPeripheral: PropTypes.func.isRequired,
  onRemovePeripheral: PropTypes.func.isRequired,
  updatePeripheral: PropTypes.func.isRequired,
}

export default ComponentPeripherals
