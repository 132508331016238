import React from 'react';
import { IconHackster, IconTwitter, IconFacebook, IconYoutube, IconIns,IconGithub } from './icons';
import { analyticsLinkClick } from '../../utils';

// Youtube, Facebook, Instructables...
const SocialLinks = () =>{
  return(
    <nav>
      <ul className="social-links">
        <li>
          <a className='icon-link' href="https://github.com/circuito-io" target="_blank" rel="nofollow"
              onClick={()=>{analyticsLinkClick("github")}}>
            <IconGithub />
            <span className='social-links-caption text-app-hamburger'>
              GitHub
            </span>
          </a>
        </li>
        <li>
          <a className='icon-link' href="https://www.hackster.io/circuito-io" target="_blank" rel="nofollow"
              onClick={()=>{analyticsLinkClick("hackster")}}>
            <IconHackster />
            <span className='social-links-caption text-app-hamburger'>
              Hackster.io
            </span>
          </a>
        </li>
        <li>
          <a className='icon-link' href="https://twitter.com/circuitoio" target="_blank" rel="nofollow"
              onClick={()=>{analyticsLinkClick("twitter")}}>
            <IconTwitter />
            <span className='social-links-caption text-app-hamburger'>
              Twitter
            </span>
          </a>
        </li>
        <li>
          <a className='icon-link' href="https://www.facebook.com/circuito.io" target="_blank" rel="nofollow"
              onClick={()=>{analyticsLinkClick("facebook")}}>
            <IconFacebook />
            <span className='social-links-caption text-app-hamburger'>
              Facebook
            </span>
          </a>
        </li>
        <li>
          <a className='icon-link' href="https://www.youtube.com/c/Circuitoio" target="_blank" rel="nofollow"
              onClick={()=>{analyticsLinkClick("youtube")}}>
            <IconYoutube />
            <span className='social-links-caption text-app-hamburger'>
              Youtube
            </span>
          </a>
        </li>
        <li>
          <a className='icon-link' href="http://www.instructables.com/member/Circuito+io/" target="_blank" rel="nofollow"
              onClick={()=>{analyticsLinkClick("instructables")}}>
            <IconIns />
            <span className='social-links-caption text-app-hamburger'>
              Instructables
            </span>
          </a>
        </li>
      </ul>
    </nav>
  )
};

SocialLinks.displayName = 'SocialLinks';

export default SocialLinks;
