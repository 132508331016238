import 'babel-polyfill';
import 'document-register-element' // ES2015 //polyfill for document.registerElement()
import jQuery from 'jquery';
import analytics from './segment_client'; // Analytics are intialized before any other code is run
import React from 'react';
import HTML5DnDBackend from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Router, Route, IndexRoute, browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux'
import configureStore from './configure_store';
import ComponentForm from './component_requests/containers/component_form';
import App from './app/components/app';
import MainAppPage from './requests/containers/main_app_page';
import StyleSheetApp from './app/containers/stylesheet_app';
import Homepage from './app/containers/homepage';
import ListComponentRequestsPage from './component_requests/containers/list_component_requests_page';
import StaticPage from './app/containers/static_page';
// import Webix from './app/containers/webix';

// Set jquery as global, this is needed for bootstrap
window.jQuery = window.$ = jQuery;

// Init bootstrap
// TODO: This is (hopefully) the only place on the client where we have to use CommonJS module loading
// bootstrap-sass needs jQuery as a global variable to run, problem is, ES6 modules are all imported
// BEFORE any other code is ran in the module (see this comment and answer http://www.2ality.com/2014/09/es6-modules-final.html#comment-1625198742)
// there is a spec that didn't make it to ES2015 that allows loading modules programatically using System.import
// which in turn returns a promise and enables flow control. There a is a Babel polyfill for this but it's
// dependency (called 'when') is currently broken when bundled using browserify.
// so hepufully in the future, we there will be a sleeker solution
require('bootstrap-sass');
require('bootstrap-material-design');

// Create a redux store
let store = configureStore({});

// Init material design plugin
$.material.init();

// Get containers
let mainContainer = document.getElementById('main-app');

// Use Redux's store history
const history = syncHistoryWithStore(browserHistory, store);


/**

Routing Important disclaimer!

--------------------------------------------
All routes will point to 404 pages
unless they are both added in the react app & also
in the Express routes/index.js file.
--------------------------------------------

Routing Important disclaimer!

**/

let MainApp = () => {
  return(
    <Provider store={store}>
    <Router history={history} >
        <Route path='/' component={Homepage}/>
        <Route path='app' component={App}>
          <IndexRoute component={MainAppPage} />
        </Route>
        <Route path='stylesheet' component={StyleSheetApp}/>
        <Route path='component_requests' component={App}>
          <IndexRoute component={ListComponentRequestsPage} />
        </Route>
        <Route path='component_request(/:componentRequestId)' component={App}>
          <IndexRoute component={ComponentForm} />
        </Route>
        <Route path='about' component={App}>
          <IndexRoute component={StaticPage} staticPageName='about' />
        </Route>
        <Route path='faq' component={App}>
          <IndexRoute component={StaticPage} staticPageName='faq' />
        </Route>
        <Route path='terms' component={App}>
          <IndexRoute component={StaticPage} staticPageName='terms' />
        </Route>
        <Route path='privacy' component={App}>
          <IndexRoute component={StaticPage} staticPageName='privacy' />
        </Route>
        <Route path='partners' component={App}>
          <IndexRoute component={StaticPage} staticPageName='partners' />
        </Route>
        <Route path='partners/_mouser' component={App}>
          <IndexRoute component={StaticPage} staticPageName='partners/_mouser' />
        </Route>
        <Route path='partners/_mouserelec' component={App}>
          <IndexRoute component={StaticPage} staticPageName='partners/_mouserelec' />
        </Route>
        <Route path='partners/_mouserinc' component={App}>
          <IndexRoute component={StaticPage} staticPageName='partners/_mouserinc' />
        </Route>
        <Route path='partners/-arrow' component={App}>
          <IndexRoute component={StaticPage} staticPageName='partners/-arrow' />
        </Route>
        <Route path='partners/-arrowelec' component={App}>
          <IndexRoute component={StaticPage} staticPageName='partners/-arrowelec' />
        </Route>
        <Route path='partners/-arrowinc' component={App}>
          <IndexRoute component={StaticPage} staticPageName='partners/-arrowinc' />
        </Route>
        <Route path='partners/demo' component={App}>
          <IndexRoute component={StaticPage} staticPageName='partners/demo' />
        </Route>
        <Route path='*' exact={true} component={App}>
          <IndexRoute component={StaticPage} staticPageName='404' />
        </Route>
      </Router>
    </Provider>
  );
}

// Wrap main app inside a drag & drop context so that the whole app can use it
MainApp = DragDropContext(HTML5DnDBackend)(MainApp);

// Inital react render
render(
  <MainApp />,
  mainContainer
);

$(()=>{
  // Hack function for marketing
  window.generateLink = (from = null)=>{
    let selectedComponentsIds = from ? from.split(',') : store.getState().requests.selectedComponentsIds;
    browserHistory.push({
      pathname: '/app?components=',
      search: selectedComponentsIds.join(',')
    });
    console.log('%cLink generated in url bar', 'color: green');
  };
});
