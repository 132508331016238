import React from 'react';
import {Button} from '.';

const ButtonFlat = ({className, ...buttonProps}) => {
  return(
  	<Button {...buttonProps} className={"btn-flat " + className} />
  );
};

ButtonFlat.displayName = "ButtonFlat";
ButtonFlat.propTypes = {
  className: React.PropTypes.string,
}

ButtonFlat.defaultProps = {
  className: 'default',
}

export default ButtonFlat;
