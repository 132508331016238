import React from 'react';

const IconYoutube = ()=>{
  return(
    <svg className='icon icon-youtube' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path d="M24,4H8A4,4,0,0,0,4,8V24a4,4,0,0,0,4,4H24a4,4,0,0,0,4-4V8A4,4,0,0,0,24,4ZM12.7,20.72V11.78l8.6,4.49Z"/>
    </svg>
  )
}

export default IconYoutube;
