import React from 'react';
import { isEmpty } from 'lodash';
import ComponentPins from '../components/component_pins';
import ComponentPeripherals from '../components/component_peripherals';
import { PopoverButton } from '../../app/components/form_controls'
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Input, TextAreaInput } from '../../app/components/form_controls';
import saveComponentRequest from '../actions/save_component_request';
import loadComponentRequest from '../actions/load_component_request';
import { isEmailValid } from '../../utils';
import Loader from '../../app/components/loader';
import CheckBox from '../../app/components/form_controls/check_box';

// TODO: This component still has it's own state.. We should move it to redux
class ComponentForm extends React.Component{

  constructor(props){
    super(props);
    let defaults = {
      name: '',
      desc: '',
      link: '',
      email: '',
      displayName: '',
      pins: [],
      peripherals: [],
      terms: false,
      ino: '',
      libraries: '',
      image: '',
      fritzingURL: '',
      changeset: {}
    }

    this.state = defaults;
  }

  componentWillReceiveProps(nextProps){
    // We can check if an id is preset so we know if a new component was loaded
    if(nextProps.componentRequest._id !== this.props.componentRequest._id){
      this.setState(nextProps.componentRequest);
    } 
  }

  componentDidMount(){
    // Check if we need to load our component
    if(this.props.componentRequestId){
      this.isLoading = true;
      this.props.onLoad(this.props.componentRequestId);
    }
  }

  _handleGenericInputChange(key, val){
    this.setState({
      [key]: val,
      changeset: Object.assign(this.state.changeset, { [key]: val })
    });
  }

  _handleSubmit(){
    let data = {
      email: this.state.email,
      displayName: this.state.displayName,
      componentRequest: {
        name: this.state.name,
        desc: this.state.desc,
        link: this.state.link,
        pins: this.state.pins,
        peripherals: this.state.peripherals,
        ino: this.state.ino,
        libraries: this.state.libraries,
        contributors: this.state.contributors,
        image: this.state.image,
        fritzingURL: this.state.fritzingURL,
      },
      changeset: this.state.changeset
    }

    if(this.state._id){
      data._id = this.state._id;
    }

    this.props.onSubmit(data);
  }

  _handleAddPin(){
    this.state.pins.push({name: "",
                          type: "PWM",
                          voltage: "5v"});
    this.setState({
      pins: this.state.pins,
      changeset: Object.assign(this.state.changeset, { pins: this.state.pins })
    });
  }

  _handleRemovePin(index){
    this.state.pins.splice(index, 1);
    this.setState({
      pins: this.state.pins,
      changeset: Object.assign(this.state.changeset, { pins: this.state.pins })
    });
  }

  _handleUpdatePin(index, data){
    let newPins = this.state.pins
    newPins[index] = data
    this.setState({
      pins: newPins,
      changeset: Object.assign(this.state.changeset, { pins: newPins })
    })
  }

  _handleAddPeripheral(){
    this.state.peripherals.push({type: "Resistor",
                                  value: "",
                                  pin: ""})
    this.setState({
      peripherals: this.state.peripherals,
      changeset: Object.assign(this.state.changeset, { peripherals: this.state.peripherals })
    })
  }

  _handleRemovePeripheral(index){
    this.state.peripherals.splice(index, 1)
    this.setState({
      peripherals: this.state.peripherals,
      changeset: Object.assign(this.state.changeset, { peripherals: this.state.peripherals })
    })
  }

  _handleUpdatePeripheral(index, data){
    let newPeripherals = this.state.peripherals
    newPeripherals[index] = data
    this.setState({
      peripherals: newPeripherals,
      changeset: Object.assign(this.state.changeset, { peripherals: newPeripherals })
    })
  }

  _isValid(){
    return(isEmailValid(this.state.email) && this.state.name && this.state.terms)
  }

  render(){
    // Check if we are still loading
    if(this.props.componentRequestId && isEmpty(this.props.componentRequest)){
      return(<Loader />);
    }
    return(
      <div className='static-page '>
        <div className='container'>  
          <h1>Component Editor</h1>
          <h3>Create a new component for the circuito.io community</h3>
          <p style={{color: 'red'}}>
            * all fields marked with an asterisk are Mandatory.
          </p>
        </div>
        <div className="container">
          <form>
            <div className="well">
              <h2>Component Details</h2>
              <h3>
                The basic details that uniquely defined the component you want to work
                on.
              </h3>
              <div className="form-group">
                <Input onChange={(val) => this._handleGenericInputChange('name', val)} 
                        className="form-control" 
                        id="comp-name" 
                        label="Component name*"
                        type="text"
                        value={this.state.name}/>
              </div>
              <div className="form-group">
                <Input type="text" onChange={(val) => this._handleGenericInputChange('link', val)} 
                className="form-control"
                id="comp-spec"
                label="Component Link on www.digikey.com"
                value={this.state.link}/>
              </div>
              <div className="form-group">
                <Input type="text" onChange={(val) => this._handleGenericInputChange('image', val)} 
                className="form-control" 
                id="comp-shop" 
                label="Component Image URL"
                value={this.state.image}
                />
              </div>
              <div className="form-group">
                <Input type="text" onChange={(val) => this._handleGenericInputChange('fritzingURL', val)} 
                className="form-control" 
                id="comp-fritzing" 
                label="Component Fritzing URL"
                value={this.state.fritzingURL}
                />
              </div>
            </div>
            <ComponentPins componentpins={this.state.pins} 
              onAddPin={()=>this._handleAddPin()}
              onRemovePin={(index)=>this._handleRemovePin(index)}
              updatePin={(index, data)=>this._handleUpdatePin(index, data)}/>
            <ComponentPeripherals componentperipherals={this.state.peripherals} 
              onAddPeripheral={()=>this._handleAddPeripheral()}
              onRemovePeripheral={(index)=>this._handleRemovePeripheral(index)}
              updatePeripheral={(index, data)=>this._handleUpdatePeripheral(index, data)}/>
            <div className="well">
              <h2>
                Component Code
              </h2>
              <h3>
                Please add sample code to operate the component. &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp;
              </h3>
              <div className="form-group">
                <div className="form-group">
                  <div className="form-group">

                    <div className="form-group">
                      <TextAreaInput placeholder="Paste a firmware.ino with sample code to operate the component" 
                             type="text" onChange={(val) => this._handleGenericInputChange('ino', val)} 
                             className="form-control" label="Ino code" value={this.state.ino}/>
                    </div>

                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group">
                    <TextAreaInput value={this.state.libraries} placeholder="Paste code libraries you used in your sample" 
                           type="text" onChange={(val) => this._handleGenericInputChange('libraries', val)} 
                           className="form-control" label="Other libraries"/>
                  </div>

                </div>
              </div>
            </div>
            <div className="well">
              <h2>
                A little bit about you... and about us.
              </h2>
              <div className="form-group">

                <Input label="Your email address* (we'll update you about your contribution)" type="email" value={this.state.email}
                  className="form-control" 
                  id="email-address"
                  onChange={(val) => this._handleGenericInputChange('email', val)} />
                <Input label="Display name*" type="text" value={this.state.displayName}
                  className="form-control"
                  id="display-name" 
                  onChange={(val) => this._handleGenericInputChange('displayName', val)} />
                <br/>
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h2 className="panel-title">
                      Terms &amp; Conditions
                    </h2>
                  </div>
                  <div className="panel-body">
                    The content of the circuito.io hub is licensed under the Creative Commons
                    Attribution-ShareAlike 4.0 International Public License. If you contribute
                    anything to this effort, you thereby license it to the public under CC
                    BY-SA. If you want to import anything that you have found elsewhere or
                    that you have co-authored with others, you must first assert that it is
                    compatible with CC BY-SA as well.
                  </div>
                </div>
                <CheckBox className='' isChecked={this.state.terms}
                  onChange={(val) => this._handleGenericInputChange('terms', val)}>
                  {"&nbsp;by clicking the Contribute button below you accept circuito.io contribution Terms &amp; Conditions."}
                </CheckBox>
                <div>
                  <br />
                </div>
              </div>
            </div>
            <div className="well">
              <h2>Final review</h2>
              <p>Once you are ready to submit the new component for our review, click here!</p>
              <div className='component-request-form-controls'>
                <PopoverButton 
                  type="submit" 
                  className="btn btn-primary" 
                  id="btn-submit" 
                  onClick={this._handleSubmit.bind(this)}
                  isValid={this._isValid()}
                  label="Agree to Terms &amp; Contribute"/>
                <Link className='btn btn-raised btn-default' to='/component_requests'>
                  Cancel
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

ComponentForm.displayName = "ComponentForm";
ComponentForm.propTypes = {
  isSubmitted: React.PropTypes.bool,
  onSubmit: React.PropTypes.func,
  onLoad: React.PropTypes.func,
  componentRequestId: React.PropTypes.string,
  componentRequest: React.PropTypes.object,
}

const mapStateToProps = ({componentRequests}, {params}) => {
  return({
    componentRequestId: params.componentRequestId,
    componentRequest: componentRequests.selectedComponentRequest,
  })
}

const mapDispatchToProps = {
  onLoad: loadComponentRequest,
  onSubmit: saveComponentRequest,
}

export default connect(mapStateToProps,mapDispatchToProps)(ComponentForm);
