import React from 'react';

const IconAddCircle = ()=>{
  return(
    <svg className='icon icon-add-circle' viewBox="0 0 64 64" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Add-input-" transform="translate(-38.000000, -57.000000)">
                <g id="addInput" transform="translate(38.000000, 57.000000)">
                    <circle id="Oval-3" fill="#FDB72F" cx="32" cy="32" r="32"></circle>
                    <path d="M36,29 L36,18 L29,18 L29,29 L18,29 L18,36 L29,36 L29,47 L36,47 L36,36 L47,36 L47,29 L36,29 Z" id="Combined-Shape" fill="#FFFFFF"></path>
                </g>
            </g>
        </g>
    </svg>
  )
}

export default IconAddCircle;
