import * as types from '../constants';

export default function(){
  return (dispatch, getStateFunc) => {
    if (getStateFunc().requests.isComponentContextMenuClosable
    	|| getStateFunc().requests.isSchematicsSettingsMenuClosable) {
      dispatch({type: types.DEFAULT_CLICK})
    }
  }
}
