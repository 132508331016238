import * as types from '../constants';
import { getComponentRequest } from '../../api';

export default function(selectedId){
  // Fetch all components
  return (dispatch) => {
    dispatch({type: types.LOAD_COMPONENT_REQUEST_REQUEST})
    return getComponentRequest(selectedId)
      .then((selectedComponentRequest)=>{
        dispatch({type: types.LOAD_COMPONENT_REQUEST_SUCCESS, selectedComponentRequest})
      })
      .catch((error) => {
        dispatch({type: types.LOAD_COMPONENT_REQUEST_FAILURE, error});
      });
  }
}
