import React from 'react';

const IconTwitter = ()=>{
	return(
		<svg className="icon icon-twitter" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
			<title>Circuito Assets</title>
			<rect id="_Path_" data-name="&lt;Path&gt;" className="cls-1" x="4" y="4" width="24" height="24" rx="4" ry="4"/>
			<path className="cls-2" d="M13.41,23.06H12.25c-0.34,0-.67-0.06-1-0.11a9.75,9.75,0,0,1-2.62-.78c-0.43-.2-0.85-0.44-1.27-0.65V21.46A7.27,7.27,0,0,0,12.56,20a3.55,3.55,0,0,1-2.07-.72A3.49,3.49,0,0,1,9.25,17.5a3.55,3.55,0,0,0,1.57-.06,3.58,3.58,0,0,1-2-1.28A3.48,3.48,0,0,1,8,13.9a3.39,3.39,0,0,0,1.54.42,3.61,3.61,0,0,1-1.43-2.16,3.51,3.51,0,0,1,.4-2.57,10.17,10.17,0,0,0,3.28,2.64,10,10,0,0,0,4.05,1.08,4.16,4.16,0,0,1,0-1.39,3.58,3.58,0,0,1,3.33-3,3.52,3.52,0,0,1,2.68,1,0.25,0.25,0,0,0,.27.07A7.3,7.3,0,0,0,24,9.3l0.18-.09a3.8,3.8,0,0,1-1.47,1.94,8,8,0,0,0,1-.19c0.32-.09.63-0.21,1-0.32l-0.07.11a6.69,6.69,0,0,1-1.51,1.57,0.37,0.37,0,0,0-.16.35,9.36,9.36,0,0,1-.25,2.47,10.32,10.32,0,0,1-3.26,5.51A9.47,9.47,0,0,1,14.24,23Z"/>
		</svg>
	);
}

export default IconTwitter;