import React from 'react';

const Overlay = ({
  className,
  isVisible,
  isClickable,
  children,
  onClick,
}) => {
  return(
    <div className={(isVisible ? `overlay ${className}` : `overlay overlay-closed ${className}`)
                    + (isClickable ? 'overlay-clickable' : '')}
         onClick={onClick}>
      <div className='overlay-content'>
        {children}
      </div>
    </div>
  )
}

Overlay.propTypes = {
  isVisible: React.PropTypes.bool.isRequired,
  isClickable: React.PropTypes.bool,
  className: React.PropTypes.string,
  children: React.PropTypes.node,
  onClick: React.PropTypes.func,
}

Overlay.defaultProps = {
  className: '',
  isClickable: false,
}

export default Overlay;
