export const LOAD_COMPONENT_REQUESTS_REQUEST = 'LOAD_COMPONENT_REQUESTS_REQUEST';
export const LOAD_COMPONENT_REQUESTS_SUCCESS = 'LOAD_COMPONENT_REQUESTS_SUCCESS';
export const LOAD_COMPONENT_REQUESTS_FAILURE = 'LOAD_COMPONENT_REQUESTS_FAILURE';
export const LOAD_COMPONENT_REQUEST_REQUEST = 'LOAD_COMPONENT_REQUEST_REQUEST';
export const LOAD_COMPONENT_REQUEST_SUCCESS = 'LOAD_COMPONENT_REQUEST_SUCCESS';
export const LOAD_COMPONENT_REQUEST_FAILURE = 'LOAD_COMPONENT_REQUEST_FAILURE';
export const SAVE_COMPONENT_REQUEST_REQUEST = 'SAVE_COMPONENT_REQUEST_REQUEST';
export const SAVE_COMPONENT_REQUEST_SUCCESS = 'SAVE_COMPONENT_REQUEST_SUCCESS';
export const SAVE_COMPONENT_REQUEST_FAILURE = 'SAVE_COMPONENT_REQUEST_FAILURE';
export const EDIT_COMPONENT_REQUEST = 'EDIT_COMPONENT_REQUEST';
export const OPEN_COMPONENT_EDITOR_MODAL = 'OPEN_COMPONENT_EDITOR_MODAL';
export const CLOSE_COMPONENT_EDITOR_MODAL = 'CLOSE_COMPONENT_EDITOR_MODAL';