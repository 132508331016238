import React, { PropTypes } from 'react'
import ComponentPin from './component_pin'

const ComponentPins = ({ componentpins, onAddPin, onRemovePin, updatePin }) => (
	<div className="well">
	  <h3>
	    Component Pins
	  </h3>
	  <p>
	  </p>
	  <p>
	    Please define all the connections this component provides and requires.
	    For example: Signal -&gt; PWM -&gt; 5V
	  </p>
	  <table className="table">
	    <tbody id="pinTable">
	      <tr>
	        <th>
	          Name
	        </th>
	        <th>
	          Type
	        </th>
	        <th>
	          Voltage
	        </th>
	      </tr>
	      {componentpins.map((componentpin, i) =>
        		<ComponentPin
        		key={i}
      	  		onRemovePin={()=>onRemovePin(i)}
      	  		updatePin={(updatedPin) => {updatePin(i, updatedPin)}}
        		pin={componentpin}
      		/>)}
  		</tbody>
      </table>
      <div className="btn-group">
        <a onClick={onAddPin} className="btn btn-default">Add Pin</a>
      </div>
    </div>
)

ComponentPins.propTypes = {
  componentpins: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    voltage: PropTypes.string.isRequired,
  }).isRequired).isRequired,
  onAddPin: PropTypes.func.isRequired,
  onRemovePin: PropTypes.func.isRequired,
  updatePin: PropTypes.func.isRequired,
}

export default ComponentPins