import React from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import {ON_MOBILE_MQ, ON_DESKTOP_MQ} from '../../config.js'
import Btn from '../../app/components/form_controls/btn';
import BasicTab from './basic_tab';
import { getSelectedComponents } from '../selectors';

class ProjectGuideTab extends React.Component{
  _onProjectGiudeLinkClick(name) {
    analyticsSimple('BoM Click', 'BoM', 'Click', 'BoM Click ' + name);
  }

  _getBtn(projectGuideLink,id){
    return(
      <a rel="nofollow" href={`${document.location.protocol}//${document.location.host}/static/reply/index.html?solutionId=${id}&solutionPath=${projectGuideLink}`} target="_blank">
          <Btn type="primary" label='go to project guide' onClick={()=>this._onProjectGiudeLinkClick('Project Guide')}/>
      </a>
    )
  }

  render(){
    // If there's a project link to show
    if (this.props.validationData
        && this.props.validationData.solutionId
        && this.props.validationData.solutionPath
        && this.props.selectedComponents.length > 2) {
      let projectGuideLink = this.props.validationData.solutionPath;
      let id = this.props.validationData.solutionId;

      return (
        <BasicTab
          image={<img src='static/images/walkthough_step_5_mobile.svg'/>}
          title="Ready to build your circuit?"
          text="Select the combination of components you need for your project.
                Add or remove components to the circuit using the dynamic builder,
                and use the test code to verify your connections."
          button={this._getBtn(projectGuideLink,id)}
        />
      );
    }
    else {
      return (
        <BasicTab title="You don't have a project to build yet"
                  text="Add components to your schematic in the Design step to start your project."
        />);
    }
  }
}

ProjectGuideTab.displayName = 'ProjectGuideTab';
ProjectGuideTab.propTypes = {
  validationData: React.PropTypes.object,
  selectedComponents: React.PropTypes.array,
}

ProjectGuideTab.defaultProps = {
  selectedComponents: [],
}

const mapStateToProps = (state) => {
  return({
    validationData: state.app.validationData,
    selectedComponents: getSelectedComponents(state),
  });
}

export default connect(mapStateToProps, null)(ProjectGuideTab);
