import Cookies from 'universal-cookie';
import { _ } from 'lodash';
import React from 'react';
import {connect} from 'react-redux';
import MediaQuery from 'react-responsive';
import {ON_MOBILE_MQ, ON_DESKTOP_MQ} from '../../config';
import {getInitialComponentIds} from '../../utils';
import ComponentInfoModal from './component_info_modal';
import NewComponentModal from './new_component_modal';
import CheckoutSecondaryInfoModal from './checkout_secondary_info_modal';
import WalkthroughModal from './walkthrough_modal';
import NotificationModal from '../../app/containers/notification_modal';
import ThumbDownModal from './thumb_down_modal';
import EditPartModal from '../../component_requests/components/edit_part_modal';
import DesignTab from './design_tab';
import CodeTab from './code_tab';
import MessForm from './mess_form';
import ProjectGuideTab from './project_guide_tab';
import Tabs from './tabs';

// Action creators
import validateComponents from '../actions/helpers/validate_components';
import loadComponents from '../actions/load_components';
import defaultClick from '../actions/default_click';
import tabClicked from '../actions/tab_change';
import { withRouter } from 'react-router'


// Utils
import { analyticsTrackPage, getParameterByName, getOldComponentsFormat } from '../../utils';

// Main app component
class MainAppPage extends React.Component {
  constructor() {
    super();

    this._renderActiveTab = this._renderActiveTab.bind(this);
    this._validate = this._validate.bind(this);
  }

  componentDidMount(){
    analyticsTrackPage(window.location.pathname);

    this.unlisten = this.props.history.listen((location, action) => {
      // If we hit back or forward, we will revalidate the components.
      // POP actually refers to both back and forward for some reason
      // AAANNNDD This always happens right when the app starts. this is our initial validation
      if (action.location.action === 'POP') {
        this._validate();
      }
    });

    if(Object.keys(this.props.componentsById).length === 0){
      this.props.onLoad();
    }

    if (this.props.shouldRefreshCircuit) {
      this._validate();
    }
  }

  componentWillUnmount() {
    // Unlistening to back/forward browser presses
    this.unlisten();
  }

  // componentWillUpdate(nextProps) {
  //     // Switched brtween pros, revalidate using saved components for pro/not-pro
  //     let comps = getInitialComponentIds();
  //     let cookieCompsKey = 'components';
  //     let cookieComps = new Cookies().get(cookieCompsKey);
  //     if (typeof(cookieComps) !== 'undefined' && cookieComps !== null) {
  //       comps = cookieComps.split(',');
  //     }
  //     // TODO deal with all things that we need to rollback if the move to pro was unsecce
  //     nextProps.validate(comps);
  //     analyticsTrackPage('/app');
  // }

  _validate() {
    if(!_.isEmpty( getParameterByName('u')) && this.props.validationData === null){
      this.props.validate(getInitialComponentIds());
      return false;
    }
    let magicLinks = getParameterByName("components");
    if (magicLinks) {
      this.props.validate(magicLinks.split(','));
    }
    else {
      let oldComponentsFormat = getOldComponentsFormat();
      if (oldComponentsFormat) {
        this.props.validate(oldComponentsFormat);
      }
      else {
        let comps = getInitialComponentIds();
        let cookieCompsKey = 'components';
        let cookieComps = new Cookies().get(cookieCompsKey);
        if (typeof(cookieComps) !== 'undefined' && cookieComps !== null) {
          comps = cookieComps.split(',');
        }

        this.props.validate(comps);
      }
    }
  }

  _renderActiveTab() {
    switch (this.props.activeTab) {
      case 'DESIGN':
        return (<DesignTab/>);
      case 'CODE':
        return (<CodeTab/>);
      case 'PROJECT GUIDE':
        return (<ProjectGuideTab/>);
      default:
        console.log('Unknown tab: ' + this.props.activeTab);
        return null;
    }
  }

  render() {
    return(
      <div className={`desktop-content`} onClick={this.props.onDefaultClick} >
        <MediaQuery query={ON_DESKTOP_MQ}>
          <div className='desktop-content-header'>
            <Tabs deviceType='desktop'/>
          </div>
        </MediaQuery>
        {this._renderActiveTab()}
        <MediaQuery query={ON_MOBILE_MQ}>
          <Tabs deviceType='mobile'/>
        </MediaQuery>
        <ComponentInfoModal />
        <NewComponentModal />
        <CheckoutSecondaryInfoModal />
        <WalkthroughModal />
        <ThumbDownModal />
        <EditPartModal />
        <NotificationModal />
      </div>
    );
  }
}

MainAppPage.displayName = 'MainAppPage';
MainAppPage.propTypes = {
  activeTab: React.PropTypes.string.isRequired,
  componentsById: React.PropTypes.object,
  selectedComponentsIds: React.PropTypes.arrayOf(React.PropTypes.string),
  onLoad: React.PropTypes.func, // Runs on initial load
  onDefaultClick: React.PropTypes.func,
  isTermsAgreed: React.PropTypes.bool,
  shouldRefreshCircuit: React.PropTypes.bool,
  validationData: React.PropTypes.object,
  validate: React.PropTypes.func,
}

const mapStateToProps = (state)=>{
  return{
    activeTab: state.requests.activeTab,
    selectedComponentsIds: state.requests.selectedComponentsIds,
    componentsById: state.requests.componentsById,
    isTermsAgreed: state.app.isTermsAgreed,
    validationData: state.app.validationData,
    shouldRefreshCircuit: state.app.shouldRefreshCircuit,
  }
}

const mapDispatchToProps = {
  onLoad: loadComponents,
  validate: (comps) => (dispatch, getStateFunc) => validateComponents(dispatch, getStateFunc, comps),
  onDefaultClick: defaultClick,
  onTabClicked: tabClicked,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainAppPage));
