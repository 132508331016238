import { INITIAL_COMPONENT_IDS, INITIAL_CONTROLLER_NAME, COMPONENT_TYPES } from './config';
import Cookies from 'universal-cookie';
const _ = require('lodash');

let lastId = 0;

/**
 * <react-comment> Web Component
 *
 * Enable regular HTML and conditional IE comments in React.js.
 *
 * @usage
 *  <react-comment>Comment-text, e.g. [if lte IE 9]><script ... /><![endif]</react-comment>
 * @result
 *  <!--Comment-text, e.g. [if lte IE 9]><script ... /><![endif]-->
 */
var proto = Object.create(HTMLElement.prototype, {
 name: { get: function() { return 'React HTML Comment'; } },
 createdCallback: { value: function() {

  /**
   * Firefox fix, is="null" prevents attachedCallback
   * @link https://github.com/WebReflection/document-register-element/issues/22
   */
  this.is = '';
  this.removeAttribute('is');
 } },
 attachedCallback: { value: function() {
  this.outerHTML = '<!--' + this.textContent + '-->';
 } }
});
document.registerElement('react-comment', { prototype: proto });

export function generateId(prefix='id') {
    lastId++;
    return `${prefix}${lastId}`;
}

export function getIsParameterByNameExists(name, url) {
  return getParameterByName(name, url) !== null;
}

export function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function getInitialComponentIds() {
  console.log("INITIAL_COMPONENT_IDS:::::    " + JSON.stringify(INITIAL_COMPONENT_IDS))
  return INITIAL_COMPONENT_IDS;
}

export function getInitialControllerName() {
  return INITIAL_CONTROLLER_NAME;
}

export function toCssString(str) {
  return(str.replace(/\s+/g, '-').toLowerCase());
}

const emailValidityRegEx = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
export function isEmailValid(value){
  return(emailValidityRegEx.test(value));
}

export function componentIdsToNames(idsList, componentsByIds) {
  return idsList.map((id) => componentsByIds[id].name);
}

export function firstOrDefault(obj, def = null){
  if(Array.isArray(obj)){
    return obj[0] || def;
  } else {
    return def;
  }
}

export function analyticsLinkClick(site) {
  analytics.track('Link Click', {
    'category': 'Links',
    'action': 'Click',
    'label': site,
  });
}

export function analyticsTrackPage(page){
  let props = {
      'category': 'Links',
      'action': 'Click',
      'label': page,
  };

  if (window.location.search && window.location.search !== '') {
    props.params = window.location.search;
  }

  analytics.track(`Viewed ${page} Page`, props, {
    Intercom: { hideDefaultLauncher: false }
  });
}

export function getOldComponentsFormat() {
  let arr = [];
  let first = true;
  for (let arg of window.location.href.split('selectedComponentsIds=')) {
    if (first) {
      first = false;
      continue;
    }
    arr.push(arg.split('&')[0]);
  }

  if (arr.length == 0) {
    return null;
  }

  return arr;
}

export function isMagicLink(){
  let componentsInUrl = getQueryParamSingle('components').split(',');
  let isDefaultComponents = (componentsInUrl.length <= 2 && componentsInUrl.indexOf('512') !== -1 && componentsInUrl.indexOf('11021') !== -1);
  return (hasQueryParam(window.location.href, 'selectedComponentsIds') || hasQueryParam(window.location.href, 'selectedComponentsIds1') || !isDefaultComponents && componentsInUrl.length > 2);
}

export function getQueryParam(param) {
  let ua = window.navigator.userAgent;

  // If safari on iOS
  if (window.safari !== undefined || ((!!ua.match(/iPad/i) || !!ua.match(/iPhone/i)) && !!ua.match(/WebKit/i) && !ua.match(/CriOS/i))) {
    // Expects a raw URL
    param = param.replace(/[[]/, "\[").replace(/[]]/, "\]");
    var regexS = "[\?&]" + param + "=([^&#]*)",
        regex = new RegExp( regexS ),
        results = regex.exec(window.location.href);
    if (results === null || (results && typeof(results[1]) !== 'string' && results[1].length)) {
      return '';
      } else {
      return decodeURIComponent(results[1]).replace(/[&\/\\#,+()$~%.'":*?<>{}]/gi, ' ').split(' ');
    }
  }
  else {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.getAll(param);
  }
}

export function getQueryParamSingle(param) {
  let q = getQueryParam(param);
  if (!q || !q.length) {
    return '';
  }
  else {
    return q[0];
  }
}

export function hasQueryParam(param) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.has(param);
}

export function campaignParams() {
    var campaign_keywords = 'utm_source utm_medium utm_campaign utm_content utm_term'.split(' ')
    , kw = ''
    , params = {}
    , first_params = {};
    var index;
    for (index = 0; index < campaign_keywords.length; ++index) {
      kw = getQueryParam(campaign_keywords[index]);
      if (kw.length) {
        params[campaign_keywords[index] + ' [last touch]'] = kw;
      }
    }

    for (index = 0; index < campaign_keywords.length; ++index) {
      kw = getQueryParam(campaign_keywords[index]);
      if (kw.length) {
        first_params[campaign_keywords[index] + ' [first touch]'] = kw;
      }
    }

  mixpanel.people.set(params);
  mixpanel.people.set_once(first_params);
  mixpanel.register(params);
}


/**
 * SVG Path rounding function. Takes an input path string and outputs a path
 * string where all line-line corners have been rounded. Only supports absolute
 * commands at the moment.
 *
 * @param pathString The SVG input path
 * @param radius The amount to round the corners, either a value in the SVG
 *               coordinate space, or, if useFractionalRadius is true, a value
 *               from 0 to 1.
 * @param useFractionalRadius If true, the curve radius is expressed as a
 *               fraction of the distance between the point being curved and
 *               the previous and next points.
 * @returns A new SVG path string with the rounding
 */
export function roundPathCorners(pathString, radius, useFractionalRadius) {
  function moveTowardsLength(movingPoint, targetPoint, amount) {
    var width = (targetPoint.x - movingPoint.x);
    var height = (targetPoint.y - movingPoint.y);

    var distance = Math.sqrt(width*width + height*height);

    return moveTowardsFractional(movingPoint, targetPoint, Math.min(1, amount / distance));
  }
  function moveTowardsFractional(movingPoint, targetPoint, fraction) {
    return {
      x: movingPoint.x + (targetPoint.x - movingPoint.x)*fraction,
      y: movingPoint.y + (targetPoint.y - movingPoint.y)*fraction
    };
  }

  // Adjusts the ending position of a command
  function adjustCommand(cmd, newPoint) {
    if (cmd.length > 2) {
      cmd[cmd.length - 2] = newPoint.x;
      cmd[cmd.length - 1] = newPoint.y;
    }
  }

  // Gives an {x, y} object for a command's ending position
  function pointForCommand(cmd) {
    return {
      x: parseFloat(cmd[cmd.length - 2]),
      y: parseFloat(cmd[cmd.length - 1]),
    };
  }

  // Split apart the path, handing concatonated letters and numbers
  var pathParts = pathString
    .split(/[,\s]/)
    .reduce(function(parts, part){
      var match = part.match("([a-zA-Z])(.+)");
      if (match) {
        parts.push(match[1]);
        parts.push(match[2]);
      } else {
        parts.push(part);
      }

      return parts;
    }, []);

  // Group the commands with their arguments for easier handling
  var commands = pathParts.reduce(function(commands, part) {
    if (parseFloat(part) == part && commands.length) {
      commands[commands.length - 1].push(part);
    } else {
      commands.push([part]);
    }

    return commands;
  }, []);

  // The resulting commands, also grouped
  var resultCommands = [];

  if (commands.length > 1) {
    var startPoint = pointForCommand(commands[0]);

    // Handle the close path case with a "virtual" closing line
    var virtualCloseLine = null;
    if (commands[commands.length - 1][0] == "Z" && commands[0].length > 2) {
      virtualCloseLine = ["L", startPoint.x, startPoint.y];
      commands[commands.length - 1] = virtualCloseLine;
    }

    // We always use the first command (but it may be mutated)
    resultCommands.push(commands[0]);

    for (var cmdIndex=1; cmdIndex < commands.length; cmdIndex++) {
      var prevCmd = resultCommands[resultCommands.length - 1];

      var curCmd = commands[cmdIndex];

      // Handle closing case
      var nextCmd = (curCmd == virtualCloseLine)
        ? commands[1]
        : commands[cmdIndex + 1];

      // Nasty logic to decide if this path is a candidite.
      if (nextCmd && prevCmd && (prevCmd.length > 2) && curCmd[0] == "L" && nextCmd.length > 2 && nextCmd[0] == "L") {
        // Calc the points we're dealing with
        var prevPoint = pointForCommand(prevCmd);
        var curPoint = pointForCommand(curCmd);
        var nextPoint = pointForCommand(nextCmd);

        // The start and end of the cuve are just our point moved towards the previous and next points, respectivly
        var curveStart, curveEnd;

        if (useFractionalRadius) {
          curveStart = moveTowardsFractional(curPoint, prevCmd.origPoint || prevPoint, radius);
          curveEnd = moveTowardsFractional(curPoint, nextCmd.origPoint || nextPoint, radius);
        } else {
          curveStart = moveTowardsLength(curPoint, prevPoint, radius);
          curveEnd = moveTowardsLength(curPoint, nextPoint, radius);
        }

        // Adjust the current command and add it
        adjustCommand(curCmd, curveStart);
        curCmd.origPoint = curPoint;
        resultCommands.push(curCmd);

        // The curve control points are halfway between the start/end of the curve and
        // the original point
        var startControl = moveTowardsFractional(curveStart, curPoint, .5);
        var endControl = moveTowardsFractional(curPoint, curveEnd, .5);

        // Create the curve
        var curveCmd = ["C", startControl.x, startControl.y, endControl.x, endControl.y, curveEnd.x, curveEnd.y];
        // Save the original point for fractional calculations
        curveCmd.origPoint = curPoint;
        resultCommands.push(curveCmd);
      } else {
        // Pass through commands that don't qualify
        resultCommands.push(curCmd);
      }
    }

    // Fix up the starting point and restore the close path if the path was orignally closed
    if (virtualCloseLine) {
      var newStartPoint = pointForCommand(resultCommands[resultCommands.length-1]);
      resultCommands.push(["Z"]);
      adjustCommand(resultCommands[0], newStartPoint);
    }
  } else {
    resultCommands = commands;
  }

  return resultCommands.reduce(function(str, c){ return str + c.join(" ") + " "; }, "");
}


export function getTrackDebouncePanZoomFunc() {
  return _.debounce((name, cat, action, label) => {
    console.log(name + cat + action + label);
    analyticsSimple(name, cat, action, label, true);
  },1500);
}

export function getCorrectComponentsForURL() {
  if(!_.isEmpty( getParameterByName('u') )){
     return getInitialComponentIds();
  }
  
  let componentsQuery = getParameterByName("components");
  if (componentsQuery) {
    return componentsQuery.split(',');
  }

  let oldComponentsFormat = getOldComponentsFormat();
  if (oldComponentsFormat) {
    return oldComponentsFormat;
  }

  let comps = getInitialComponentIds();
  let cookieCompsKey = 'components';
  let cookieComps = new Cookies().get(cookieCompsKey);
  if (typeof(cookieComps) !== 'undefined' && cookieComps !== null) {
    return cookieComps.split(',');
  }

  return comps;
}

export function getCategories(component) {
  return component.category.filter(cat=> COMPONENT_TYPES.includes(cat));
}