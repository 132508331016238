/**
 * Created by roboamir on 8/08/16.
 */
import { SHOW_NOTIFICATION } from '../constants';

export const NotificationTypes = {
  NOTIFICATION_SUCCESS_TYPE: 'alert-success',
  NOTIFICATION_GENERAL_TYPE: 'alert-general',
  NOTIFICATION_DANGER_TYPE : 'alert-danger',
}

export default function(notificationType, notificationText,
                        isNotificationAutoDismissed=true, notificationDetails=null) {
  console.log(notificationDetails);
  return {
    type: SHOW_NOTIFICATION,
    notificationType,
    notificationText,
    notificationDetails,
    isNotificationAutoDismissed,
  }
}
