import React from 'react';
import MaterialIcon from '../material_icon';

const Button = (({
  label,
  onClick,
  bootstrapType,
  isEnabled,
  type,
  onMouseLeave,
  icon,
  className,
  iconClassName,
  id,
  children,
})=>{
  let iconElement = icon ? <MaterialIcon name={icon} className={iconClassName} /> : null;
  return(
    <button className={`btn btn-${bootstrapType} ${className}`} id={id} 
            type={type}
            disabled={!isEnabled}
            onMouseLeave={onMouseLeave}
            onClick={onClick}>
      <span>{label}</span>
      {iconElement}
      {children}
    </button>);
});

Button.displayName = "Button";
Button.propTypes = {
  label: React.PropTypes.string.isRequired,
  onClick: React.PropTypes.func,
  onMouseLeave: React.PropTypes.func,
  bootstrapType: React.PropTypes.oneOf(['default', 'primary', 'success', 'info', 'warning', 'danger', 'link']),
  isEnabled: React.PropTypes.bool,
  type: React.PropTypes.oneOf(['button', 'submit', 'reset']),
  icon: React.PropTypes.string,
  className: React.PropTypes.string,
  iconClassName: React.PropTypes.string,
  id: React.PropTypes.string,
  children: React.PropTypes.element,
}
Button.defaultProps = {
  type: 'button',
  isEnabled: true,
  className: '',
  iconClassName: '',
}

export default Button;
