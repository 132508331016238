import React from 'react';
import {Button} from '.';

const ButtonGhost = ({className, ...buttonProps}) => {
  return(
  	<Button {...buttonProps} className={`btn-ghost ${className}`} />
  );
};

ButtonGhost.displayName = "ButtonGhost";
ButtonGhost.propTypes = {
  className: React.PropTypes.string,
}
ButtonGhost.defaultProps = {
  className: "default",
}

export default ButtonGhost;
