/**
 * Created by roboamir on 01/08/16.
 */
///
/// Loads and shows a static page's content from the server
///
import * as types from '../constants';
import React from 'react';
import { connect } from 'react-redux';
import loadStaticPageAction from '../actions/load_static_page'
import { analyticsTrackPage } from '../../utils'

class StaticPage extends React.Component {

  componentDidMount() {
    analyticsTrackPage('/' + this.props.route.staticPageName);
    this.loadStaticPage(this.props.route.staticPageName);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.route.staticPageName !== this.props.route.staticPageName) {
      analyticsTrackPage('/' + nextProps.route.staticPageName);
      this.loadStaticPage(nextProps.route.staticPageName);
    }
  }

  loadStaticPage(pageName) {
    // Loading the components with an initial components query
    this.props.dispatch(loadStaticPageAction(pageName,
                                              types.LOAD_STATIC_PAGE_CONTENT_REQUEST,
                                                types.LOAD_STATIC_PAGE_CONTENT_FAILURE,
                                                  types.LOAD_STATIC_PAGE_CONTENT_SUCCESS));
  }

  render() {
    return (
      <div className='static-page' dangerouslySetInnerHTML={{__html: this.props.staticPageContent}} />
    )
  }
}

StaticPage.displayName = 'StaticPage';
StaticPage.propTypes = {
  dispatch: React.PropTypes.func.isRequired,
  staticPageContent: React.PropTypes.string,
};

const mapStateToProps = ({app}) => {
  return ({
    staticPageContent : app.staticPageContent
  });
};

export default connect(mapStateToProps)(StaticPage);
