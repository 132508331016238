
import * as types from '../constants';
import { getCodeFileContent } from '../../api';

export default function(componetCodeName, codeFile){
  return (dispatch, getStateFunc) => {
      return getCodeFileContent(componetCodeName, codeFile)
        .then((res)=>{
          let jsonData = res;
          dispatch({type: types.LOAD_CODE_FILE_SUCCESS, jsonData})
        })
    .catch((error) => {
      console.log(error)
    });
  }
}
