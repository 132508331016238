import React from 'react';

const IconInfoCircle = ({isTestedWiredAndVerified})=>{
    return(
        <svg className='icon icon-info-circle' xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
            <g fill="none" fillRule="evenodd" transform="translate(1 1)">
                <rect className={`icon-info-circle-status-${isTestedWiredAndVerified ? 'full' : 'not-full'}`} 
                    width="20" height="20" stroke="#FFF" strokeWidth="1" rx="10"
                />
                <path fill="#FFF" d="M8.889 15.556V7.407h2.222V15.556zM8.889 5.926V3.704h2.222v2.222z"/>
            </g>
        </svg>
    )
}

IconInfoCircle.propTypes = {
  isTestedWiredAndVerified: React.PropTypes.bool.isRequired,
}

export default IconInfoCircle;
