import React from 'react';

const RoboIcon = ({name, className})=>{
  return <i className={`material-icons ${className}`}>{name}</i>
}
RoboIcon.displayName = "RoboIcon";
RoboIcon.propTypes = {
  name: React.PropTypes.string.isRequired,
  className: React.PropTypes.string,
}

export default RoboIcon;
