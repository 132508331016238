///
/// Tries to switch an existing component by validating first
///
import * as types from '../constants';
import validateComponents from './helpers/validate_components';
import { getSelectedComponents, getComponentsById } from '../selectors';
import { SWITCHABLE_COMPONENT_TYPES } from '../../config';

/**
 * Switches a component by it's type.. Use this for critical components which cannot
 * be removed or appear more then once in a circuit
 * @param  {Object|Number} component A component object or its ID
 * @return {Function} Redux-thunk function
 */
export default function(component) {
    return (dispatch, getStateFunc) => {
        let state = getStateFunc();

        if (typeof(component) !== 'object') {
            component = getComponentsById(state)[component];
        }

        // We need to know which component should be switched, as we don't require
        // this logic from the UI, we need to find it ourselves.. A switched component
        // can be a controller, a power supply
        const intersection = SWITCHABLE_COMPONENT_TYPES.filter(element => component.category.includes(element));
        if (intersection.length == 0) {
            throw new Error(`Cannot swap component of category ${component.category}`);
        }

        analyticsSimple('Modify Component', 'Modify Component', 'Swap', 
                        component.name, true, true);

        // Filter out the compoent that is about to be swapped
        let newComponentsIds = getSelectedComponents(state).reduce((res, curr) => {
            if (component.category.some(cat => curr.category.includes(cat))) {
                // this one should be filtered out, for a matching category
                return res;
            }

            // None of the categories of component match any of the categories of curr
            res.push(curr.blockId);
            return res;
        }, []);

        newComponentsIds.push(component.blockId);

        // combine selected components with the newly requested component, and validate them
        return validateComponents(dispatch, getStateFunc,
                                    newComponentsIds, component, types.COMPONENT_SWITCHED)
        .catch((error) => {});
    }
}
