import createFetch from 'fetch-ponyfill';
import { REQUEST_SUBMIT_TIMEOUT } from './config';

const {fetch} = createFetch();
const metaToken = document.head.querySelector("meta[name='csrf-token']");
const csrfToken = (metaToken) ? metaToken.getAttribute('content') : null;
const reqOptions = {
  credentials: 'same-origin', // <-- includes cookies in the request
  headers: {
    'CSRF-Token': csrfToken // <-- is the csrf token as a header
  }
};

export const getComponents = () => {
  console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
  console.log(window.username);
  return fetch(`/components/${window.username}`, reqOptions)
  .then(_checkFetchStatus)
  .then((res) => res.status == 200 ? res.json() : res)
}

export const getHomepagePosts = () => {
  return fetch('/blog/related',reqOptions)
  .then(_checkFetchStatus)
  .then((res) => res.json())
}

export const login = (username, password) => {
  return fetch('/user/login',{
    method: 'POST',
    credentials: 'same-origin', // <-- includes cookies in the request
    headers: {
      'CSRF-Token': csrfToken, // <-- is the csrf token as a header
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({username, password})
  })
  .then(_checkFetchStatus)
}

export const getCodeFileContent = (compName, fileName, dbName = window.username) => {
  return fetch(`/code/${dbName}/${compName}/${fileName}`,reqOptions)
  .then(_checkFetchStatus)
  .then((res) => res.json())
}

export const getComponentRequests = () => {
  return fetch('/data/component_requests',reqOptions)
  .then(_checkFetchStatus)
  .then((res) => res.json())
}

export const getSVGDataByRef = (ref, dbName = window.dbName) => {
  return fetch(`/SVGData/${dbName}/${ref}`,reqOptions)
  .then(_checkFetchStatus)
  .then((res) => res.json())
}

export const checkOwnerStatus = () => {
  return _setTimeout(
    fetch(`/check_owner_status/${window.username}`,reqOptions), REQUEST_SUBMIT_TIMEOUT)
  .then((res) => {
    return _checkFetchStatus(res);
  })
}

export const generateSolution = (componentIds, userId) => {
  console.log("CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC");
  console.log(componentIds);
  return _setTimeout(
    fetch(`/circuito_request/${window.username}`,{
      method: 'POST',
      credentials: 'same-origin', // <-- includes cookies in the request
      headers: {
        'CSRF-Token': csrfToken, // <-- is the csrf token as a header
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ componentIds, userId })
    }), REQUEST_SUBMIT_TIMEOUT)
  .then((res) => {
    return _checkFetchStatus(res);
  })
  .then((res) => {
    return res.json();
  });
}

export const loadStaticPage = (path) => {
  return fetch('/static/' + path, reqOptions)
    .then(_checkFetchStatus)
    .then((res) => res.text())
}

export const saveComponentRequest = (data) => {
  return fetch('/data/component_request',{
    method: 'POST',
    credentials: 'same-origin', // <-- includes cookies in the request
    headers: {
      'CSRF-Token': csrfToken, // <-- is the csrf token as a header
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  })
  .then(_checkFetchStatus)
}

// This is for the regular form
export const submitComponentRequest = (data) => {
  return fetch('/component_request_simple',{
    method: 'POST',
    credentials: 'same-origin', // <-- includes cookies in the request
    headers: {
      'CSRF-Token': csrfToken, // <-- is the csrf token as a header
      'Accept': 'application/json',
      'Content-Type': 'multitype/form-data',
    },
    body: JSON.stringify(data)
  })
  .then(_checkFetchStatus)
}

// Privates
function _setTimeout(promise, timeout = null){
  return new Promise((resolve, reject)=>{

    // Set a timeout for the promise
    if(timeout){
      setTimeout(()=>{
        var error = new Error(`Request timeout: request exceeded ${timeout} ms`);
        error.response = {status: 503, statusText: "timeout"};
        reject(error);
      }, timeout);
    }

    // The resolve method will be called after fetch is finished,
    // After that, the timedout reject will not work
    promise.then(resolve, reject);/* TODO weird timeouts(result)=>{ // That's on resolve
      clearTimeout(timeoutId);
      resolve(result);
    }, (error)=>{ // That's on reject
      clearTimeout(timeoutId);
      reject(error);
    });*/
  })
}

function _checkFetchStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    return response.json()
    .then((resp)=> {
      var error = new Error(String(response.status));
      error.response = response;
      error.json = resp;
      throw error;
    });
  }
}
