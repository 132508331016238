import React from 'react';
import {Link} from 'react-router';
import Image from '../../app/components/image';
const _ = require('lodash');

const COMPONENT_REQUEST_STATES_MAP = {
  0: 'Development',
  1: 'In Progress',
  2: 'Live',
};

const DEFAULT_IMAGE = 'static/images/component_image_placeholder.svg';

class ListComponentRequestItem extends React.Component{

  constructor(props){
    super(props);
    let defaults = {
      showMoreContributors: false
    }

    this.state = defaults;
  }

  getImageUrl(){
    return(this.props.componentRequest.image || DEFAULT_IMAGE);
  }

  onShowConts() {
    this.setState({showMoreContributors: true})
  }

  getContributedBy(conts) {
    let namedConts = [];
    let unnamedConts = 0;

    for (var i = 0; i < conts.length; i++) {
      if (!_.isEmpty(conts[i]) && conts[i].hasOwnProperty('displayName')) {
        namedConts.push(conts[i]);
      }
      else {
        unnamedConts += 1;
      }
    }

    if (namedConts.length > 3 && !this.state.showMoreContributors) {
      return <p>
        contributed by {`${conts[0].displayName}, ${conts[1].displayName}, ${conts[2].displayName}`} and <a
          href="javascript: void(0);" onClick={this.onShowConts.bind(this)}>
             {`${conts.length - 3} more`}
          </a>
      </p>
    }
    else {
      if (namedConts.length > 0) {
        return <p>contributed by {namedConts.map((c)=> c.displayName).join(", ")} {unnamedConts > 0 ? `and ${unnamedConts} more` : ''}</p>
      }
      else {
        return <p>{unnamedConts} contributor(s)</p>
      }
    }
  }

  render(){
    return(
      <div className={`component-requests-list-item component-requests-list-item-${this.props.componentRequest.state}`}
           >
        <div className='components-requests-list-item-category'>
          {COMPONENT_REQUEST_STATES_MAP[this.props.componentRequest.state]}
        </div>
        <div className='component-requests-list-item-image'>
          <Image src={this.getImageUrl()} />
        </div>
        <div className='component-requests-list-item-info'>
          {this.props.componentRequest.name}
          <br />
          {this.getContributedBy(this.props.componentRequest.contributors)}
        </div>
      </div>
  )}
}

ListComponentRequestItem.propTypes = {
  componentRequest: React.PropTypes.object.isRequired,
}

export default ListComponentRequestItem;
