/**
 * Created by roboamir on 25/07/16.
 */
import { SIDEBAR_TOGGLE } from '../constants';

export default function(getState){
  if (!getState().app.isSidebarOpen) {
  	analyticsSimple("Menu Browse", "Menu", "Click", "Menu Browse");
  }

  return{
    type: SIDEBAR_TOGGLE
  }
}
