import React from 'react';

const CircuitoLogo = ({isLight, className})=>{
    let color = 'dark';
    if (isLight) {
        color = 'light';
    }

    return(
        <svg className={className} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 40">
            <title>Circuito.io</title>
            <circle className="circuito-logo dot" cx="155.2" cy="31.9" r="4"/>
            <circle className={'circuito-logo '+color} cx="30.12" cy="6.91" r="4"/>
            <circle className={'circuito-logo '+color} cx="103.76" cy="6.91" r="4"/>
            <circle className={'circuito-logo '+color} cx="166.99" cy="6.91" r="4"/>
            <path className={'circuito-logo '+color} d="M22.9,33.19a0.56,0.56,0,0,1-.24.67,15.09,15.09,0,0,1-7.34,2.26c-7.18,0-11.95-4.6-11.95-11.51S8.14,13.1,15.32,13.1a15.09,15.09,0,0,1,7.34,2.26,0.56,0.56,0,0,1,.24.67l-2.3,4.84a0.46,0.46,0,0,1-.71.24,7,7,0,0,0-3.81-1.19,4.47,4.47,0,0,0-4.8,4.68,4.47,4.47,0,0,0,4.8,4.68,7,7,0,0,0,3.81-1.19,0.46,0.46,0,0,1,.71.24Z"/>
            <path className={'circuito-logo '+color} d="M33.61,14c0.32,0,.39-0.06.39,0.22V34.89c0,0.32-.07.11-0.39,0.11h-7c-0.32,0-.63.21-0.63-0.11V14.22c0-.28.31-0.22,0.63-0.22h7Z"/>
            <path className={'circuito-logo '+color} d="M50.44,14c0.32,0,.56-0.1.56,0.22v5.16a0.61,0.61,0,0,1-.56.63h-1C47.9,20,46,20.61,46,21.6v13.3c0,0.32-.24.11-0.56,0.11h-7c-0.32,0-1.45.21-1.45-.11V14.26C37,14,38.14,14,38.45,14h4.48a0.61,0.61,0,0,1,.64.44l0.6,1.84A6.68,6.68,0,0,1,49.41,14h1Z"/>
            <path className={'circuito-logo '+color} d="M72.11,33.19a0.56,0.56,0,0,1-.24.67,15.09,15.09,0,0,1-7.34,2.26c-7.18,0-11.95-4.6-11.95-11.51S57.35,13.1,64.53,13.1a15.09,15.09,0,0,1,7.34,2.26,0.56,0.56,0,0,1,.24.67l-2.3,4.84a0.46,0.46,0,0,1-.71.24,7,7,0,0,0-3.81-1.19,4.47,4.47,0,0,0-4.8,4.68,4.47,4.47,0,0,0,4.8,4.68,7,7,0,0,0,3.81-1.19,0.46,0.46,0,0,1,.71.24Z"/>
            <path className={'circuito-logo '+color} d="M95.21,14c0.32,0-.21-0.06-0.21.26V34.93c0,0.28.52,0.07,0.21,0.07H90.72a0.54,0.54,0,0,1-.63-0.35l-0.6-1.31A8.54,8.54,0,0,1,83.18,36C78,36,75,32.79,75,27.39V14.26c0-.32.2-0.26,0.52-0.26h7c0.32,0,.49-0.06.49,0.26V26.4c0,1.35.57,2.26,1.84,2.26A2.44,2.44,0,0,0,87,27.39V14.26c0-.32.9-0.26,1.22-0.26h7Z"/>
            <path className={'circuito-logo '+color} d="M107.11,14c0.32,0-.11-0.06-0.11.22V34.89c0,0.32.43,0.11,0.11,0.11h-7c-0.32,0-1.12.21-1.12-.11V14.22c0-.28.81-0.22,1.12-0.22h7Z"/>
            <path className={'circuito-logo '+color} d="M124.29,14c0.36,0,.6.07,0.6,0.39l-0.44,3.93a0.73,0.73,0,0,1-.59.68H121V34.89c0,0.32,0,.11-0.28.11h-7c-0.32,0-.74.21-0.74-0.11V19h-2.12a1.33,1.33,0,0,1-.88-0.9V14.26c0-.36.6-0.26,0.88-0.26H113V9.33a0.72,0.72,0,0,1,.67-0.56l6.86-.67a0.48,0.48,0,0,1,.47.56V14h3.29Z"/>
            <path className={'circuito-logo '+color} d="M150.21,24.57c0,6.91-4.68,11.51-11.67,11.51s-11.67-4.6-11.67-11.47,4.68-11.51,11.67-11.51S150.21,17.71,150.21,24.57Zm-15.6,0c0,2.58,1.59,4.33,3.93,4.33s3.93-1.75,3.93-4.37-1.59-4.33-3.93-4.33S134.61,22,134.61,24.61Z"/>
            <path className={'circuito-logo '+color} d="M170.49,14c0.32,0,.51-0.06.51,0.22V34.89c0,0.32-.2.11-0.51,0.11h-7c-0.32,0-.5.21-0.5-0.11V14.22c0-.28.18-0.22,0.5-0.22h7Z"/>
            <path className={'circuito-logo '+color} d="M197.43,24.57c0,6.91-4.68,11.51-11.67,11.51s-11.67-4.6-11.67-11.47,4.68-11.51,11.67-11.51S197.43,17.71,197.43,24.57Zm-15.6,0c0,2.58,1.59,4.33,3.93,4.33s3.93-1.75,3.93-4.37-1.59-4.33-3.93-4.33S181.84,22,181.84,24.61Z"/>
        </svg>
    );
}

CircuitoLogo.propTypes = {
  isLight: React.PropTypes.bool,
  className: React.PropTypes.string,
}

CircuitoLogo.defaultProps = {
  className: ''
}

export default CircuitoLogo;
