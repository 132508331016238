import { TOGGLE_ACTIVE_TAB } from '../constants';

export default function(actionType){
	return (dispatch, getStateFunc) => {
		analyticsSimple('Tab Click', 'Tab', 'Click', 'Tab Click ' + actionType.toLowerCase(), true);

  		dispatch({
      		type: TOGGLE_ACTIVE_TAB,
      		actionType,
    	});
	}
}
