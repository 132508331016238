import React from 'react';
import { ButtonRaised } from '.'

///
///  A button which can display a popover when not valid
///  onClick will be called only when the button is valid
///
class PopoverButton extends React.Component{
  constructor(props){
    super(props);
    this.usePopover = false;
  }

  componentDidMount(){
    this.$btn = $(this.refs.btn);
    this._resetPopover();
  }

  componentDidUpdate(prevProps){
    if(this.props.popoverContent !== prevProps.popoverContent){
      this._resetPopover();
    }
  }

  _resetPopover(){
    this.$btn.popover('destroy');
    if(this.props.popoverContent){
      this.$btn.popover({
        content: this.props.popoverContent,
        placement: 'top',
        trigger: 'manual',
      });
      this.usePopover = true;
    } else {
      this.usePopover = false;
    }
  }

  _handleSubmit(e){
    if(!this.props.isValid && this.usePopover){
      e.preventDefault();
      e.stopPropagation();
      this.$btn.popover('show');

      if (this.props.onInvalid) {
        this.props.onInvalid();
      }
    }
    else {
      this.props.onClick();
    }
  }

  _handleLeave(){
    if(this.usePopover){
      this.$btn.popover('hide');
    }
  }

  render(){
    return(
      <ButtonRaised className={`btn-success ${this.props.className}`} isEnabled={this.props.isValid} label={this.props.label}
        onClick={this._handleSubmit.bind(this)} onMouseLeave={this._handleLeave.bind(this)}>
      </ButtonRaised>
    );
  }
}
PopoverButton.displayName = 'PopoverButton';
PopoverButton.propTypes = {
  label: React.PropTypes.string.isRequired,
  isValid: React.PropTypes.bool.isRequired,
  popoverContent: React.PropTypes.string,
  onClick: React.PropTypes.func,
  onInvalid: React.PropTypes.func,
  className: React.PropTypes.string,
}

ButtonRaised.defaultProps = {
  className: 'default',
}

export default PopoverButton;
