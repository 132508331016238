import React from 'react';

// This is a base class for all modals.
// it wraps any children in a modal
class Modal extends React.Component{
  componentDidMount(){
    // Bind the close/open proprty to the BS close/open event
    $(this.refs.modal).on('hidden.bs.modal', this.props.onClose);
    $(this.refs.modal).on('shown.bs.modal', this.props.onOpen);

    this._handleModal();
  }

  componentDidUpdate(){
    this._handleModal();
  }

  // Handles the modal's inner JS functions according to the isOpen prop
  _handleModal(){
    if(this.props.isOpen){
      this._openModal();
    }else{
      this._closeModal();
    }
  }

  // Opens the modal
  _openModal(){
    if (this.props.unDismissable) {
      $(this.refs.modal).modal({backdrop: 'static', keyboard: false});
    }
    
    // Open the visual modal if it's not shown
    if(!this._isModalShown()){
      $(this.refs.modal).modal('show');
    }
  }

  // Closes the modal
  _closeModal(){
    // Close the visual modal if it's shown
    if(this._isModalShown()){
      $(this.refs.modal).modal('hide');
      $('body').removeClass('modal-open');
      $('.modal-backdrop').remove();
    }
  }

  // Determines weather the actual visual modal is displayed on the screen
  _isModalShown(){
    return(typeof $(this.refs.modal).data('bs.modal') !== 'undefined' && $(this.refs.modal).data('bs.modal').isShown);
  }

  render(){
    return(
      <div ref="modal" className={`modal${this.props.fade ? " fade": ""} ${this.props.modalClass}`} id={this.props.modalId} tabIndex="-1" role="dialog" aria-labelledby="modal">
        {this.props.children}
      </div>
    );
  }
}
Modal.displayName = "Modal";
Modal.propTypes = {
  isOpen: React.PropTypes.bool,
  modalId: React.PropTypes.string,
  modalClass: React.PropTypes.string,
  onOpen: React.PropTypes.func,
  onClose: React.PropTypes.func,
  fade: React.PropTypes.bool,
  unDismissable: React.PropTypes.bool,
}
Modal.defaultProps = {
  isOpen: false,
  fade: false,
  modalId: "",
  modalClass: "",
  unDismissable: false,
}

export default Modal;
