import React from 'react';
import Modal from './modal';
import MaterialIcon from './material_icon';

class IFrameVideoModal extends React.Component{

  // Opens the modal
  _handleOpen(){
    // Set the iframe source to start the video
    $(this.refs.modalFrame).attr("src", this.props.location);
  }

  // Closes the modal
  _handleClose(){
    // Set the iframe source to start the video
    $(this.refs.modalFrame).attr("src", "");

    this.props.onClose();
  }

  render(){
    return(
      <Modal modalId='video-modal'
             isOpen={this.props.isOpen} 
             onOpen={this._handleOpen.bind(this)}
             onClose={this._handleClose.bind(this)}>
        <div className="video-modal-container">
          <a className="video-modal-close" data-dismiss="modal" aria-label="Close">
            <MaterialIcon name="close" />
          </a>
          <div className="aspect-ratio">
            <iframe className="video-modal-content" ref="modalFrame" src="" frameBorder="0" allowFullScreen></iframe>
          </div>
        </div>
      </Modal>
    );
  }
}
IFrameVideoModal.displayName = "IFrameVideoModal";
IFrameVideoModal.propTypes = {
  location: React.PropTypes.string.isRequired,
  onClose: React.PropTypes.func,
  isOpen: React.PropTypes.bool
}

export default IFrameVideoModal;
