// Redux
export const LOAD_COMPONENTS_REQUEST = 'LOAD_COMPONENTS_REQUEST';
export const LOAD_COMPONENTS_SUCCESS = 'LOAD_COMPONENTS_SUCCESS';
export const LOAD_COMPONENTS_FAILURE = 'LOAD_COMPONENTS_FAILURE';
export const LOAD_CB_JSONS_REQUEST = 'LOAD_CB_JSONS_REQUEST';
export const LOAD_CB_JSONS_SUCCESS = 'LOAD_CB_JSONS_SUCCESS';
export const LOAD_CB_JSONS_FAILURE = 'LOAD_CB_JSONS_FAILURE';
export const LOAD_SVG_FILE_REQUEST = 'LOAD_SVG_FILE_REQUEST';
export const LOAD_SVG_FILE_SUCCESS = 'LOAD_SVG_FILE_SUCCESS';
export const LOAD_SVG_FILE_FAILURE = 'LOAD_SVG_FILE_FAILURE';
export const SEED_COMPONENTS_REQUEST = 'SEED_COMPONENTS_REQUEST';
export const SEED_COMPONENTS_SUCCESS = 'SEED_COMPONENTS_SUCCESS';
export const SEED_COMPONENTS_FAILURE = 'SEED_COMPONENTS_FAILURE';
export const UPDATE_COMPONENT_DEF_REQUEST = 'UPDATE_COMPONENT_DEF_REQUEST';
export const UPDATE_COMPONENT_DEF_SUCCESS = 'UPDATE_COMPONENT_DEF_SUCCESS';
export const UPDATE_COMPONENT_DEF_FAILURE = 'UPDATE_COMPONENT_DEF_FAILURE';
export const DOWNLOAD_COMPONENT_PROTOTYPE_REQUEST = 'DOWNLOAD_COMPONENT_PROTOTYPE_REQUEST';
export const DOWNLOAD_COMPONENT_PROTOTYPE_SUCCESS = 'DOWNLOAD_COMPONENT_PROTOTYPE_SUCCESS';
export const DOWNLOAD_COMPONENT_PROTOTYPE_FAILURE = 'DOWNLOAD_COMPONENT_PROTOTYPE_FAILURE';
export const REQUEST_FIELD_CHANGED = 'REQUEST_FIELD_CHANGED';
export const VALIDATE_COMPONENT_REQUEST = 'VALIDATE_COMPONENT_REQUEST';
export const VALIDATE_COMPONENT_SUCCESS = 'VALIDATE_COMPONENT_SUCCESS';
export const VALIDATE_COMPONENT_FAILURE = 'VALIDATE_COMPONENT_FAILURE';
export const COMPONENT_ADDED = 'COMPONENT_ADDED';
export const COMPONENT_REMOVED = 'COMPONENT_REMOVED';
export const COMPONENT_SWITCHED = 'COMPONENT_SWITCHED';
export const COMPONENTS_SELECTED = 'COMPONENTS_SELECTED';
export const SOLVER_ERROR = 'SOLVER_ERROR';
export const SEARCH_INVENTORY = 'SEARCH_INVENTORY';
export const FILTER_INVENTORY_TYPE = 'FILTER_INVENTORY_TYPE';
export const SEND_UPDATES_CHANGED = 'SEND_UPDATES_CHANGED';
export const SEARCH_BAR_FOCUS_CHANGED = 'SEARCH_BAR_FOCUS_CHANGED';
export const INVENTORY_COMPONENT_DRAG_START = 'INVENTORY_COMPONENT_DRAG_START';
export const INVENTORY_COMPONENT_DRAG_END = 'INVENTORY_COMPONENT_DRAG_END';
export const BUILDER_ITEM_DRAG_START = 'BUILDER_ITEM_DRAG_START';
export const BUILDER_ITEM_DRAG_END = 'BUILDER_ITEM_DRAG_END';
export const DEFAULT_CLICK = 'DEFAULT_CLICK';
export const BOM_HOVER_ENTER = 'BOM_HOVER_ENTER';
export const BOM_HOVER_EXIT = 'BOM_HOVER_EXIT';
export const LOAD_CODE_FILE_REQUEST = 'LOAD_CODE_FILE_REQUEST';
export const LOAD_CODE_FILE_SUCCESS = 'LOAD_CODE_FILE_SUCCESS';
export const LOAD_CODE_FILE_FAILURE = 'LOAD_CODE_FILE_FAILURE';

// Modals
export const WALKTHROUGH_MODAL_OPEN = 'WALKTHROUGH_MODAL_OPEN';
export const WALKTHROUGH_MODAL_CLOSE = 'WALKTHROUGH_MODAL_CLOSE';
export const SECONDARY_COMPONENT_INFO_MODAL_OPEN = 'SECONDARY_COMPONENT_INFO_MODAL_OPEN';
export const SECONDARY_COMPONENT_INFO_MODAL_CLOSE = 'SECONDARY_COMPONENT_INFO_MODAL_CLOSE';
export const THUMB_DOWN_MODAL_OPEN = 'THUMB_DOWN_MODAL_OPEN';
export const THUMB_DOWN_MODAL_CLOSE = 'THUMB_DOWN_MODAL_CLOSE';
export const COMPONENT_INFO_MODAL_OPEN = 'COMPONENT_INFO_MODAL_OPEN';
export const COMPONENT_INFO_MODAL_CLOSE = 'COMPONENT_INFO_MODAL_CLOSE';
export const NEW_COMPONENT_MODAL_OPEN = 'NEW_COMPONENT_MODAL_OPEN';
export const NEW_COMPONENT_MODAL_CLOSE = 'NEW_COMPONENT_MODAL_CLOSE';
export const NEW_COMPONENT_MODAL_SUBMIT_REQUEST = 'NEW_COMPONENT_MODAL_SUBMIT_REQUEST';
export const NEW_COMPONENT_MODAL_SUBMIT_SUCCESS = 'NEW_COMPONENT_MODAL_SUBMIT_SUCCESS';
export const NEW_COMPONENT_MODAL_SUBMIT_FAILURE = 'NEW_COMPONENT_MODAL_SUBMIT_FAILURE';

//Sidebar tabs
export const TOGGLE_ACTIVE_TAB = 'TOGGLE_ACTIVE_TAB';

// DnD
export const DRAG_SOURCE_INVENTORY_COMPONENT = 'DRAG_SOURCE_INVENTORY_COMPONENT';
export const DRAG_SOURCE_BUILDER_COMPONENT = 'DRAG_SOURCE_BUILDER_COMPONENT';

// Cart
export const ADDED_TO_CART = 'ADDED_TO_CART';
export const LOADED_CART = 'LOADED_CART';

// Components
export const COMPONENT_DEFAULT_IMAGE = '/static/images/component-default-image-blue.svg';

// Schematics
export const COMPONENT_CONTEXT_MENU_OPEN = 'COMPONENT_CONTEXT_MENU_OPEN';
export const COMPONENT_CONTEXT_MENU_CLOSE = 'COMPONENT_CONTEXT_MENU_CLOSE';
export const COMPONENT_CONTEXT_MENU_IS_CLOSABLE = 'COMPONENT_CONTEXT_MENU_IS_CLOSABLE';
export const SCHEMATICS_SETTINGS_MENU_IS_CLOSABLE = 'SCHEMATICS_SETTINGS_MENU_IS_CLOSABLE';
export const SCHEMATICS_TOOLTIP_UPDATED = 'SCHEMATICS_TOOLTIP_UPDATED';
export const SCHEMATICS_ZOOM = 'SCHEMATICS_ZOOM';
export const SCHEMATICS_SETTINGS_MENU_TOGGLE = 'SCHEMATICS_SETTINGS_MENU_TOGGLE';
