import React from 'react';
import { connect } from 'react-redux';
import MaterialIcon from '../../app/components/material_icon';
import Modal from '../../app/components/modal';
import Btn from '../../app/components/form_controls/btn';
const editorUrl = process.env.COMPONENT_EDITOR_URL;

// Actions
import EditPartModalClose from '../actions/close_edit_part_modal';


class EditPartModal extends React.Component{
  constructor(props) {
    super(props);
    this._onClosed = this._onClosed.bind(this);
    this.isClosing = false;
  }

  _onClosed() {
    this.isClosing = false;
    this.props.onClosed();
  }

  render(){
    return(
      <Modal modalClass="comp-editor-modal"
             isOpen={this.props.isOpen && !this.isClosing}
             onClose={this._onClosed}
             fade={true}>
        <div className="comp-editor-modal modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <button id="modal-close" type="button" className="close"
                      data-dismiss="modal" aria-label="Close">
                <MaterialIcon name="close" />
              </button>
            </div>
            <div>
              <div className="modal-body">
                <div className="gif-container">
                  <img src="/static/images/GitpodGif.gif"/>
                </div>
                <div className="text-app-header-2-bold">
                    Heads Up!
                  </div>
                  <br/>
                  <div className="text-app-body-2">
                    In order to create/edit a component, you will be redirected to the Component Editor which will open in a new tab. Be sure to check out the "Getting Started" Section to learn how to use the Component Editor.
                  </div>
                  <br/>
                  <div data-dismiss="modal">
                    <Btn type="primary" label="Continue" isStretch={true} onClick={()=> {  analyticsSimple('Clicked Edit', 'Button', 'Click', `edit location: ${this.props.eventSrc}`, true); window.open(editorUrl, '_blank')} }/>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

EditPartModal.displayName = 'EditPartModal';
EditPartModal.propTypes = {
  isOpen: React.PropTypes.bool.isRequired,
  onClosed: React.PropTypes.func,
}

const mapStateToProps = (state)=> {
  return{
    isOpen: state.componentRequests.isEditPartModalOpen,
    eventSrc : state.componentRequests.eventSrc
  }
}

const mapDispatchToProps = {
  onClosed: EditPartModalClose,
}

export default connect(mapStateToProps,mapDispatchToProps)(EditPartModal);
