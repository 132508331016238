import React from 'react';
import MediaQuery from 'react-responsive';
import HamburgerButton from '../components/hamburger_button';
import CartPreview from '../../requests/containers/cart_preview';
import { connect } from 'react-redux';
import toggleSidebar from '../actions/header_sidebar_toggle';
import { getSelectedComponentsGroupedByType } from '../../requests/selectors';
import { ON_DESKTOP_MQ } from '../../config.js'
import { Btn } from '../../app/components/form_controls';
import { getQueryParam } from '../../utils';
const _ = require('lodash');

class MainHeader extends React.Component{
  constructor(props){
    super(props)
    this.state = {activePartsBtn: false, partsPreviewActive:((this.props.isMagicLink) ? true : false)};
  }

  _renderSEOTitle(selectedComponentsByType, isMagicLink) {
    if (!isMagicLink
        || window.isMobile
        || !this.props.showSEOTitle
        || !selectedComponentsByType.hasOwnProperty('controller')
        || selectedComponentsByType['controller'].length == 0){
      // document.title = "Circuito.io"
      return null;
    }

    let comps = [];
    if (selectedComponentsByType.hasOwnProperty('input')
        && selectedComponentsByType['input'].length > 0) {
            selectedComponentsByType['input'].map( (item,indx) => {
              let compName = (item.app) ? item.app.shortName : item.name;
              if(comps.indexOf(compName) === -1){
                  comps.push(compName);
              }
            })
    }
    if (selectedComponentsByType.hasOwnProperty('output')
        && selectedComponentsByType['output'].length > 0) {
      selectedComponentsByType['output'].map( (item,indx) => {
        let compName = (item.app) ? item.app.shortName : item.name;
        if(comps.indexOf(compName) === -1){
            comps.push(compName);
        }
      })
    }

    if (comps.length == 0) {
      return null;
    }
    let allCompsNames = comps.join(', ');
    let text = `How to connect ${(selectedComponentsByType['controller'][0].app.shortName || selectedComponentsByType['controller'][0].app.appName)} to ${allCompsNames}`;
    return (
      <h1 className="ellipsis text-app-nav active page-nav-header" title={text}>
        {text}
      </h1>
    );
  }

  _onTopCheckoutClick() {
    analyticsSimple("Top Go To Cart", "Button", "Click", "Top Go To Cart", true);
    this.setState({activePartsBtn:!this.state.activePartsBtn})
  }

  _getHeaderRightSide(){
    if(this.props.showPartsPanel){
        return (
          <div className='header-right' onMouseOver={()=> {
                          if(!this.state.partsPreviewActive){
                            this.setState({partsPreviewActive:true})
                            analyticsSimple("Top Cart Hover", "Button", "Hover", "Top View Cart", true)
                          }
                        }
                      }
                      onMouseLeave={()=> {
                            setTimeout( () => {
                              if(!this.state.activePartsBtn){
                                //this.setState({partsPreviewActive:false})
                                analyticsSimple("Top Cart HoverOut", "Button", "HoverOut", "Top View Cart", true)
                              }
                            },350)
                        }
                      }>
          <Btn label="Parts" id='cart' className={`cart-button ${this.state.activePartsBtn ? "active" : ""}`} type="secondary-negative-outline-yellow" icon="list_alt"
                onClick={this._onTopCheckoutClick.bind(this)}>
            {( !_.isEmpty(this.props.validationData) ) ?
              <span className='badge cart'>
                {this.props.validationData.output.components.reduce((sum, item)=> sum + item.qty, 0)}
              </span>
              : null
            }
          </Btn>
        </div>
      )
    }
  }

  _getSandboxTitle(){
    let isSandbox = getQueryParam('u');
    if(isSandbox && isSandbox.length !== 0){
      return(<span className="sandbox text-app-nav"> Sandbox </span>)
    }
  }

  render(){
    return(
      <div className="main-header">
        <div className='header-left'>
          <HamburgerButton onClick={this.props.onHamburgerClick}/>
          {this._renderSEOTitle(this.props.selectedComponentsByType, this.props.isMagicLink)}
        </div>
        <div className='btn' onClick={this.props.onAppRootClicked}>
          <img className="main-header-logo" src="/static/images/cir_logo_white.svg" alt="circuito.io" title="circuito.io"/>
          {this._getSandboxTitle()}
        </div>
        {this._getHeaderRightSide()}
        <CartPreview onHover={ () => {
            this.setState({partsPreviewActive:true});
          }}
          onHoverOut={ () => {
            if(!this.state.activePartsBtn){
                this.setState({partsPreviewActive:false});
            }
          }}
            isVisible={(this.state.partsPreviewActive)} onCloseCart={()=>{
            this.setState({partsPreviewActive:false,activePartsBtn:false})
          }}/>
      </div>
    );
  }
}

MainHeader.displayName = 'MainHeader';
MainHeader.propTypes = {
  onHamburgerClick: React.PropTypes.func,
  onAppRootClicked: React.PropTypes.func,
  selectedComponentsByType: React.PropTypes.object,
  isMagicLink: React.PropTypes.bool,
  selectedComponentsIds: React.PropTypes.arrayOf(React.PropTypes.string),
  cartSize: React.PropTypes.number,
  showSEOTitle: React.PropTypes.bool,
};

const mapDispatchToProps = {
  onHamburgerClick: ()=> (dispatch, getState)=> dispatch(toggleSidebar(getState)),
}

const mapStateToProps = (state) => {
  return({
    selectedComponentsByType: getSelectedComponentsGroupedByType(state),
    isMagicLink: state.requests.isMagicLink,
    selectedComponentsIds: state.requests.selectedComponentsIds,
    cartSize: state.requests.cartSize,
    showSEOTitle: state.app.showSEOTitle,
    validationData: state.app.validationData,
    showPartsPanel:state.app.showPartsPanel,
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(MainHeader);
