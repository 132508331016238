import React from 'react';
import Ratings from './ratings';
import MediaQuery from 'react-responsive';
import { ON_DESKTOP_MQ } from '../../config.js'

class FixedCard extends React.Component{
  render(){
    //var classString =  + this.props.styleName
    var classNames = 'fixed-card circuito-card ';
    return(
      <div className={(this.props.styleName) ? classNames + this.props.styleName : classNames}>
        <div id='side-content' style={{'display': 'flex', 'flex-flow': 'column', 'flex': '1 1 auto', 'height': '100%'}}>
          {this.props.children}
        </div>
        <MediaQuery query={ON_DESKTOP_MQ}>
          <Ratings/>
        </MediaQuery>
      </div>
    );
  }
}

FixedCard.displayName = 'FixedCard';

FixedCard.propTypes = {
  children: React.PropTypes.node,
  styleName:React.PropTypes.string,
};

export default FixedCard;
