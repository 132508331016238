import React from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import {ON_MOBILE_MQ, ON_DESKTOP_MQ} from '../../config.js'

// Components
import Schematics from './schematics';
import FixedCard from '../components/fixed_card';
import Inventory from './inventory';

class DesignTab extends React.Component{
  constructor(props) {
    super(props);

    this._updateDimensions = this._updateDimensions.bind(this);
    this.state = {width: window.innerWidth};
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this._updateDimensions);
  }

  componentDidMount() {
    this._updateDimensions();
    window.addEventListener("resize", this._updateDimensions);
  }

  _updateDimensions() {
    this.setState({width: window.innerWidth});
  }

  _getSchematics(){
    if (!this.props.isTermsAgreed
        || !this.props.validationData
        || !this.props.validationData.output) {
      return null;
    }

    return(<Schematics/>);
  }

  _renderComponents(){
    let isSideView = (this.state.width > 991);
    return(
      <div className='design upper-desktop-container'>
        <FixedCard styleName="search-card">
          <MediaQuery query={ON_DESKTOP_MQ}>
            {Object.keys(this.props.componentsById).length > 0 ?
              <Inventory isSideView={isSideView}/>
              : null
            }
          </MediaQuery>
          <MediaQuery query={ON_MOBILE_MQ}>
            <Inventory isSideView={isSideView}/>
          </MediaQuery>
        </FixedCard>
        {this._getSchematics()}
      </div>
    );
  }

  render(){
    return(this._renderComponents());
  }
}

DesignTab.displayName = 'DesignTab';
DesignTab.propTypes = {
  componentsById: React.PropTypes.object,
  selectedComponentsIds: React.PropTypes.arrayOf(React.PropTypes.string),
  isTermsAgreed: React.PropTypes.bool,
}

const mapStateToProps = (state, {params}) => {
  return({
    ...state.requests,
    activeTab: state.requests.activeTab,
    selectedComponentsIds: state.requests.selectedComponentsIds,
    componentsById: state.requests.componentsById,
    isTermsAgreed: state.app.isTermsAgreed,
    validationData: state.app.validationData,
  });
}

export default connect(mapStateToProps, null)(DesignTab);
