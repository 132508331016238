/**
 * Created by roboamir on 25/07/16.
 */
import { SIDEBAR_CLOSE} from '../constants';

export default function(){
  return{
    type: SIDEBAR_CLOSE
  }
}

