import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

//Actions
import tabClicked from '../actions/tab_change';

class Tabs extends React.Component{
  constructor(props){
    super(props);

    this._onTabClick = this._onTabClick.bind(this);
    this._getCompNum = this._getCompNum.bind(this);
  }

  _onTabClick(tabName, notification){
    if(this.props.activeTab !== tabName){
      this.props.onTabClicked(tabName)
    }
  }

  _getCompNum(){
    // On mobile we don't draw tab badges
    if (window.isMobile) {
      return false;
    }

    if (!this.props.validationData) {
      return 0;
    }

    return this.props.validationData.output.components.reduce((sum, item)=> sum + item.qty, 0);
  }

  _renderTab(tabName, badgeNum) {
    return (
      <div onClick={()=>this._onTabClick(tabName)}
           className={`btn btn-flat text-app-nav tab-${this.props.deviceType}
                      ${this.props.activeTab === tabName ? " active" : ""}`}>
          <span>
            {tabName}
          </span>
          {badgeNum ?
            <span className={`text-app-bubble badge active-${this.props.isBadgeActive}`}>
              {badgeNum}
            </span>
          : null}
          {(this.props.deviceType === 'desktop' && this.props.activeTab === tabName) ?
            <div className='tab-arrow'>
              <div/>
            </div>
          : null}
      </div>
    );
  }

  render(){
    return(
      <div className={`main-tabs tab-${this.props.deviceType}`}>
          {this._renderTab('DESIGN')}
          {this._renderTab('CODE')}
          {this._renderTab('PROJECT GUIDE')}
      </div>
    )
  }
}

Tabs.displayName = 'Tabs';


Tabs.propTypes = {
  activeTab: React.PropTypes.string,
  deviceType: React.PropTypes.string,
  isBadgeActive: React.PropTypes.bool,
  validationData: React.PropTypes.object,
  onTabClicked: React.PropTypes.func,
}

const mapStateToProps = (state) => {
  return({
    activeTab: state.requests.activeTab,
    isBadgeActive:state.requests.isBadgeActive,
    validationData:state.app.validationData,
  })
}

const mapDispatchToProps = {
  onTabClicked: tabClicked,
}

export default connect(mapStateToProps, mapDispatchToProps)(Tabs);
