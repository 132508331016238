import * as types from '../constants';
import { submitComponentRequest } from '../../api';

export default function(data){
  return((dispatch)=>{
    dispatch({type: types.NEW_COMPONENT_MODAL_SUBMIT_REQUEST});
    submitComponentRequest(data)
    .then(()=>{
      dispatch({type: types.NEW_COMPONENT_MODAL_SUBMIT_SUCCESS})
    })
    .catch((error)=>{
      console.error(error);
      dispatch({type: types.NEW_COMPONENT_MODAL_SUBMIT_FAILURE})
    })
  })
}
