///
/// Loads and categorizes components from the server
///
import * as types from '../constants';
import { groupBy } from 'lodash';
import { getComponents } from '../../api';
import showNotification from '../../app/actions/show_notification';
import { NotificationTypes } from '../../app/actions/show_notification';

export default function(){
  // Fetch all components
  return (dispatch, getState) => {
    dispatch({type: types.LOAD_COMPONENTS_REQUEST})
    return getComponents()
      .then((res)=>{
        if (res.status && res.status !== 200) {
          throw(res.errMsg || "look at the logs");
        }

        const parts = res.parts;
        const blocks = res.blocks;
        const cbJsons = res.jsons;
        const blocksByIds = res.blocksByIds;

        let jsonData = {
          blocks:blocks,
          blocksByIds: blocksByIds,
          parts:parts,
          cbJsons: cbJsons,
          isBlockHasCodersForController: res.isBlockHasCodersForController,
        }

        dispatch({type: types.LOAD_COMPONENTS_SUCCESS, jsonData})
      })
      /*.catch((error) => {
        dispatch({type: types.LOAD_COMPONENTS_FAILURE, error});
      });*/
  }
}
