import React from 'react';
import MaterialIcon from './material_icon';

const HamburgerButton = (({onClick, className})=>{
  return(
    <a href="javascript: void(0);" className={"hamburger btn " + className} onClick={onClick}><MaterialIcon name="menu" /></a>
  );
});

HamburgerButton.displayName = "HamburgerButton";
HamburgerButton.propTypes = {
  onClick: React.PropTypes.func,
  className: React.PropTypes.string,
}

HamburgerButton.defaultProps = {
  className: ''
}

export default HamburgerButton;
