/**
 * Created by roboamir on 20/07/23.
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import {Link} from 'react-router';
import { analyticsTrackPage, analyticsLinkClick, getCorrectComponentsForURL } from '../../utils';
import HomepagePlayButton from '../containers/homepage_play_button';
import HomepageVideoModal from '../containers/homepage_video_modal';
import { getHomepagePosts } from '../../api';
import MediaQuery from 'react-responsive';
import { ON_MOBILE_MQ, ON_DESKTOP_MQ } from '../../config';
import HamburgerButton from '../components/hamburger_button'
import SideBar from '../components/sidebar';
import toggleSidebar from '../actions/header_sidebar_toggle';
import CircuitoLogo from '../components/circuito_logo';

class Homepage extends React.Component {
  constructor() {
    super();

    this.state = {
      blogPosts: null,
      projectPosts: null,
      randomCompSplitted: '|',
      isLightHeader: false,
    };

    this.mainAppURL = '/app?components='+getCorrectComponentsForURL();

    this._renderHeaderNav = this._renderHeaderNav.bind(this);
    this._handleScroll = this._handleScroll.bind(this);
    this._renderHeader = this._renderHeader.bind(this);

    this.comps = ['a DC Motor', 'MPU-6050', 'ESP8266', 'HC-05 bluetooth', 'a Stepper Motor', 'an Accelerometer', 'a Servo motor', 'an RGB led', 'a GAS Sensor', 'a lidar-lite', 'an led matrix', 'an adxl345', 'a joystick'];

    this._startRandomCompAnimation = this._startRandomCompAnimation.bind(this);
  }

  componentWillUnmount() {
      if(this.intervalId){
        clearInterval(this.intervalId)
      }
      if(this.timeout){
        clearTimeout(this.timeout)
      }

      this.scrollableContent.removeEventListener('scroll', this._handleScroll);
      document.body.classList.remove('home-page'); // FOR VIDEO MODAL
  }

  componentDidMount() {
    document.body.classList.add('home-page'); // FOR VIDEO MODAL
    analyticsTrackPage('/');

    this.scrollableContent.addEventListener('scroll', this._handleScroll);
    this.upperDarkElemsHeight = this.upperContainerElem.clientHeight + this.sitesElem.clientHeight + (this.headerElem.clientHeight*2);

    this._startRandomCompAnimation();

    getHomepagePosts().then((data)=> {
      console.log(data);
      this.setState({
        projectPosts: data.results.slice(0,3),
        blogPosts: data.results.slice(data.results.length -3, data.results.length)
      });
    })
  }

  _handleScroll(event) {
    if (!this.state.isLightHeader
        && this.upperDarkElemsHeight < event.srcElement.scrollTop) {
      this.setState({isLightHeader: true})
    }
    else if (this.state.isLightHeader
        && this.upperDarkElemsHeight >= event.srcElement.scrollTop) {
      this.setState({isLightHeader: false})
    }
  }

  _startRandomCompAnimation() {
    const TYPING_SPEED = 130;
    const TYPING_BACKSPACE_SPEED = 60;
    const COMPLETE_COMPONENT_NAME_DISPLAY_TIME = 3000;

    this.randomComp = this.comps[Math.floor(Math.random()*this.comps.length)].toUpperCase();
    this.curRandomCompSplitIndex = 1;

    // Interval for adding letters
    this.intervalId = setInterval(()=>{
      this.setState({
        randomCompSplitted: this.randomComp.substring(0, this.curRandomCompSplitIndex)
      });

      this.curRandomCompSplitIndex++;

      if (this.curRandomCompSplitIndex > this.randomComp.length) {
        // Finished writing the word
        clearInterval(this.intervalId);

        // Setting a delay to show off the compleated word
        this.timeout = setTimeout(()=> {
          // Starting to delete letters
          this.intervalId = setInterval(()=> {
            this.curRandomCompSplitIndex--;

            this.setState({
              randomCompSplitted: this.randomComp.substring(0, this.curRandomCompSplitIndex)
            })

            if (this.curRandomCompSplitIndex == 0) {
              // Finished deleting the word
              clearInterval(this.intervalId);

              // Starting another random word
              this.timeout = setTimeout(this._startRandomCompAnimation, TYPING_BACKSPACE_SPEED);
            }
          }, TYPING_BACKSPACE_SPEED);
        }, COMPLETE_COMPONENT_NAME_DISPLAY_TIME);
      }
    }, TYPING_SPEED)
  }

  _renderWalkthroughItem(image, text) {
    return(
      <div className='homepage-walkthrough'>
        <img className='homepage-walkthrough-image' src={image}/>
        <div className='homepage-walkthrough-text text-homepage-body-1-dark'>{text}</div>
      </div>
    );
  }

  _renderGoToAppBtn(isTop) {
    let label = 'Go To App ' + (isTop ? 'Top' : 'Bottom');

    return(
      <Link to={this.mainAppURL} className="robo-btn primary go-to-app"
          onClick={()=>analyticsSimple('Go To App', 'Button', 'Click', label)}>
        GO TO APP
      </Link>
    );
  }

  _renderImageLinkListItem(image, link) {
    return(
      <a href={link} target="_blank"
          onClick={()=>analyticsLinkClick(link)}>
        <img className='home-page-sites-images' src={image}/>
      </a>
    );
  }

  _renderPost(posts, i) {
    if (posts == null || posts.length <= i) {
      return null;
    }

    let image = (posts[i].type === 'component_post') ? posts[i].data.gallery_image_one.url : posts[i].data.cover_image.url;
    let link = (posts[i].type === 'component_post') ? '/component/' + posts[i].uid : '/blog/' + posts[i].uid;
    let text = posts[i].data.page_title;
    let postImgStyle = {"backgroundImage":`url(${image})`}
    return(
      <a className='homepage-post-container blog_post' href={link + "/"} target="_blank" 
         onClick={()=>analyticsLinkClick(link)}>
          <div className='homepage-post-image' style={postImgStyle}></div>
          <h4 className="text-homepage-sub-head-1">{text}</h4>
      </a>
    );
  }

  _renderHeaderNav(nav) {
    return (
      <div>
        <MediaQuery query={ON_MOBILE_MQ}>
          <HamburgerButton onClick={this.props.onHamburgerClick}/>
          <SideBar>
            {nav}
          </SideBar>
        </MediaQuery>
        <MediaQuery query={ON_DESKTOP_MQ}>
          {nav}
        </MediaQuery>
      </div>
    );
  }

  _renderHeader(isLight, isFixed) {
    let headerTextColor = 'light';
    if (isLight) {
      headerTextColor = 'dark';
    }

    let gistLinks = [];
    // On Edge we open new window for embedded gist content like privacy and terms
    if (window.userAgent.browser.name === "Edge" || window.userAgent.browser.name === "IE") {
      gistLinks.push(
        <a className={`text-app-hamburger-${headerTextColor} menu`} href="https://s3-eu-west-1.amazonaws.com/storage.circuito.io/terms.html" target="_blank"
            rel="nofollow" onClick={()=> analyticsLinkClick("terms")}>Terms of service
        </a>);
      gistLinks.push(
        <a className={`text-app-hamburger-${headerTextColor} menu`} href="https://s3-eu-west-1.amazonaws.com/storage.circuito.io/privacy.html" target="_blank"
            rel="nofollow" onClick={()=> analyticsLinkClick("privacy")}>Privacy
        </a>);
    }
    else {
      gistLinks.push(<Link to="/terms" className={`text-app-hamburger-${headerTextColor} menu`} onClick={()=> analyticsLinkClick("terms")}>Terms of service</Link>);
      gistLinks.push(<Link to="/privacy" className={`text-app-hamburger-${headerTextColor} menu`} onClick={()=>analyticsLinkClick("privacy")}>Privacy</Link>);
    }

    return(
      <div className={`homepage-header ${isLight ? 'light' : 'dark'} ${isFixed ? 'fixed' : ''}`} ref={(elem)=> this.headerElem = elem}>
        <div>
          <Link to={this.mainAppURL} className='btn' onClick={()=>analyticsLinkClick("/app")}>
            <CircuitoLogo isLight={!isLight} className="main-header-logo" alt="circuito.io" title="circuito.io"/>
          </Link>
        </div>
        {this._renderHeaderNav(
          <nav>
            <Link to={this.mainAppURL} className={`text-app-hamburger-bold-${headerTextColor} menu`}>
              App
            </Link>
            <a className={`text-app-hamburger-bold-${headerTextColor} menu`} href="/blog/" onClick={()=>{analyticsLinkClick("blog")}}>
              Blog
            </a>
            <Link to="/about" className={`text-app-hamburger-bold-${headerTextColor} menu`} onClick={()=>analyticsLinkClick("about")}>
              About us
            </Link>
            <a className={`text-app-hamburger-bold-${headerTextColor} menu`} href="https://talk.circuito.io" target="_blank"
              rel="nofollow" onClick={()=>{analyticsLinkClick("talk")}}>
              Talk
            </a>
            <Link to="/faq" className={`text-app-hamburger-bold-${headerTextColor} menu`} onClick={()=>analyticsLinkClick("faq")}>
              F.A.Q
            </Link>
            <a className={`text-app-hamburger-bold-${headerTextColor} menu`} href="/partners" target="_self" rel="nofollow" onClick={()=> analyticsLinkClick("partners")}>Partners
            </a>
            {gistLinks}
          </nav>
        )}
      </div>
    );
  }

  render() {
    return(
      <div className='homepage-container' ref={(elem)=> this.scrollableContent = elem}>
        {this._renderHeader(this.state.isLightHeader, true)}
        <div className='upper-container' ref={(elem)=> this.upperContainerElem = elem}>
          <nav className='homepage-how-to-connect'>
            <div>
              <h1 className='text-homepage-display-heading-light'>
                DESIGN YOUR CIRCUIT <br/> WITH CIRCUITO.IO
              </h1>
              <div className='text-homepage-body-1-light'>
                Instantly get a detailed Bill of Materials, wiring guide and test code<br/>for your electronic circuit and bring your project to life.
              </div>
              {this._renderGoToAppBtn(true)}
            </div>
            <HomepagePlayButton/>
          </nav>
        </div>
        <table>
          <tr className='homepage-sites-container' ref={(elem)=> this.sitesElem = elem}>
            <nav>
              {this._renderImageLinkListItem('/static/images/make.svg', 'https://makezine.com/2017/06/21/circuito-io-simplifies-starter-circuits')}
              {this._renderImageLinkListItem('/static/images/psfk.svg', 'https://www.psfk.com/2017/07/circuito-io-makes-it-easy-to-build-your-own-electronic-circuits.html')}
              {this._renderImageLinkListItem('/static/images/petapixel.svg', 'https://petapixel.com/2017/11/16/make-diy-automated-turntable-product-photography')}
              {this._renderImageLinkListItem('/static/images/hackaday.svg', 'https://hackaday.com/2017/11/24/this-diy-turntable-just-got-freaky-fresh')}
              {this._renderImageLinkListItem('/static/images/htxt.africa.svg', 'https://www.htxt.co.za/topics/circuito-io')}
              {this._renderImageLinkListItem('/static/images/springwise.svg', 'https://www.springwise.com/circuito-io-makes-creating-electronic-circuits-easy')}
            </nav>
          </tr>
          <tr>
            <div className='inner-container'>
              <h2 className="text-homepage-header-1">
                JOIN THOUSANDS OF PROTOTYPERS<br/>ALREADY USING CIRCUITO.IO
              </h2>
              <nav className='baseline space-around'>
                {this._renderWalkthroughItem('/static/images/walkthough_step_2.png',
                  'Easy drag-and-drop interface quickly brings your schematic to life')}
                {this._renderWalkthroughItem('/static/images/walkthough_step_4.png',
                  'View your components in the Bill of Materials and purchase the ones you need')}
                {this._renderWalkthroughItem('/static/images/walkthough_step_5.png',
                  'Personalized Project Guide includes step-by-step wiring instructions and test snippets')}
              </nav>
              <div className="go-to-app-wrapper">
                  {this._renderGoToAppBtn(false)}
              </div>
            </div>
          </tr>
          <tr>
            <div className='inner-container'>
              <h2 className="text-homepage-header-1">
                RECENT BLOG POSTS
              </h2>
              <nav className='baseline'>
                {this._renderPost(this.state.blogPosts, 0)}
                {this._renderPost(this.state.blogPosts, 1)}
                {this._renderPost(this.state.blogPosts, 2)}
              </nav>
            </div>
          </tr>
          <tr>
            <div className='inner-container'>
              <h1 className='homepage-how-to-connect-title text-homepage-display-heading'>
                HOW TO CONNECT ARDUINO <br/> WITH&nbsp;
                {this.state.randomCompSplitted}
                <span className='cursor-char'>|</span>
              </h1>
              <div className='homepage-how-to-connect-text text-homepage-body-1-dark'>
                circuito.io’s online circuit builder gives you wiring, code and IoT solutions for<br/>Arduino projects. Purhcase your components directly with us so you can quickly<br/>and effortlessly start prototyping.
              </div>
              <div className="go-to-app-wrapper">
                {this._renderGoToAppBtn(true)}
              </div>
            </div>
          </tr>
          <tr>
            <div className='inner-container'>
              <h2 className="text-homepage-header-1">
                PROJECTS
              </h2>
              <nav className='baseline'>
                {this._renderPost(this.state.projectPosts, 0)}
                {this._renderPost(this.state.projectPosts, 1)}
                {this._renderPost(this.state.projectPosts, 2)}
              </nav>
            </div>
          </tr>
          <tr>
            <div className='inner-container'>
              <h2 className="text-homepage-header-1">
                FIND MORE PROJECTS ON
              </h2>
              <nav>
                {this._renderImageLinkListItem('/static/images/github.png', 'https://github.com/circuito-io')}
                {this._renderImageLinkListItem('/static/images/instructables.png', 'https://www.instructables.com/member/Circuito%20io/instructables/')}
                {this._renderImageLinkListItem('/static/images/hackster.png', 'https://www.hackster.io/circuito-io-team/')}
                {this._renderImageLinkListItem('/static/images/thingiverse.png', 'https://www.thingiverse.com/circuito/about')}
              </nav>
            </div>
          </tr>
        </table>
        {this._renderHeader(false, false)}
        <HomepageVideoModal/>
      </div>
    );
  }
}

const mapDispatchToProps = {
  onHamburgerClick: ()=> (dispatch, getState)=> dispatch(toggleSidebar(getState))
};

Homepage.displayName = 'Homepage';

export default connect(null, mapDispatchToProps)(Homepage);
