/**
 * Created by roboamir on 20/07/23.
 */
import React from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router';
import { analyticsTrackPage, analyticsLinkClick } from '../../utils';
import MediaQuery from 'react-responsive';
import { ON_MOBILE_MQ, ON_DESKTOP_MQ } from '../../config';
import Btn from '../components/form_controls/btn';

class StyleSheet extends React.Component {
  constructor() {
    super();
    this.style = {
      section:{
        "margin": "0px auto",
        "display": "block",
        "width": "70%"
      },
      subSection:{
        "padding":"20px",
        "background":"rgba(0,0,0,0.5)",
        "margin":"25px 0px"
      },
      headerLogo:{
        "height": "100px"
      },
      header:{
        "text-align": "center",
        "margin": "50px 0px"
      },
      negativeBg:{
        "padding": "7px",
        "background-color": "#36454E",
        "width": "300px"
      },
      btnsRow:{
        "margin":"10px 5px"
      },
      subSectionTitle:{
        "text-decoration": "underline",
        "margin": "10px"
      }
    }
  }

  componentWillUnmount() {

  }

  componentDidMount() {
    analyticsTrackPage('/stylesheet');
  }

  _getTypoSection(){
    return(
      <section className="typography" style={this.style.section}>
        <style jsx>{`
         .homepage-headers div,
         .app-headers div {
           margin:10px 0px;
         }
       `}</style>
        <div className="h1 font-roboto">Typography:</div>
        <div className="homepage-headers" style={this.style.subSection}>
            <div className="text-homepage-display-heading">
              Homepage Display Heading
              <span> - class: text-homepage-display-heading</span>
            </div>
            <div className="text-homepage-header-1">
              Homepage Header 1
              <span> - class: text-homepage-header-1</span>
            </div>
            <div className="text-homepage-header-2">
              Homepage Header 2
              <span> - class: text-homepage-header-2</span>
            </div>
            <div className="text-homepage-sub-head-1">
              Homepage Sub-head 1
              <span> - class: text-homepage-sub-head-1</span>
            </div>
            <div className="text-homepage-body-1-light">
              Homepage Body 1 Light
              <span> - class: text-homepage-body-1-light</span>
            </div>
            <div className="text-homepage-body-1-dark">
              Homepage Body 1 Dark
              <span> - class: text-homepage-body-1-dark</span>
            </div>
            <div className="text-homepage-nav-bold">
              Homepage Nav Bold
              <span> - class: text-homepage-nav-bold</span>
            </div>
            <div className="text-homepage-nav">
              Homepage Nav Regular
              <span> - class: text-homepage-nav</span>
            </div>
            <div className="text-homepage-btn">
              Button Text
              <span> - class: text-homepage-btn</span>
            </div>
        </div>

        <div className="app-headers" style={this.style.subSection}>
            <div className="text-app-header-1">
              App Header 1
              <span> - class: text-app-header-1</span>
            </div>
            <div className="text-app-sub-header-1">
              App sub-head 1
              <span> - class: text-app-sub-header-1</span>
            </div>
            <div className="text-app-header-2">
              App Header 2
              <span> - class: text-app-header-2</span>
            </div>
            <div className="text-app-header-2-bold">
              App Header 2 bold
              <span> - class: text-app-header-2-bold</span>
            </div>
            <div className="text-app-sub-header-2">
              App Sub-header 2
              <span> - class: text-app-sub-header-2</span>
            </div>
            <div className="text-app-body-2">
              App Body 2
              <span> - class: text-app-body-2</span>
            </div>
            <div className="text-app-body-2-bolder">
              Text app body 2 bolder
              <span> - class: text-app-body-2-bolder</span>
            </div>
            <div className="text-app-hamburger-light">
              App Hamburger light
              <span> - class: text-app-hamburger-light</span>
            </div>
            <div className="text-app-hamburger-bold-light">
              App Hamburger bold light
              <span> - class: text-app-hamburger-bold-light</span>
            </div>
            <div className="text-app-hamburger-dark">
              App Hamburger dark
              <span> - class: text-app-hamburger-dark</span>
            </div>
            <div className="text-app-hamburger-bold-dark">
              App Hamburger bold dark
              <span> - class: text-app-hamburger-bold-dark</span>
            </div>
            <div className="text-app-nav-active">
              App Nav active
              <span> - class: text-app-nav-active</span>
            </div>
            <div className="text-app-nav">
              App Nav inactive
              <span> - class: text-app-nav</span>
            </div>
            <div className="text-app-comp-list-item">
              App Component List Item
              <span> - class: text-app-comp-list-item</span>
            </div>
            <div className="text-app-comp-list-item-bold">
              App Component List Item Bold
              <span> - class: text-app-comp-list-item-bold</span>
            </div>
            <div className="text-app-tooltip">
              App Tooltip
              <span> - class: text-app-tooltip</span>
            </div>
            <div className="text-app-tooltip-dark">
              App Tooltip Dark
              <span> - class: text-app-tooltip-dark</span>
            </div>
            <div className="text-app-alert">
              App Alert
              <span> - class: text-app-alert</span>
            </div>
            <div className="text-app-info">
              App Information
              <span> - class: text-app-info</span>
            </div>
            <div className="text-app-searchbar">
              App Search Bar
              <span> - class: text-app-searchbar</span>
            </div>
            <div className="text-app-btn">
              Button Text
              <span> - class: text-app-btn</span>
            </div>
            <div className="text-app-btn-2">
              App Button 2
              <span> - class: text-app-btn-2</span>
            </div>
            <div className="text-app-dark-btn-2">
              App Dark Button 2
              <span> - class: text-app-dark-btn-2</span>
            </div>

            <div className="text-app-bubble">
              text-app-bubble
              <span> - class: text-app-bubble</span>
            </div>

        </div>
      </section>
    )
  }

  _getButtonsSection(){
    return(
      <section className="buttons" style={this.style.section}>
        <div className="h1 font-roboto">Buttons:</div>
        <span>*Between filled and outlined buttons - filled buttons are meant to be the stronger focus, CTA or prefered click, outlined buttons represent a more optional click</span>
        <div className="sub-section-title" style={this.style.subSectionTitle}>Primary Style Buttons:</div>
        <div className="primary-btns" style={this.style.btnsRow}>
            <Btn label="Primary Button - Normal" type="primary"/>
            <Btn label="Primary Button - disabled" type="primary" isEnabled={false}/>
        </div>

        <div className="primary-btns-outline" style={this.style.btnsRow}>
            <Btn label="Primary Button - Normal" type="primary-outline"/>
            <Btn label="Primary Button - disabled" type="primary-outline" isEnabled={false}/>
        </div>

        <div className="primary-btns-yellow" style={this.style.btnsRow}>
            <Btn label="Primary Button - Yellow" type="primary-yellow"/>
            <Btn label="Primary Button - Yellow" type="primary-yellow" isEnabled={false}/>
        </div>

        <div className="primary-btns-outline-yellow" style={this.style.btnsRow}>
          <Btn label="Primary Button Outline - Yellow" type="primary-yellow-outline"/>
          <Btn label="Primary Button Outline - Yellow" type="primary-yellow-outline" isEnabled={false}/>
        </div>

        <div className="sub-section-title" style={this.style.subSectionTitle}>Secondary Style Buttons:</div>

        <div className="secondary-btns-with-icons" style={this.style.btnsRow}>
            <Btn label="Select" type="secondary" icon="alarm"/>
            <Btn label="Select" type="secondary" icon="alarm" isEnabled={false}/>
        </div>

        <div className="secondary-outline-btns-with-icons" style={this.style.btnsRow}>
            <Btn label="Select" type="secondary-outline-gray" icon="alarm"/>
            <Btn label="Select" type="secondary-outline-gray" icon="alarm" isEnabled={false}/>
        </div>

        <div className="secondary-outline-btns-with-icons" style={this.style.btnsRow}>
          <Btn label="Select" type="secondary-outline-yellow" icon="alarm"/>
          <Btn label="Select" type="secondary-outline-yellow" icon="alarm" isEnabled={false}/>
        </div>

        <div className="secondary-negative-outline-btns-with-icons" style={this.style.negativeBg}>
          <Btn label="Select" type="secondary-negative-outline-yellow" icon="alarm"/>
          <Btn label="Select" type="secondary-negative-outline-yellow" icon="alarm" isEnabled={false}/>
        </div>

        <div className="sub-section-title" style={this.style.subSectionTitle}>No Icon Buttons:</div>


        <div className="secondary-outline-btns" style={this.style.btnsRow}>
          <Btn label="Remove" type="secondary-outline-gray"/>
          <Btn label="Clear Circuit" type="secondary-outline-gray" isEnabled={false}/>
        </div>

        <div className="secondary-btns" style={this.style.btnsRow}>
            <Btn label="Select" type="secondary"/>
            <Btn label="Select" type="secondary" isEnabled={false}/>
        </div>

        <div className="secondary-outline-btns" style={this.style.btnsRow}>
          <Btn label="Added!" type="secondary-outline"/>
          <Btn label="Added!" type="secondary-outline" isEnabled={false}/>
        </div>

      </section>
    )
  }
  _getColorsSection(){
    return(
      <section className="colors-section" style={this.style.section}>
        <style jsx>{`
         .colors-section .colors div {
           border-radius: 100%;
           border: 1px solid #333;
           max-height: 200px;
           max-width:200px;
           flex: 1 0 0;
           margin: 8px;
           height:200px;
           width:200px;
           position:relative;
         }
         .colors-section .colors{
           display:flex;
           flex-direction:row;
         }
         .primary-colors .colors {
            position: relative;
            width: 250px;
            display: inline-flex;
            height: 425px;
            margin-top: 20px;
        }

        .primary-colors .colors div {
            height: 200px;
            width: 200px;
            flex: none;
            display: block;
            position: absolute;
        }

        .primary-colors .colors div:nth-child(1) {
            top: 10px;
        }

        .primary-colors .colors div:nth-child(2) {
            top: 40px;
        }

        .primary-colors .colors div:nth-child(3) {
            top: 70px;
        }

        .primary-colors .colors div:nth-child(4) {
            top: 100px;
        }

        .primary-colors .colors div:nth-child(5) {
            top: 130px;
        }

        .primary-colors .colors div:nth-child(6) {
            top: 160px;
        }

        .primary-colors .colors div:nth-child(7) {
            top: 190px;
        }

        .colors h4 {
            position: absolute;
            bottom: -55px;
            width: 100%;
            text-align:center;
        }

        .colors {
            position: relative;
            margin-bottom: 65px;
        }
       `}</style>
        <div className="h1 font-roboto">Primary Colors:</div>
        <span>
          *Primary Colors should never appear on top of anything other than a Neutral Color
          * Only Clean White or a darker/lighter family color may appear on top of any Primary Color
        </span>
        <div className="primary-colors">
          <div className="yellow colors">
            <div className="yellow-one"></div>
            <div className="yellow-two"></div>
            <div className="yellow-three"></div>
            <div className="yellow-four"></div>
            <div className="yellow-five"></div>
            <div className="yellow-six"></div>
            <div className="yellow-seven"></div>
            <h4>Sunflower Yellow</h4>
          </div>
          <div className="blue colors">
            <div className="pacific-blue-one"></div>
            <div className="pacific-blue-two"></div>
            <div className="pacific-blue-three"></div>
            <div className="pacific-blue-four"></div>
            <div className="pacific-blue-five"></div>
            <div className="pacific-blue-six"></div>
            <div className="pacific-blue-seven"></div>
            <h4>Pacific Blue</h4>
          </div>
          <div className="pink colors">
            <div className="pink-sherbert-one"></div>
            <div className="pink-sherbert-two"></div>
            <div className="pink-sherbert-three"></div>
            <div className="pink-sherbert-four"></div>
            <div className="pink-sherbert-five"></div>
            <div className="pink-sherbert-six"></div>
            <div className="pink-sherbert-seven"></div>
            <h4>Pink Sherbert</h4>
          </div>
          <div className="violet-blue colors">
            <div className="violet-blue-one"></div>
            <div className="violet-blue-two"></div>
            <div className="violet-blue-three"></div>
            <div className="violet-blue-four"></div>
            <div className="violet-blue-five"></div>
            <div className="violet-blue-six"></div>
            <div className="violet-blue-seven"></div>
            <h4>Violet-Blue</h4>
          </div>
        </div>

        <div className="h1 font-roboto">Secondary Colors:</div>
        <span>
          *Secondary Colors should never appear on top of anything other than Clean White or Light Grey 1
        </span>

        <div className="secondary-colors colors">
          <div className="alert-red">
            <h4>Alert Red</h4>
          </div>
          <div className="warning-orange">
            <h4>Warning Orange</h4>
          </div>
          <div className="success-green">
            <h4>Success Green</h4>
          </div>
          <div className="info-blue">
            <h4>Info Blue</h4>
          </div>
        </div>

        <div className="h1 font-roboto">Neutral Colors:</div>

        <div className="neutral-colors colors">
          <div className="clean-white">
            <h4>Clean White</h4>
          </div>
          <div className="light-grey-1">
            <h4>Light Grey 1</h4>
          </div>
          <div className="light-grey-2">
            <h4>Light Grey 2</h4>
          </div>
          <div className="medium-grey">
            <h4>Medium Grey</h4>
          </div>
          <div className="grey-blue">
            <h4>Grey Blue</h4>
          </div>
          <div className="dark-grey">
            <h4>Dark Grey</h4>
          </div>
          <div className="dark-grey-blue">
            <h4>Dark Grey Blue</h4>
          </div>
        </div>

      </section>
    )
  }
  render() {
    return(
      <div className='StyleSheet-container' style={{overflow:"scroll"}}>
        <div className='upper-container'>
          <div className='StyleSheet-header' style={this.style.header}>
            <img src="static/images/logo.svg" style={this.style.headerLogo}/>
          </div>
      </div>
      {this._getColorsSection()}
      {this._getTypoSection()}
      {this._getButtonsSection()}
    </div>
    );
  }
}

StyleSheet.displayName = 'StyleSheet';

export default StyleSheet;
