///
/// This is the root reducer for the app
///
import {combineReducers} from 'redux';
import app from './app/app_reducer';
import requests from './requests/requests_reducer';
import componentRequests from './component_requests/component_requests_reducer';
import {routerReducer} from 'react-router-redux';

export const RESET_STATE = 'RESET_STATE';

const reducer = combineReducers({
  app,
  requests,
  componentRequests,
  routing: routerReducer,
});

export default function(state, action){
  if(action.type === RESET_STATE){
    state = undefined;
  }

  return reducer(state, action);
}
