import Cookies from 'universal-cookie';
import * as types from './constants';
import { LOCATION_CHANGE } from 'react-router-redux';
import { getInitialComponentIds, getIsParameterByNameExists, getQueryParamSingle, hasQueryParam } from '../utils';

var userEmail = new Cookies().get('email');
if (typeof(userEmail) === 'undefined' || !userEmail) {
  userEmail = '';
}

let termsAgreed = new Cookies().get('isTermsAgreed');
if (typeof(termsAgreed) === 'undefined' || termsAgreed === 'false') {
  termsAgreed = false;
}

var _isMagicLink = () => {
  let componentsInUrl = getQueryParamSingle('components').split(',');
  let isDefaultComponents = (componentsInUrl.length === 2 && componentsInUrl.indexOf('512') !== -1 && componentsInUrl.indexOf('11021') !== -1);
  let answer = (getQueryParamSingle('components').split(',').length != 0 || getQueryParam('selectedComponentsIds').length != 0 || getQueryParam('selectedComponentsIds1').length || !isDefaultComponents && componentsInUrl.length > 2);
  return answer;
}

let isMagicLink = _isMagicLink();
let compsInUrl = getQueryParamSingle('components').split(',');

const initialState = {
  isBadgeActive:true,
  componentsById: {},
  cbJsons: null,
  bomsByName: {},
  codeFile:null,
  schemePartsByRef: {},
  componentInfoModalIsOpen: false,
  walkthroughModalIsOpen: !termsAgreed,
  CheckoutSecondaryInfoModalIsOpen:false,
  componentInfoModalComponent: null,
  isComponentInfoModalOpenedFromInventory: false,
  newComponentModalIsOpen: false,
  newComponentModalIsSubmitted: false,
  thumbDownModalIsOpen: false,
  selectedComponentsIds:(compsInUrl.length > 0 ) ? compsInUrl : getInitialComponentIds(), 
  selectedBuilderSchemeParts:[],
  sendUpdates: false,
  inventorySearchTerm: '',
  isSearchBarFocused: false,
  isDropOverlayVisible: false,
  fadeCanvasOpacity:true,
  isComponentContextMenuOpen: false,
  isComponentContextMenuClosable: false,
  contextMenuComponent: null,
  componentContextMenuPos: {x: 0, y: 0},
  activeTab: "DESIGN",
  svgHoverIds: {enter: [], exit: []},
  cart: {},
  cartSize: null,
  numOfChangesSinceInitialState : 0,
  isSchematicsTooltipVisible: false,
  schematicsTooltipText: '',
  isSchematicsSettingsMenuOpen: false,
  isSchematicsSettingsMenuClosable: false,
  schematicsZoom: 1,
  ratingsURLKey: '',
  inventoryTypeFilterArray: [],
  isMagicLink,
  isComponentsEditorReady: false,
  curSvgContent: null,
  componentsEditorJsons: null,
  blocksByName: {},
}

export default function(state = initialState, action){
  switch(action.type){
    case (LOCATION_CHANGE):
      return({
        ...state,
        ratingsURLKey: action.payload.pathname + action.payload.search,
      })
    case(types.DEFAULT_CLICK):
      return{
        ...state,
        isComponentContextMenuOpen: false,
        isSchematicsSettingsMenuOpen: false,
        contextMenuComponent: null,
        componentContextMenuPos: {x: 0, y: 0},
        isComponentContextMenuClosable: false,
        isSchematicsSettingsMenuClosable: false,
      }
    case(types.TOGGLE_ACTIVE_TAB):
      return{
        ...state,
        activeTab: action.actionType,
        isBadgeActive:false,
      }
    case(types.SEARCH_INVENTORY):
      return{
        ...state,
        inventorySearchTerm: action.searchTerm,
      }
    case(types.FILTER_INVENTORY_TYPE):
      return{
        ...state,
        inventoryTypeFilterArray: action.inventoryTypeFilterArray,
        inventorySearchTerm: action.searchTerm ? state.inventorySearchTerm : ''
      }
    case(types.UPDATE_COMPONENT_DEF_SUCCESS):
      return{
        ...state,
        componentsEditorJsons: {
          ...state.componentsEditorJsons,
          [action.updatedComp.filename]: action.updatedComp
        }
      }
    case(types.LOAD_CODE_FILE_SUCCESS):
      return{
        ...state,
        codeFile: action.jsonData,
      }
    case(types.LOAD_SVG_FILE_SUCCESS):
      return{
        ...state,
        curSvgContent: action.svgData,
      }
    case(types.LOAD_COMPONENTS_SUCCESS):
      return{
        ...state,
        componentsById: action.jsonData.blocksByIds,
        bomsByName: action.jsonData.parts,
        cbJsons: action.jsonData.cbJsons,
        blocksByName: action.jsonData.blocks,
        isBlockHasCodersForController: action.jsonData.isBlockHasCodersForController,
      }
    case(types.LOAD_CB_JSONS_SUCCESS):
      return{
        ...state,
        componentsEditorJsons: action.cbJsons,
      }
    case(types.WALKTHROUGH_MODAL_OPEN):
      return{
        ...state,
        walkthroughModalIsOpen: true
      }
    case(types.WALKTHROUGH_MODAL_CLOSE):
      return{
        ...state,
        walkthroughModalIsOpen: false,
        fadeCanvasOpacity:true,
      }
    case(types.COMPONENT_INFO_MODAL_OPEN):
      return{
        ...state,
        componentInfoModalIsOpen: true,
        componentInfoModalComponent: action.component,
        isComponentInfoModalOpenedFromInventory: action.isOpenedFromInventory,
      }
    case(types.COMPONENT_INFO_MODAL_CLOSE):
      return{
        ...state,
        componentInfoModalIsOpen: false,
      }
      case(types.SECONDARY_COMPONENT_INFO_MODAL_OPEN):
        return{
          ...state,
          CheckoutSecondaryInfoModalIsOpen: true,
          CheckoutSecondaryInfoModalComponent: action.component
        }
      case(types.SECONDARY_COMPONENT_INFO_MODAL_CLOSE):
        return{
          ...state,
          CheckoutSecondaryInfoModalIsOpen: false,
          CheckoutSecondaryInfoModalComponent: null
        }
    case(types.NEW_COMPONENT_MODAL_OPEN):
      return{
        ...state,
        newComponentModalIsOpen: true
      }
    case(types.NEW_COMPONENT_MODAL_CLOSE):
      return{
        ...state,
        newComponentModalIsOpen: false,
        newComponentModalIsSubmitted: false
      }
    case(types.THUMB_DOWN_MODAL_OPEN):
      return{
        ...state,
        thumbDownModalIsOpen: true,
      }
    case(types.THUMB_DOWN_MODAL_CLOSE):
      return{
        ...state,
        thumbDownModalIsOpen: false,
      }
    case(types.NEW_COMPONENT_MODAL_SUBMIT_REQUEST):
      return{
        ...state,
        newComponentModalIsSubmitted: true,
      }
    case(types.REQUEST_FIELD_CHANGED):
      return{
        ...state,
        [action.field]: action.value,
      }
    case(types.SEND_UPDATES_CHANGED):
      return{
        ...state,
        sendUpdates: action.sendUpdates,
      }
    case(types.COMPONENT_SWITCHED):
    case(types.COMPONENT_ADDED):
    case(types.COMPONENT_REMOVED):
    case(types.COMPONENTS_SELECTED):
      return{
        ...state,
        isMagicLink:(action.newSelectedComponentsIds.toString() === state.selectedComponentsIds.toString()) ? true : false,
        selectedComponentsIds: action.newSelectedComponentsIds,
        isSearchBarFocused: false,
        numOfChangesSinceInitialState : (state.numOfChangesSinceInitialState + 1),
        isBadgeActive:true,
        fadeCanvasOpacity:(state.walkthroughModalIsOpen) ? true : false,
      }
    case(types.SEARCH_BAR_FOCUS_CHANGED):
      return{
        ...state,
        isSearchBarFocused: action.isFocused,
      }
    case(types.INVENTORY_COMPONENT_DRAG_START):
      return{
        ...state,
        isDropOverlayVisible: true
      }
    case(types.INVENTORY_COMPONENT_DRAG_END):
      return{
        ...state,
        isDropOverlayVisible: false,
      }
    case(types.COMPONENT_CONTEXT_MENU_OPEN):
      return{
        ...state,
        isComponentContextMenuOpen: true,
        contextMenuComponent: action.component,
        componentContextMenuPos: action.componentContextMenuPos,
      }
    case(types.COMPONENT_CONTEXT_MENU_CLOSE):
      return{
        ...state,
        isComponentContextMenuOpen: false,
        contextMenuComponent: null,
        contextMenuPos: {x: 0, y: 0},
        isComponentContextMenuClosable: false,
      }
    case(types.COMPONENT_CONTEXT_MENU_IS_CLOSABLE):
      return{
        ...state,
        isComponentContextMenuClosable: true,
      }
    case(types.SCHEMATICS_TOOLTIP_UPDATED):
      return{
        ...state,
        isSchematicsTooltipVisible: action.isVisible,
        schematicsTooltipText: action.text,
      }
    case(types.SCHEMATICS_ZOOM):
      return{
        ...state,
        schematicsZoom: action.zoom,
      }
    case(types.SCHEMATICS_SETTINGS_MENU_TOGGLE):
      return{
        ...state,
        isSchematicsSettingsMenuOpen: action.isOpen,
        isSchematicsSettingsMenuClosable: (!action.isOpen ? false : state.isSchematicsSettingsMenuClosable)
      }
    case(types.SCHEMATICS_SETTINGS_MENU_IS_CLOSABLE):
      return{
        ...state,
        isSchematicsSettingsMenuClosable: true,
      }
    case(types.BOM_HOVER_ENTER):
      return{
        ...state,
        svgHoverIds: {
          enter: action.svgIds,
          exit: state.svgHoverIds.exit.filter( function( el ) {
                  return !action.svgIds.includes( el );
                })
        }
      }
    case(types.BOM_HOVER_EXIT):
      return{
        ...state,
        svgHoverIds: {
          enter: state.svgHoverIds.enter.filter( function( el ) {
                  return !action.svgIds.includes( el );
                }),
          exit: action.svgIds,
        }
      }
    case(types.ADDED_TO_CART):
    case(types.LOADED_CART):
      return{
        ...state,
        cart: action.cart,
        cartSize: action.cartSize,
      }
    case(types.SEED_COMPONENTS_SUCCESS):
      return{
        ...state,
        isComponentsEditorReady: true,
      }
    default:
      return state;
  }
}
