import React from 'react';

const IconAddCircle = ()=>{
  return(
    <svg className='icon icon-ins' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    
      <g id='ins-screw'>
        <line id="_Path_" y1="15" x2="32" y2="15"/>
      </g>

      <g id='ins-head'>
        <rect id="_Rectangle_" x="4.23" y="4" width="24" height="24" rx="4" ry="4"/>
      </g>

      <g id="ins-eyes">
        <circle id="_Path_2" cx="20.44" cy="14.38" r="2.14"/>
        <circle id="_Path_3" cx="11.89" cy="14.38" r="2.51"/>
      </g>

      <g id='ins-lines'>
        <line id="_Path_4" x1="8.65" y1="21.42" x2="22.52" y2="22.63"/>
        <line id="_Path_5" x1="7.98" y1="12.55" x2="24.67" y2="11.17"/>
      </g>

      <g id='ins-ears'>
        <path id="_Path_6" d="M4.23,11.13a4.34,4.34,0,0,0,0,7.73V11.13Z"/>
        <path id="_Path_7" d="M28.23,18.86a4.34,4.34,0,0,0,0-7.73v7.73Z"/>
      </g>


    </svg>
  )
}

export default IconAddCircle;
