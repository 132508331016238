import React from 'react';

const MaterialIcon = ({name, className})=>{
  return <i className={`material-icons icon ${className}`}>{name}</i>
}
MaterialIcon.displayName = "MaterialIcon";
MaterialIcon.propTypes = {
  name: React.PropTypes.string.isRequired,
  className: React.PropTypes.string,
}

export default MaterialIcon;
