///
/// Redux.js store init
///

import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import createLogger from 'redux-logger';
import rootReducer from './reducers';

let middleware = [thunk];

if(process.env.NODE_ENV !== 'production'){
  const logger = createLogger();
  middleware.push(logger);
  console.error('Warning: Logging redux in development, make sure to set NODE_ENV to `production` on deploy');
}

export default function configureStore(initialState){
  return createStore(
    rootReducer,
    initialState,
    applyMiddleware(...middleware)
  )
}
