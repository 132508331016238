import React from 'react';

const CheckBox = (props) => {
  return(
  <div className="checkbox">
    <label>
      <input type="checkbox" onChange={(e) => props.onChange(e.target.checked)} checked={props.isChecked}/>
      <span className="checkbox-material">
        <span className="check" />
      </span> 
    </label>
    {props.children}
  </div>
  );
}
CheckBox.displayName = "CheckBox";
CheckBox.propTypes = {
  isChecked: React.PropTypes.bool.isRequired,
  onChange: React.PropTypes.func.isRequired,
  children: React.PropTypes.element,
}

export default CheckBox;
