/**
 * Created by roboamir on 01/08/16.
 */
///
/// Loads a static page's content from the server
///
import { loadStaticPage } from '../../api';

export default function(path, requestType, failureType, successType){

  // Fetch static page content
  return (dispatch) => {
    dispatch({type: requestType})
    return loadStaticPage(path)
      .then((staticPageContent)=>{
        dispatch({type: successType, staticPageContent})
      })
      .catch((error) => {
        dispatch({type: failureType, error});
      });
  }
}
