import React from 'react';
import MaterialIcon from '../robo_icon';

const Btn = (({
  label,
  onClick,
  isEnabled,
  type,
  onMouseLeave,
  icon,
  className,
  iconClassName,
  id,
  children,
  isStretch,
})=>{
  let typography = {
    "primary":"text-app-btn",
    "secondary":"text-app-btn-2"
  };
  let fontStyleClass = (type.indexOf("primary") !== -1) ? typography.primary : typography.secondary;
  let iconElement = icon ? <MaterialIcon name={icon} className={iconClassName} /> : null;
  let btnLabel = (label)  ? <span>{label}</span> : null;
  let stretchBtnClass = (isStretch) ? "btn-stretch" : " ";
  return(
    <button className={`robo-btn ${stretchBtnClass} ${className} ${type} ${fontStyleClass}`} id={id}
            disabled={!isEnabled}
            onMouseLeave={onMouseLeave}
            onClick={onClick}>
      {iconElement}
      {btnLabel}
      {children}
    </button>);
});

Btn.displayName = "Button";
Btn.propTypes = {
  label: React.PropTypes.string.isRequired,
  onClick: React.PropTypes.func,
  onMouseLeave: React.PropTypes.func,
  isEnabled: React.PropTypes.bool,
  isStretch: React.PropTypes.bool,
  type: React.PropTypes.string,
  icon: React.PropTypes.string,
  className: React.PropTypes.string,
  iconClassName: React.PropTypes.string,
  id: React.PropTypes.string,
  children: React.PropTypes.element,
}
Btn.defaultProps = {
  type: 'primary',
  isEnabled: true,
  className: '',
  iconClassName: '',
}

export default Btn;
