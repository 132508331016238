import { connect } from 'react-redux';
import headerVideoClose from '../actions/header_video_close';
import IFrameVideoModal from '../components/iframe_video_modal';

const mapStateToProps = ({app}) => {
  return {
    isOpen: app.headerModalIsOpen,
    location: 'https://www.youtube.com/embed/mPmrB0HklTA?autoplay=1'
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onClose: ()=>{
      dispatch(headerVideoClose());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IFrameVideoModal);
