import { FILTER_INVENTORY_TYPE, TOGGLE_ACTIVE_TAB } from '../constants';
import focusSearchBar from './focus_search_bar';

export default function(inventoryTypeFilterArray, searchTerm = null) {
	return (dispatch) => {
		dispatch({
				type: TOGGLE_ACTIVE_TAB,
				actionType: "DESIGN",
		});

		// First lets focus on the search bar tab
		dispatch(focusSearchBar(true));

		if (!Array.isArray(inventoryTypeFilterArray)) {
			inventoryTypeFilterArray = [inventoryTypeFilterArray];
		}

		dispatch({
    		type: FILTER_INVENTORY_TYPE,
    		inventoryTypeFilterArray,
    		searchTerm,
  		});
	}
}
