/**
 * Created by roboamir on 10/08/16.
 */
///
/// Tries to add a new component by validating first
///
import { generateSolution, checkOwnerStatus } from '../../../api';
import * as types from '../../constants';
import { SHOW_DESKTOP_LOADER } from '../../../app/constants';
import showNotification, { NotificationTypes } from '../../../app/actions/show_notification';
import { getComponentsById, getSelectedComponentsIds } from '../../selectors';
import { isEmpty } from 'lodash';
import { browserHistory } from 'react-router';
import { getInitialComponentIds } from '../../../utils.js';
import Cookies from 'universal-cookie';

export default function(dispatch, getState, newSelectedComponentsIds, newComponent, actionType) {
    dispatch({ type: types.VALIDATE_COMPONENT_REQUEST });

    let isInitial = (getState().app.validationData == null);

    // Setting a timeout that if happens it will show the spinner cuz the user is waiting and needs to know what happens by
    // that time.. if the validate ends before the timeout happens, it will be cleared and no loading spinner will be shown
    const beginDesktopLoaderTimeout = setTimeout(() => dispatch({ type: SHOW_DESKTOP_LOADER, isShow: true }), 750);

    let validationTime = new Date().getTime();

    let _onGenerateSuccess = (result) => {
        try {
            clearTimeout(beginDesktopLoaderTimeout);

            let postSelectedComponentsIds = [];

            // Check if there were any component swaps
            if (result.output && !isEmpty(result.output.swappedComponents)) {
                postSelectedComponentsIds = _swapComponents(result.output.swappedComponents, newSelectedComponentsIds, dispatch, getState(), newComponent);
            }
            else {
              // Return the new selected Ids without performing switches
              postSelectedComponentsIds = newSelectedComponentsIds;
            }

            dispatch({ type: types.VALIDATE_COMPONENT_SUCCESS, data: result });
            dispatch({ type: types.TOGGLE_ACTIVE_TAB, actionType: "DESIGN" });

            analyticsSimple('Validation Finished', 'Timers', 'Validation Finished',
                (new Date().getTime() - validationTime) / 1000, true);

            let sortedComps = postSelectedComponentsIds.sort((a, b) => a - b).join(',');
            let pathname = '/app';

            let hasPreview = (window.location.href.indexOf('u=') != -1) ? true : false;
            let newUrl = (hasPreview) ? `?components=${sortedComps}&u=${window.username}` : `?components=${sortedComps}`;

            browserHistory.push({
                pathname,
                search: newUrl
            });

            let cookieComponents = 'components';

            new Cookies().set(cookieComponents, postSelectedComponentsIds.join(','));

            // Dispatch an added component action
            dispatch({
                type: actionType || types.COMPONENTS_SELECTED, // That's the default
                newSelectedComponentsIds: postSelectedComponentsIds
            });

            return postSelectedComponentsIds;
        } catch (e) {
            console.log("Error showing a successfull validation - ");
            console.log(e);
        }
    };

    let _onGenerateFailed = (error) => {
        clearTimeout(beginDesktopLoaderTimeout);

        analyticsSimple('Validation Failed', 'Modify Component',
            'Validation Failed', newSelectedComponentsIds, true, true);

        analyticsSimple('Validation Failed Finished', 'Timers', 'Validation Finished',
            (validationTime - new Date().getTime()) / 1000, true);

        // If we have a 'solution not found' error
        if (error.response && error.response.status === 404) {
            dispatch(showNotification(NotificationTypes.NOTIFICATION_DANGER_TYPE, `There are no more available pins on the controller for ${newComponent.name}. Try removing other components or changing the controller`, true, error.details));
        } else if (error.response && error.response.status === 503) {
            dispatch(showNotification(NotificationTypes.NOTIFICATION_DANGER_TYPE, `Oops.. your request timed out. please try again`, true, error.json.details));
        } else if (error.response && error.response.status === 401) {
            dispatch(showNotification(NotificationTypes.NOTIFICATION_DANGER_TYPE, `Oops.. Looks like our server couldn't find a solution for this combination`, true, error.json.details));
        } else if (error.response && error.response.status === 320) {
            dispatch(showNotification(NotificationTypes.NOTIFICATION_DANGER_TYPE, `Wait.. there are no components for this user yet!`, true, error.json.details));
        } else if (error.response && error.response.status === 321) {
            dispatch(showNotification(NotificationTypes.NOTIFICATION_DANGER_TYPE, `Aww.. looks like we had a problem preparing your components!`, true, error.json.details));
        } else if (error.response && error.response.status === 322) {
            dispatch(showNotification(NotificationTypes.NOTIFICATION_DANGER_TYPE, `Hey, your components are being prepared.. Waiting for them to finish, hang on!`, false, error.json.details));
            dispatch({ type: SHOW_DESKTOP_LOADER, isShow: true })
            setTimeout(()=> {
                return checkOwnerStatus()
                .then(()=> window.location.reload())
                .catch(_onGenerateFailed);
            }, 1000);
        } else {
            dispatch(showNotification(NotificationTypes.NOTIFICATION_DANGER_TYPE, `Oops.. something went wrong. Please try again.`, true, error.json.details));
        }

        if (error.response && error.response.status !== 322) {
            dispatch({ type: types.VALIDATE_COMPONENT_FAILURE, error });
        }
    }

    return generateSolution(newSelectedComponentsIds, window.userId)
        .then(_onGenerateSuccess)
        .catch((error) => {
            // If this is an *initial* validation that failed
            if (isInitial && window.username === window.mainUsername) {
                analyticsSimple('Initial Validation Failed', 'Initial Validation',
                    'Validation Failed', newSelectedComponentsIds, true);

                newSelectedComponentsIds = getInitialComponentIds();

                // We should just load an empty validation run instead
                return generateSolution(newSelectedComponentsIds, window.userId)
                    .then(_onGenerateSuccess)
                    // Wow even that doesn't work - meaning validation doesn't work anyway
                    .catch(_onGenerateFailed);
            }

            _onGenerateFailed(error);

        });
}

/**
 * Swaps components
 * @param  {Object} swappedComponents     Object with a key 'swappedComponents' which contains a key:value of swapToId:swapFromId
 */
function _swapComponents(swappedComponents, selectedComponentsIds, dispatch, state, newComponent) {
    let allComponents = getComponentsById(state);
    let swappedSelectedComponentsIds = [...selectedComponentsIds];

    // Each key is an id to switch to, and is also a key in a key-value where the value is the id to switch from
    for (let key of Object.keys(swappedComponents)) {
        let swappedFrom = allComponents[swappedComponents[key]];
        let beautifullKey = key.replace('!', '').split('_')[0];
        let swappedTo = allComponents[beautifullKey];

        if (!swappedTo) {
            throw new Error(`Solver requested a swap of an unknown item (id: ${beautifullKey})`);
        } else if (!swappedFrom) {
            throw new Error(`Solver requested a swap of an unknown item (id: ${beautifullKey})`);
        }

        let swappedIndex = selectedComponentsIds.indexOf(swappedFrom.blockId);

        if (swappedIndex === -1) {
            throw new Error(`Solver requested a swap of an item that is not present at the selected items (id: ${swappedFrom.blockId})`);
        }

        if (typeof newComponent !== 'undefined' && newComponent.category.includes('power')) {
            // We chose bad power supply. No change will be made, returning old selected components
            dispatch(showNotification(NotificationTypes.NOTIFICATION_DANGER_TYPE, `This power source cannot power your circuit.`, true));
        }
        else {
            // Just show a notification for now (If we got here because of an added component)
            if (typeof newComponent !== 'undefined') {
                if (swappedFrom.category.includes('controller')) {
                    dispatch(showNotification(NotificationTypes.NOTIFICATION_GENERAL_TYPE,
                        `Your controller was replaced to ${swappedTo.name} as the previous didn't have enough pins for ${newComponent.name}.`, true));
                }
                // When the power supply is swapped it can be either because it isn't compatible with the new controller,
                // Or because it doesn't give enough power to the new component
                else if (swappedFrom.category.includes('power')) {
                    if (newComponent.category.includes('controller')) {
                        dispatch(showNotification(NotificationTypes.NOTIFICATION_GENERAL_TYPE,
                            `Your power source was replaced to ${swappedTo.name} because it was not compatible with ${newComponent.name}.`, true));
                    } else {
                        dispatch(showNotification(NotificationTypes.NOTIFICATION_GENERAL_TYPE,
                            `Your power source was replaced to ${swappedTo.name} to supply enough power for ${newComponent.name}.`, true));
                    }
                }
            }
        }

        // Swapping
        swappedSelectedComponentsIds[swappedIndex] = swappedTo.blockId;
        console.log('swap 1 : ' + swappedSelectedComponentsIds)
    }
    console.log('swap 2 : ' + swappedSelectedComponentsIds)
    return swappedSelectedComponentsIds;
}