import React from 'react';
import ReactTooltip from 'react-tooltip'
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { ON_DESKTOP_MQ } from '../../config';
import { ButtonRaised , ButtonFlat } from '../../app/components/form_controls';
import Image from '../../app/components/image';
import MaterialIcon from '../../app/components/material_icon';
import Modal from '../../app/components/modal';

// Actions
import CheckoutSecondaryInfoModalClose from '../actions/checkout_secondary_info_modal_close';
// Selectors
import { getIsUserEmailValid } from '../selectors';

class CheckoutSecondaryInfoModal extends React.Component{
  constructor(props) {
    super(props);

    this._onMoreInfoClick = this._onMoreInfoClick.bind(this);
  }

  componentDidUpdate() {
    let moreInfoInnerHtmlBtn = document.getElementById("more-info");
    if (moreInfoInnerHtmlBtn) {
      moreInfoInnerHtmlBtn.onclick = this._onMoreInfoClick;
    }
  }

  _getRequiredBy(){
      var res = "";
      var requiredBy = this.props.component.requiredBy;
      if(requiredBy && requiredBy.length != 0){
        res += "Required By: " + requiredBy;
      }
      return res;
  }

  _renderModal(){
    let description = '';

    if (this.props.component) {
      let part = this.props.bomsByName[this.props.component.name];
      var link = (part.bom[0].link) ? part.bom[0].link : this.props.component.link;
      // Putting the 'more info' inside the description, before the last tag closing </>
      description = this.props.component.desc;
      let pos = description.lastIndexOf('</');

      let moreInfo =
          ` </p><p><a id='more-info' href=${link} target='_blank' rel='nofollow' class='more-info text-app-info'>`
        +   `Technical Info from Distributor</a></p>`;

      if (pos === -1) {
        description += ' ' + moreInfo;
      }
      else {
        description = description.substr(0, pos) + moreInfo + description.substr(pos);
      }
    }

    return(
      <Modal modalClass="checkout-secondary-info-modal"
             isOpen={this.props.isOpen}
             onClose={this.props.onClose}
             fade={true}>
        <div className="modal-dialog modal-md">
          <div className="modal-content">

            <div className="modal-header">
              <button id="modal-close" type="button" className="close" data-dismiss="modal" aria-label="Close">
                <MaterialIcon name="close" />
              </button>
            </div>
            { (this.props.component) ?
              <div>
                  <div className="modal-body">
                      <div className="component-info-modal-image">
                      {this._getCompImage()}
                      </div>

                      <div className="component-info-modal-header">
                        <div className="component-info-modal-header-content">
                          <h3 className="text-app-header-2">{this.props.component.displayName}</h3>
                        </div>
                      </div>

                      <div className="component-info-modal-description text-app-body-2" dangerouslySetInnerHTML={{__html: description}}>
                      </div>

                      <div className="required-by text-app-sub-header-2">{this._getRequiredBy()}</div>
                    </div>
                  </div>

              : null }
          </div>
        </div>
      </Modal>
    );
  }

  _getCompImage(){
    const placeholderSrc = "/static/images/component-default-image.svg";
    let imgSrc = (this.props.component.image) ? this.props.component.image : placeholderSrc;
    return (<Image className="component-info-modal-image" src={imgSrc} alt={this.props.component.name} /> );
  }

  _onMoreInfoClick() {
    analyticsSimple("Modal More Info", "Modal", "More Info",
                    "Modal More Info " + this.props.component.name, true);
  }

  render(){
    return this._renderModal();
  }
}

CheckoutSecondaryInfoModal.displayName = 'CheckoutSecondaryInfoModal';
CheckoutSecondaryInfoModal.propTypes = {
  isOpen: React.PropTypes.bool.isRequired,
  onClose: React.PropTypes.func
}

const mapStateToProps = (state)=> {
  return{
    isOpen: state.requests.CheckoutSecondaryInfoModalIsOpen,
    component:state.requests.CheckoutSecondaryInfoModalComponent,
    bomsByName: state.requests.bomsByName,
  }
}

const mapDispatchToProps = {
    onClose: CheckoutSecondaryInfoModalClose,
}

export default connect(mapStateToProps,mapDispatchToProps)(CheckoutSecondaryInfoModal);
