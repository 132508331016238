import React from 'react';
import { connect } from 'react-redux';

class ComponentStatus extends React.Component {
	render() {
		let checkedImage =
			this.props.isBlue ? '/static/images/check-blue.svg'
							  : '/static/images/check-color.svg';
		let uncheckedImage =
			this.props.isBlue ? '/static/images/x-blue.svg'
							  : '/static/images/x-color.svg';
		let alert = this.props.isBlue ? '/static/images/alert-blue.svg'
									  : '/static/images/alert-color.svg';
		let tooltipClass = (!this.props.modalView) ? "text-app-tooltip" : "text-app-tooltip-dark";
		return(
			<div className={`tooltip-status-${this.props.align}`}>
				{!this.props.isBlockHasCodersForController ? null :
					<div className='tooltip-status-cell'>
						<img src={this.props.isBlockHasCodersForController[
									`${this.props.component.name}-${this.props.controllerName}`] ? checkedImage : uncheckedImage}
							 className='tooltip-image'/>
						<span className={`${tooltipClass}`}>Test code</span>
					</div>
				}
				<div className='tooltip-status-cell'>
					<img src={this.props.component.app.indicators.verified ? checkedImage : uncheckedImage}
						 className='tooltip-image'/>
					 <span className={`${tooltipClass}`}>Verified</span>
				</div>
				
				{this.props.component.app.indicators.solder ?
					<div className='tooltip-status-cell'>
						<img src={alert} className='tooltip-image'/>
						<span className={`${tooltipClass}`}>Soldering Required</span>
					</div>
				: null}
			</div>
		);
	}
}

ComponentStatus.propTypes = {
  component: React.PropTypes.object.isRequired,
  controllerName: React.PropTypes.string.isRequired,
  align: React.PropTypes.oneOf(['vertical', 'horizontal']),
  modalView: React.PropTypes.bool,
  isBlue: React.PropTypes.bool,
}

const mapStateToProps = (state) => {
  return{
    isBlockHasCodersForController: state.requests.isBlockHasCodersForController,
  }
}

ComponentStatus.displayName = 'ComponentStatus';

export default connect(mapStateToProps, null)(ComponentStatus);
