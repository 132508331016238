import React from 'react';
import { connect } from 'react-redux';
import openThumbDownModal from '../actions/thumb_down_modal_open';

class Ratings extends React.Component{
  _onToggleThumb(isThumbUpBtn) {
    let enabled = (isThumbUpBtn ? 'up' : 'down');

    if (this.curRating === enabled) {
      // If so - putting off
      window.localStorage.setItem(this.ratingsKey, '');
    }
    else {
      // Putting on - either up or down
      window.localStorage.setItem(this.ratingsKey, enabled);

      analyticsSimple('Rating', 'Rating', 'Rating ' + enabled,
                      'Rating ' + this.props.tabName.toLowerCase(), true);

      if (enabled === 'down') {
        this.props.onThumbDownClick();
      }
    }

    this.forceUpdate();
  }

  _getSectionAndUrlKey() {
    return `ratings-${this.props.tabName}-${window.location.pathname}-${window.location.search}`;
  }

  render(){
    this.ratingsKey = this.props.tabName + '-' + this.props.ratingsURLKey;
    this.ratingsText = 'Rate this ' + (this.props.tabName === 'CODE' ? 'code' : 'schematic');
    this.curRating = window.localStorage.getItem(this.ratingsKey, '');

    return(
      <div className='ratings-container'>
        <div className='ratings-desc text-app-body-2-bolder'>
          {this.ratingsText}
        </div>
        <i className={"material-icons thumb " + 
                      (this.curRating === 'up' ? 'up' : '' )}
            onClick={()=>this._onToggleThumb(true)}>
          thumb_up
        </i>
        <i className={"material-icons thumb " + 
                      (this.curRating === 'down' ? 'down' : '' )}
            onClick={()=>this._onToggleThumb(false)}>
          thumb_down
        </i>
      </div>
    );
  }
}

Ratings.displayName = 'Ratings';

Ratings.propTypes = {
  tabName: React.PropTypes.string,
  ratingsURLKey: React.PropTypes.string,
  onThumbDownClick: React.PropTypes.func,
};

const mapDispatchToProps = {
  onThumbDownClick: openThumbDownModal,
}

const mapStateToProps = (state) => {
  return({
    tabName: state.requests.activeTab,
    ratingsURLKey: state.requests.ratingsURLKey,
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(Ratings);
