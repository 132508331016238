import React from 'react';
import {Button} from '.';

const ButtonRaised = ({className, ...buttonProps}) => {
  return(
  	<Button {...buttonProps} className={`${className} btn-raised btn-success`} />
  );
};

ButtonRaised.displayName = "ButtonRaised";
ButtonRaised.propTypes = {
  className: React.PropTypes.string,
  onClick:React.PropTypes.func
}
ButtonRaised.defaultProps = {
  className: 'default',
}

export default ButtonRaised;
