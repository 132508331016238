import React from 'react';
import { connect } from 'react-redux';
import MaterialIcon from '../../app/components/material_icon';
import Modal from '../../app/components/modal';
import componentInfoModalClose from '../actions/component_info_modal_close';
import Image from '../../app/components/image';
import addComponent from '../actions/add_component';
import removeComponent from '../actions/remove_component';
import switchComponent from '../actions/switch_component';
import editComponent from '../../component_requests/actions/open_edit_part_modal';
import filterInventoryType from '../actions/filter_inventory_type';
import { getSelectedComponents, getSelectedComponentsGroupedByType } from '../selectors';
import { Btn } from '../../app/components/form_controls';
import { SWITCHABLE_COMPONENT_TYPES } from '../../config';
import { getCategories, firstOrDefault, getInitialControllerName } from '../../utils';
import ComponentStatus from '../components/component_status';

class ComponentInfoModal extends React.Component{
  constructor(props) {
    super(props);

    this._onAddClick = this._onAddClick.bind(this);
    this._onRemoveClick = this._onRemoveClick.bind(this);
    this._onMoreInfoClick = this._onMoreInfoClick.bind(this);
    this._onSuggestSwitchClick = this._onSuggestSwitchClick.bind(this);
    this._onSwitchClick = this._onSwitchClick.bind(this);
  }

  componentDidUpdate() {
    let moreInfoInnerHtmlBtn = document.getElementById("more-info");
    if (moreInfoInnerHtmlBtn) {
      moreInfoInnerHtmlBtn.onclick = this._onMoreInfoClick;
    }
  }

  _renderModal(onActionClick, actionButtonIconName, actionButtonLabel, onSecondActionClick){
    let description;
    let categories = null;

    // Putting the 'more info' inside the description, before the last tag closing </>
    if (this.props.component) {
      let bom = this.props.bomsByName[this.props.component.name];
      var link = (bom && bom.bom && bom.bom[0]) ? bom.bom[0].link : this.props.component.link;
      description = this.props.component.app.desc;
      let pos = description.lastIndexOf('</');

      let moreInfo =
          ` </p><p><a id='more-info ' href=${link} target='_blank' rel='nofollow' class='text-app-info'>`
        +   `Technical Info from Distributor</a></p>`;

      description = description.substr(0, pos) + moreInfo + description.substr(pos);

      categories = getCategories(this.props.component);
    }

    let controllerName = (this.props.selectedComponentsByType.controller ?
                            this.props.selectedComponentsByType.controller[0].name
                          : getInitialControllerName());

    return(
      <Modal modalClass="component-info-modal"
             isOpen={this.props.isOpen}
             onClose={this.props.onClose}
             fade={true}>
        <div className="modal-dialog modal-md">
          {(this.props.component) ?
            <div className="modal-content">

              <div className="modal-header">
                <button id="modal-close" type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <MaterialIcon name="close" />
                </button>
              </div>
              <div className="modal-body">
                <div className="component-info-modal-image">
                {this._getCompImage()}
                  <small className='attribution'>{this.props.component.imageAttribution}</small>
                </div>
                <div className="component-info-modal-header">
                  <div className="component-info-modal-header-content">
                    <h3 className="text-app-header-2">{this.props.component.app.appName}</h3>
                    <ComponentStatus modalView={true} component={this.props.component} align='horizontal' 
                                      isBlue={false} controllerName={controllerName}/>
                  </div>
                </div>
                <div className="component-info-modal-description text-app-body-2" dangerouslySetInnerHTML={{__html: description}}>
                </div>
              </div>
              <div className="modal-footer">
                { actionButtonIconName ?
                  <div className="action-btn-container" data-dismiss="modal" aria-label="Close">
                    <Btn type="secondary-outline" className="default action-btn" icon={actionButtonIconName} label={actionButtonLabel} onClick={()=>onActionClick(this.props.component)} />
                  </div>
                  : null}

                  {onSecondActionClick && this.props.component ?
                    <div className="action-btn-container" data-dismiss="modal" aria-label="Close">
                      <Btn label="Remove" type="secondary-outline-gray" icon="delete" className="action-btn" onClick={()=>onSecondActionClick(this.props.component)} />
                    </div>
                    : null}
                    <div className="action-btn-container" data-dismiss="modal" aria-label="Close">
                      <Btn label="Edit" type="secondary-outline-yellow" onClick={ () => this.props.onEditClick('modal-info') }icon="edit" className="action-btn" />
                    </div>
                    <a href={link} target="_blank" className="action-btn-container buy-container" onClick={()=>this._onBuyClick()} aria-label="Close">
                      <Btn label="Buy" type="secondary-outline-yellow" icon="shopping_cart" className="action-btn" />
                    </a>
              </div>
            </div>
          : null }
        </div>
      </Modal>
    );
  }

  _getCompImage(){
    const placeholderSrc = "/static/images/component-default-image.svg";
    let imgSrc = (!this.props.component.app.image) ? placeholderSrc : this.props.component.app.image;
    return (<Image className="component-info-modal-image" src={imgSrc} alt={this.props.component.app.appName} /> );
  }

  _onAddClick() {
    analyticsSimple("Modal Add", "Modal", "Add",
                    "Modal Add " + this.props.component.app.appName, true);

    this.props.onAddClick(this.props.component);
  }


  _onBuyClick() {
    analyticsSimple("Modal Buy", "Modal", "Buy", this.props.component.app.appName, true);
  }


  _onRemoveClick() {
    analyticsSimple("Modal Remove", "Modal", "Remove",
                    "Modal Remove " + this.props.component.app.appName, true);

    this.props.onRemoveClick(this.props.component);
  }

  _onMoreInfoClick() {
    analyticsSimple("Modal More Info", "Modal", "More Info",
                    "Modal More Info " + this.props.component.app.appName, true);
  }

  _onSuggestSwitchClick() {
    analyticsSimple("Modal Suggest Swap", "Modal", "More Info",
                    "Modal Suggest Swap " + this.props.component.app.appName, true);

    this.props.onSuggestSwitchClick(getCategories(this.props.component), '');
  }

  _onSwitchClick() {
    analyticsSimple("Modal Swap", "Modal", "More Info",
                    "Modal Swap " + this.props.component.app.appName, true);

    this.props.onSwitchClick(this.props.component);
  }

  render(){
    if (!this.props.component) {
      return this._renderModal(()=>{}, null);
    }
    let categories = getCategories(this.props.component);
    var isCompIsSelected = false;
    
    this.props.selectedComps.forEach( (item) => {
      if(this.props.component.blockId === item.blockId){
        isCompIsSelected = true;
      }
    })

    let isConnectivity = (categories.includes("connectivity"));
    let isCtrlr = (categories.includes("controller"));
    if(!this.props.isOpenedFromInventory){
      return this._renderModal(()=>{}, null);
    }
    // If the component is switchable, show a 'switch' button
    if(SWITCHABLE_COMPONENT_TYPES.some((switchable)=> categories.includes(switchable))) {
      if (this.props.isOpenedFromInventory) {
        // Avoid trying to switch a component with a component we already have on the circuit
        if (isCompIsSelected) {
          return this._renderModal(()=>{}, null);
        }
        // When swapping from the inventory we actually switch
        return this._renderModal(this._onSwitchClick, 'swap_horiz', 'Swap');
      }

      // When swapping not from the inventory we just suggest a switch
      return this._renderModal(this._onSuggestSwitchClick, 'swap_horiz', 'Swap');
    }
    else if (isConnectivity) {
      if(isCompIsSelected){
        return this._renderModal(this._onRemoveClick, 'delete', 'Remove');
      }

      return this._renderModal(this._onAddClick, 'library_add', 'Add');
    }
    else {
      if(isCompIsSelected){
        return this._renderModal(this._onAddClick, 'library_add', 'Add' , this._onRemoveClick);
      }

      return this._renderModal(this._onAddClick, 'library_add', 'Add');
    }
  }
}

ComponentInfoModal.displayName = 'ComponentInfoModal';
ComponentInfoModal.propTypes = {
  isOpen: React.PropTypes.bool.isRequired,
  component: React.PropTypes.object,
  onClose: React.PropTypes.func,
  onAddClick: React.PropTypes.func,
  onRemoveClick: React.PropTypes.func,
  onSwitchClick: React.PropTypes.func,
  onSuggestSwitchClick: React.PropTypes.func,
  isOpenedFromInventory: React.PropTypes.bool,
  selectedComps: React.PropTypes.array,
  selectedComponentsByType: React.PropTypes.object,
}

const mapStateToProps = (state)=>{
  return{
    isOpen: state.requests.componentInfoModalIsOpen,
    component: state.requests.componentInfoModalComponent,
    isOpenedFromInventory: state.requests.isComponentInfoModalOpenedFromInventory,
    actionType: state.requests.componentInfoModalActionType,
    selectedComps : getSelectedComponents(state),
    selectedComponentsByType: getSelectedComponentsGroupedByType(state),
    bomsByName: state.requests.bomsByName,
  }
}

const mapDispatchToProps = {
  onClose: componentInfoModalClose,
  onAddClick: addComponent,
  onRemoveClick: removeComponent,
  onSwitchClick: switchComponent,
  onSuggestSwitchClick: filterInventoryType,
  onEditClick: editComponent,
}

export default connect(mapStateToProps,mapDispatchToProps)(ComponentInfoModal);
