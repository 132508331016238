import React from 'react';
import { connect } from 'react-redux';
import MaterialIcon from '../../app/components/material_icon';
import Modal from '../../app/components/modal';
import { Input } from '../../app/components/form_controls';
import newComponentModalClose from '../actions/new_component_modal_close';
import newComponentModalSubmit from '../actions/new_component_modal_submit';
import { isEmailValid } from '../../utils';

// TODO: This component still has it's own state.. We should move it to redux
class NewComponentModal extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      url: "",
      email: "",
    };
  }

  componentWillReceiveProps(){
    this.state = {
      url: "",
      email: "",
    };
  }

  _handleUrlChange(value){
    this.setState({
      url: value,
      urlValid: Boolean(value)
    });
  }

  _handleEmailChange(value){
    this.setState({
      email: value,
      emailValid: isEmailValid(value)
    })
  }

  _handleSubmit(event){
    event.preventDefault();
    if(this._isValid()){
      this.props.onSubmit({
        url: this.state.url,
        email: this.state.email,
      });
    }
  }

  _isValid(){
    return(this.state.urlValid && this.state.emailValid);
  }

  _renderBeforeSend(){
    return(
      <div className="modal-content">
        <div className="modal-header">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <MaterialIcon name="close" />
          </button>
          <div className="modal-title">Can't find a component? Send us some details and we'll add it ASAP</div>
        </div>
        <form onSubmit={this._handleSubmit.bind(this)}>
          <div className="modal-body">
            <Input ref="url" icon="link" value={this.state.url} label="Part url" onChange={this._handleUrlChange.bind(this)} />
            <Input ref="email" value={this.state.email} icon="mail_outline" label="Your email address" type="email" onChange={this._handleEmailChange.bind(this)} />
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-default" data-dismiss="modal">Cancel</button>
            <button type="submit" className="btn btn-primary" disabled={!this._isValid()}>Submit</button>
          </div>
        </form>
      </div>
    );
  }
  
  _renderAfterSend(){
    return(
      <div className="modal-content">
        <div className="modal-header">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <MaterialIcon name="close" />
          </button>
        </div>
        <form onSubmit={this._handleSubmit.bind(this)}>
          <div className="modal-body">
            Thank you for your suggestion!
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
          </div>
        </form>
      </div>
    );
  }

  render(){
    return(
      <Modal modalClass="new-component-modal"
         isOpen={this.props.isOpen} 
         onClose={this.props.onClose}
         fade={true}>
        <div className="modal-dialog">
          {this.props.isSubmitted ? this._renderAfterSend() : this._renderBeforeSend()}
        </div>
      </Modal>
    );
  }
}

NewComponentModal.displayName = "NewComponentModal";
NewComponentModal.propTypes = {
  isOpen: React.PropTypes.bool.isRequired,
  isSubmitted: React.PropTypes.bool,
  onClose: React.PropTypes.func,
  onSubmit: React.PropTypes.func,
}

const mapStateToProps = ({requests}) => {
  return({
    isOpen: requests.newComponentModalIsOpen,
    isSubmitted: requests.newComponentModalIsSubmitted,
  })
}

const mapDispatchToProps = (dispatch) => {
  return{
    onClose: ()=>{
      dispatch(newComponentModalClose());
    },
    onSubmit: (data)=>{
      dispatch(newComponentModalSubmit(data));
    },
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(NewComponentModal);
