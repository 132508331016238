import * as types from '../constants';

/**
 * Closes the component context menu
 * @return {function} a function for redux-thunk middleware
 */
export default function(contextMenuPosition){
  return (dispatch, getStateFunc) => {
    //let lastContextMenuPosition = getStateFunc().requests.componentContextMenuPos;
    let isOpen = getStateFunc().requests.isComponentContextMenuOpen;

    // We close the context menu only if the current open one is in the same place as this.
    // To avoid the race condition when we close it at one place because we opened at another and the close action
    // happens after the d3's open action
    //if (lastContextMenuPosition.x === contextMenuPosition.x && lastContextMenuPosition.y === contextMenuPosition.y) {
    if(isOpen){
      dispatch({
        type: types.COMPONENT_CONTEXT_MENU_CLOSE,
      });
    }
    //}
  }
}
