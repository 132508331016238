import React from 'react';

const IconAddFacebook = ()=>{
  return(
    <svg className='icon icon-facebook' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path d="M24,4H8A4,4,0,0,0,4,8V24a4,4,0,0,0,4,4H24a4,4,0,0,0,4-4V8A4,4,0,0,0,24,4Zm-3.56,6.61h-2a1,1,0,0,0-1,1V14H20.1l-0.34,2.73H17.37v7.86H14.29V16.76H11.56V14h2.73V11.41A3.53,3.53,0,0,1,17.9,7.88h2.54v2.73Z"/>
    </svg>
  )
}

export default IconAddFacebook;
