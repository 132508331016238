import React from 'react';

const IconInfo = ()=>{
  return(
    <svg className='icon icon-info' viewBox="0 0 8 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Card" transform="translate(-12.000000, -114.000000)">
                <g id="info_button" transform="translate(4.000000, 112.000000)">
                    <path d="M8.18310547,18.9067383 L9.90429688,18.5405273 L9.90429688,10.3496094 L8,9.98339844 L8,7.83496094 L13.46875,7.83496094 L13.46875,18.5405273 L15.1777344,18.9067383 L15.1777344,21.0429688 L8.18310547,21.0429688 L8.18310547,18.9067383 Z M13.46875,4.67333984 L9.90429688,4.67333984 L9.90429688,2 L13.46875,2 L13.46875,4.67333984 Z" id="Icon_info" fill="#FFFFFF"></path>
                    <rect id="bg" x="0" y="0" width="24" height="24"></rect>
                </g>
            </g>
        </g>
    </svg>
  )
}

export default IconInfo;
