import * as types from '../constants';

export default function(isOpen){
  return (dispatch, getStateFunc) => {
  	if (getStateFunc().requests.isSchematicSettingsMenuOpen !== isOpen) {
      dispatch({
        type: types.SCHEMATICS_SETTINGS_MENU_TOGGLE,
        isOpen,
      });
	}
  }
}
