import React from 'react';
import MediaQuery from 'react-responsive';
import {ON_DESKTOP_MQ, ON_MOBILE_MQ} from '../../config'

class WalkthroughBasicStep extends React.Component{
  render(){
    let imgPath = `/static/images/walkthough_step_${this.props.step}`;
    return(
      <div className="walkthrough-modal-step-content">
        <MediaQuery query={ON_DESKTOP_MQ}>
          <img className="walkthrough-modal-step-image" src={imgPath+'.png'}/>
          <h2 className="text-app-header-2-bold">{this.props.title}</h2>
          <p className="text-app-body-2">{this.props.text}
            {this._getTermsStep() }
          </p>
        </MediaQuery>
        <MediaQuery query={ON_MOBILE_MQ}>
          <img className="walkthrough-modal-step-image mobile" src={imgPath+'_mobile.png'} style={{height: this.props.illustrationSize}} />
          {this.props.title ? <h2 className="text-app-header-2-bold mobile">{this.props.title}</h2> : null}
          <p className="text-app-body-2 mobile">
            {this.props.textMobile || this.props.text}
            {this._getTermsStep() }
          </p>
        </MediaQuery>
        {this.props.children}
      </div>
    );
  }

  _getTermsStep(){
    if((this.props.isTermsStep)){
      return (
        <span className="agree-to-terms">
          <a href={window.userAgent.browser.name === "Edge" || window.userAgent.browser.name === "IE" ?
            'https://s3-eu-west-1.amazonaws.com/storage.circuito.io/terms.html' : "/terms"}>
            Terms Of Service
          </a>
        </span>)
    }
  }
}

WalkthroughBasicStep.displayName = 'WalkthroughBasicStep';
WalkthroughBasicStep.propTypes = {
  isTermsStep:React.PropTypes.bool,
  title: React.PropTypes.string,
  text: React.PropTypes.string.isRequired,
  textMobile: React.PropTypes.string,
  step: React.PropTypes.number.isRequired,
  illustrationSize: React.PropTypes.number,
  children: React.PropTypes.array,
}

WalkthroughBasicStep.defaultProps = {
  title: null,
  textMobile: null,
}

export default WalkthroughBasicStep;
