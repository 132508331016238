import React from 'react';

const IconHackster = ()=>{
	return(
		<svg className='icon icon-hackster' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
			<title>Circuito Assets</title>
			<rect id="_Path_" data-name="&lt;Path&gt;" className="cls-1" x="4" y="4" width="24" height="24" rx="4" ry="4"/>
			<path className="cls-2" d="M9.35,14.82c-0.43,0-.87,0-1.3,0a0.78,0.78,0,0,1-.29-0.16A0.77,0.77,0,0,1,8,14.41c0.45-.14.91-0.26,1.36-0.38l4.1-1.12a1.9,1.9,0,0,1,.24-0.07c0.29,0,.38,0,0.39.32s0,0.7,0,1.06c0,0.54.08,0.61,0.62,0.61h5.72a0.35,0.35,0,0,1,.37.39c0,0.37,0,.75,0,1.12,0,0.74,0,.74.77,0.74h2.05a1.17,1.17,0,0,1,.37,0,0.49,0.49,0,0,1,.25.22,0.41,0.41,0,0,1-.17.22,0.91,0.91,0,0,1-.29.11l-5.41,1.47c-0.35.09-.43,0-0.44-0.36s0-.7,0-1.06-0.11-.53-0.57-0.53H11.7c-0.4,0-.47-0.08-0.48-0.47s0-.83,0-1.24-0.13-.62-0.62-0.63H9.35Z"/>
			<path className="cls-2" d="M20.8,11.47c0,0.66,0,1.33,0,2,0,0.4-.08.48-0.46,0.49q-1,0-1.93,0c-0.4,0-.47-0.08-0.47-0.48q0-1.62,0-3.23a0.5,0.5,0,0,1,.45-0.63c0.67-.2,1.35-0.38,2-0.55,0.27-.07.36,0,0.39,0.3s0,0.37,0,.56Q20.8,10.69,20.8,11.47Z"/>
			<path className="cls-2" d="M11.22,20.53c0-.66,0-1.33,0-2,0-.4.08-0.47,0.48-0.48q1,0,1.93,0c0.38,0,.46.08,0.46,0.49q0,1.62,0,3.23a0.5,0.5,0,0,1-.46.62c-0.61.18-1.23,0.35-1.84,0.5s-0.55.1-.56-0.45S11.22,21.17,11.22,20.53Z"/>
			<path className="cls-2" d="M17.85,20.47c0-.08,0-0.16,0-0.25a0.47,0.47,0,0,1,.53-0.53c0.66,0,1.32,0,2,0a0.37,0.37,0,0,1,.43.33,0.42,0.42,0,0,1-.32.51c-0.73.2-1.45,0.41-2.18,0.6a0.31,0.31,0,0,1-.43-0.3,2.78,2.78,0,0,1,0-.37h0Z"/>
			<path className="cls-2" d="M12.59,12.32H11.66A0.36,0.36,0,0,1,11.22,12a0.42,0.42,0,0,1,.33-0.52l2.2-.6a0.24,0.24,0,0,1,.34.24s0,0,0,.06c0,1.27.09,1.15-1.06,1.15H12.59Z"/>
		</svg>
	);
}

export default IconHackster;