import Cookies from 'universal-cookie';
import { LOCATION_CHANGE } from 'react-router-redux';
import { NotificationTypes } from './actions/show_notification';
import { getQueryParamSingle, hasQueryParam } from '../utils';
import * as types from './constants';
import * as requestsTypes from '../requests/constants';
import * as componentRequestsTypes from '../component_requests/constants';
import UAParser from 'ua-parser-js';
let isTermsAgreed = new Cookies().get('isTermsAgreed');
if (typeof(isTermsAgreed) === 'undefined' || isTermsAgreed === 'false') {
  isTermsAgreed = false;
}
else {
  isTermsAgreed = true;
}

if (hasQueryParam('u') || window.location.href.indexOf('robo-admin') != -1 ) {
  window.username = getQueryParamSingle('u');
}

let showTutorial = false;
if  (!isTermsAgreed) {
  showTutorial = true; // Real first-timers
}

window.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent);
window.userAgent = new UAParser().getResult();

const initialState = {
  showPartsPanel:false,
  showSEOTitle:true,
  headerModalIsOpen: false,
  isDesktopLoading: true,
  isSidebarOpen: false,
  isNotificationVisible: false,
  isNotificationAutoDismissed: false,
  notificationID: 0,
  notificationType: NotificationTypes.NOTIFICATION_GENERAL_TYPE,
  notificationText: '',
  notificationDetails: null,
  notificationModalIsOpen: false,
  validationData: null,
  shouldRefreshCircuit: false,
  searchQuery: null,
  showTutorial,
  isTermsAgreed,
}

export default function(state = initialState, action){
  switch(action.type){
    case LOCATION_CHANGE:
      return({
        ...state,
        headerModalIsOpen: false,
        isSidebarOpen: false,
        showSEOTitle:( (action.payload.search === state.searchQuery || state.searchQuery === null) ? true : false ),
        searchQuery:action.payload.search,
        showPartsPanel:(action.payload.pathname.includes('app')) ? true : false,
        validationData: window.location.pathname.includes('app') ? state.validationData : null,
        shouldRefreshCircuit: !window.location.pathname.includes('app') ? true : state.shouldRefreshCircuit,
      })
    case types.HEADER_VIDEO_OPEN:
      return({
        ...state,
        headerModalIsOpen: true
      });
    case types.HEADER_VIDEO_CLOSE:
      return({
        ...state,
        headerModalIsOpen: false
      });
    case types.SIDEBAR_TOGGLE:
      return({
        ...state,
        isSidebarOpen: !state.isSidebarOpen
      });
    case types.SIDEBAR_CLOSE:
      return({
        ...state,
        isSidebarOpen: false
      });
    case types.LOAD_STATIC_PAGE_CONTENT_REQUEST:
      return({
        ...state,
        staticPageContent: "",
      });
    case types.LOAD_STATIC_PAGE_CONTENT_SUCCESS:
      return({
        ...state,
        isDesktopLoading: false,
        staticPageContent: action.staticPageContent,
      });
    case types.SHOW_NOTIFICATION:
      return({
        ...state,
        isNotificationVisible: true,
        notificationType: action.notificationType,
        notificationText: action.notificationText,
        notificationDetails: action.notificationDetails,
        isNotificationAutoDismissed: action.isNotificationAutoDismissed,
        notificationID: ++state.notificationID,
      });
    case types.HIDE_NOTIFICATION:
      return({
        ...state,
        isNotificationVisible: false,
      });
    case(types.NOTIFICATION_MODAL_OPEN):
      return{
        ...state,
        notificationModalIsOpen: true,
      }
    case(types.NOTIFICATION_MODAL_CLOSE):
      return{
        ...state,
        notificationModalIsOpen: false,
      }
    case requestsTypes.VALIDATE_COMPONENT_REQUEST:
      return({
        ...state,
        shouldRefreshCircuit: false,
      });
    case requestsTypes.VALIDATE_COMPONENT_SUCCESS:
      return({
        ...state,
        validationData: action.data,
        isDesktopLoading: false,
      });
    case types.AGREE_TO_TERMS:
      return ({
        ...state,
        isTermsAgreed: !state.isTermsAgreed
      });
    case types.SHOW_DESKTOP_LOADER:
      return({
        ...state,
        isDesktopLoading: action.isShow,
      });
    case requestsTypes.UPDATE_COMPONENT_DEF_REQUEST:
      return({
        ...state,
        isDesktopLoading: true,
      });
    case componentRequestsTypes.LOAD_COMPONENT_REQUEST_SUCCESS:
    case componentRequestsTypes.LOAD_COMPONENT_REQUESTS_SUCCESS:
    case requestsTypes.SEED_COMPONENTS_SUCCESS:
    case requestsTypes.SEED_COMPONENTS_FAILURE:
    case requestsTypes.VALIDATE_COMPONENT_FAILURE:
    case types.LOAD_STATIC_PAGE_CONTENT_FAILURE:
    case requestsTypes.SOLVER_ERROR:
    case requestsTypes.UPDATE_COMPONENT_DEF_SUCCESS:
    case requestsTypes.UPDATE_COMPONENT_DEF_FAILURE:
      return({
        ...state,
        isDesktopLoading: false,
      })
    default:
      return({
        ...state,
      });
  }
}
