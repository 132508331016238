import React from 'react';

const IconGithub = () => {
    return ( 
    <svg className='icon icon-github' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <rect width="24" height="24" fill="#5D5E5E" x="4" y="4" rx="4" ry="4"/>
            <path fill="#FFF" d="M7,16.025a9.001,9.001 0 0 0 6.155,8.54c0.45,0.082 0.614,-0.196 0.614,-0.434c0,-0.214 -0.007,-0.78 -0.012,-1.53c-2.503,0.543 -3.031,-1.207 -3.031,-1.207c-0.41,-1.04 -1,-1.317 -1,-1.317c-0.817,-0.558 0.062,-0.547 0.062,-0.547c0.903,0.064 1.378,0.928 1.378,0.928c0.803,1.375 2.107,0.978 2.62,0.747c0.082,-0.581 0.314,-0.978 0.571,-1.203c-1.998,-0.227 -4.1,-0.999 -4.1,-4.448c0,-0.982 0.351,-1.786 0.927,-2.415c-0.093,-0.227 -0.402,-1.143 0.089,-2.382c0,0 0.755,-0.242 2.474,0.923a8.62,8.62 0 0 1 2.253,-0.303a8.63,8.63 0 0 1 2.253,0.303c1.719,-1.165 2.473,-0.923 2.473,-0.923c0.491,1.24 0.182,2.155 0.09,2.382c0.577,0.629 0.925,1.433 0.925,2.415c0,3.458 -2.105,4.219 -4.11,4.441c0.323,0.278 0.611,0.827 0.611,1.667c0,1.203 -0.01,2.174 -0.01,2.469c0,0.24 0.162,0.52 0.618,0.433a9,9 0 1 0 -11.85,-8.539Z" id="svg_2"/>
    </svg>
    )
}

export default IconGithub;