import { COMPONENT_INFO_MODAL_OPEN } from '../constants';

export default function(component, isOpenedFromInventory){
  return (dispatch) => {
  	analyticsSimple('Info Click', 'Info Click', 'Click', `${component.name} Info`, true);

  	dispatch({
      type: COMPONENT_INFO_MODAL_OPEN,
      component,
      isOpenedFromInventory,
    });
  }
}
