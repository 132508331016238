export const REQUEST_SUBMIT_TIMEOUT = 120000;
export const ON_MOBILE_MQ = '(max-device-width: 991px)';
export const ON_DESKTOP_MQ = '(min-device-width: 992px)';
export const ON_MAX_MD_WIDTH = '(max-width: 991px)';
export const ON_MIN_MD_WIDTH = '(min-width: 992px)';
export const NOTIFICATION_AUTO_DISMISS_TIME = 12000;
export const COMPONENT_TYPES = ['input', 'output', 'controller', 'power', 'connectivity'];
export const SWITCHABLE_COMPONENT_TYPES = ['controller','power'];
export const INITIAL_COMPONENT_IDS = ['512', '11021']; // ArduinoUno, USBPowerB
export const INITIAL_CONTROLLER_NAME = 'ArduinoUno';
export const MAX_COMPONENTS_MAP = {
  'input': 10,
  'output': 10,
  'connectivity': 10,
}
