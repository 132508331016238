import React from 'react';
import {withFauxDOM} from 'react-faux-dom';
import * as d3 from 'd3';
import { roundPathCorners, isMagicLink, getTrackDebouncePanZoomFunc } from '../../utils.js';
const _ = require('lodash');

class SVGBuilder extends React.Component {
  constructor(props) {
    super(props);

    this._setHoverEffect = this._setHoverEffect.bind(this);
    this._renderWires = this._renderWires.bind(this);
    this._renderCircles = this._renderCircles.bind(this);
    this._renderComponents = this._renderComponents.bind(this);
    this._rerender = this._rerender.bind(this);
    this._updateViewBoxWithComponent = this._updateViewBoxWithComponent.bind(this);
    this._onComponentHoverEnter = this._onComponentHoverEnter.bind(this);
    this._onComponentHoverExit = this._onComponentHoverExit.bind(this);
    this._onMouseMove = this._onMouseMove.bind(this);
    this._animateOnFauxDOM = this._animateOnFauxDOM.bind(this);
    this._connectAndLabelSVG = this._connectAndLabelSVG.bind(this);
    this._loadSVG = this._loadSVG.bind(this);
    this.circleTextOffset = 30;
    this.rowSpacing = 150;
    this.circleRaduis = 60;
    this.circleTextOffset = 30;
    this.maxStringLength = 35;
    this.labelFontSize = 85;
    this.legendFontSize = 100;
    this.trackPanZoomDebounceFn = getTrackDebouncePanZoomFunc();
    this.pan = { x:0, y:0 };
    this._resetZoom = this._resetZoom.bind(this);
    this. panZoomSelector = '#root';
    this.svgSelection = null;
    this.prevTouchPos = {x: 0, y: 0};

    this.isFaux = true;
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if(isSafari || window.isMobile){
      this.isFaux = false;
    }
  }

  componentDidMount() {
    this.zoom = this.props.zoom;

    if (this.isFaux) {
      this.faux = this.props.connectFauxDOM('div', 'chart');
    }
    else {
      this.faux = this.refs.svg;
    }

    d3.select(this.faux)
      .attr("class", 'fullWidth flex')
      .append("svg")
        .attr("id", "root")
        .attr("class", "maxFullHeightWidth margin-auto")
        .on('touchstart', ()=> {
          this.prevTouchPos = {
            x: d3.event.touches[0].pageX,
            y: d3.event.touches[0].pageY,
          }
        })
        .call(d3.zoom()
        .on("zoom", ()=> {
          if(!d3.event.sourceEvent){
            return false;
          }

          let currentVal = d3.select(this.faux).select(this.panZoomSelector).attr("transform");
          let currentValStr = currentVal.substring(currentVal.indexOf('(') , currentVal.indexOf(')') + 1 )
          let baseCord = currentValStr.substr(1, currentValStr.length -2).split(',');
          let maxCords = {
            y: (this.props.builderSize.height * 0.7),
            x: (this.props.builderSize.width * 0.7),
          }
          let newCord = {};
          let touchDelta = {};

          if(d3.event.sourceEvent.touches){
            touchDelta = {
              x: d3.event.sourceEvent.touches[0].pageX - this.prevTouchPos.x,
              y: d3.event.sourceEvent.touches[0].pageY - this.prevTouchPos.y,
            }

            // If the user pinched - the zoom changed
            if (d3.event.transform.k) {
              this.zoom = d3.event.transform.k;
              this.applyPanZoom(false);
            }

            // Update prev touches
            this.prevTouchPos.x = d3.event.sourceEvent.touches[0].pageX;
            this.prevTouchPos.y = d3.event.sourceEvent.touches[0].pageY;
          }else{
            touchDelta = {
              x: d3.event.sourceEvent.movementX,
              y: d3.event.sourceEvent.movementY
            }
          }

          newCord = {
            x: touchDelta.x  + parseInt(baseCord[0]),
            y: touchDelta.y + parseInt(baseCord[1]),
          }

          if(Math.abs(newCord.y) > maxCords.y){
              newCord.y = parseInt(baseCord[1]);
          }
          if(Math.abs(newCord.x) > maxCords.x){
            newCord.x = parseInt(baseCord[0]);
          }

          if(d3.event.sourceEvent.type === "wheel"){
            // Is it zoom in or zoom out? wheelDeltaY knows.
            if ((typeof(d3.event.sourceEvent.wheelDeltaY) !== 'undefined' &&
                  d3.event.sourceEvent.wheelDeltaY > 0)
                ||
                (typeof(d3.event.sourceEvent.deltaY) !== 'undefined' &&
                  d3.event.sourceEvent.deltaY < 0)
                ||
                (typeof(d3.event.sourceEvent.detail) !== 'undefined' &&
                  d3.event.sourceEvent.detail > 0)) {
              this.props.onZoomIn('mouse');
            }
            else {
              this.props.onZoomOut('mouse');
            }
          }
          else{
            this.pan.x = newCord.x;
            this.pan.y = newCord.y;

            this.applyPanZoom(true);

            this.trackPanZoomDebounceFn('Pan', 'SVG viewer', 'Click', 'Use pan', true);

          }
        }))
        .attr("version", "1.2")
        .attr("baseProfile", "tiny")
        .attr("transform","translate(0,0) scale(1)")
        .attr("style","translate(0,0) scale(1)")
        .append('g')
          .attr("id", "main-group")
          .attr("class", "maxFullHeightWidth margin-auto")
          .attr("transform","translate(0,0) scale(1)")

    this._rerender();

    // If the zoom is not 1, it should be at this point
    if (this.zoom != 1) {
      this._resetZoom();
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (!this.props.validationData) {
      return;
    }

    let liveSchemeData = this.props.validationData.output.liveSchemeGroups;
    let fadeCanvasChanged = (prevProps.fadeCanvasOpacity !== this.props.fadeCanvasOpacity);

    if (fadeCanvasChanged || liveSchemeData !== prevProps.validationData.output.liveSchemeGroups
        || !_.isEqual(this.props.svgHoverIds, prevProps.svgHoverIds)) {
      let newHoversIds = {enter: null, exit: null};

      if (!_.isEqual(this.props.svgHoverIds.enter, prevProps.svgHoverIds.enter)) {
        newHoversIds.enter = this.props.svgHoverIds.enter;
      }

      if (!_.isEqual(this.props.svgHoverIds.exit, prevProps.svgHoverIds.exit)) {
        newHoversIds.exit = this.props.svgHoverIds.exit;
      }

      this._rerender(newHoversIds);
      this._resetZoom();
    }
    // If an outside zoom action happened, we apply it with d3
    else if (!_.isEqual(prevProps.zoom, this.props.zoom)) {
      this.zoom = this.props.zoom;
      this.applyPanZoom(false);
    }
  }

  _animateOnFauxDOM(time) {
    this.props.animateFauxDOM(time);
  }

  _rerender(newHoversIds) {
    if (!this.props.validationData) {
      return;
    }

    let liveSchemeData = this.props.validationData.output.liveSchemeGroups;

    this.svgWidth = 200;
    this.svgHeight = 200;
    this.svgX = -200;
    this.svgY = -400;

    this.pinDict = {};
    this.countDict = {};

    var svgSelection = d3.select(this.faux).select('#root').select('#main-group');
    svgSelection.attr("opacity", (this.props.fadeCanvasOpacity
                                  && !isMagicLink()) ? "0.5" : "1");

    var rootSelection = d3.select(this.faux).select('#root');

    if (liveSchemeData.hasOwnProperty('components')) {
      this._renderComponents(svgSelection);
    }

    if (liveSchemeData.hasOwnProperty('wires')) {
        this._renderWires(svgSelection);
    }

    // Apply external hover requests, like from hovering a bom entry
    if (!_.isUndefined(newHoversIds) && !_.isEmpty(newHoversIds.enter)) {
      newHoversIds.enter.map((id)=> this._setHoverEffect(id, svgSelection, true, true));
    }
    if (!_.isUndefined(newHoversIds) &&  !_.isEmpty(newHoversIds.exit)) {
      newHoversIds.exit.map((id)=> this._setHoverEffect(id, svgSelection, true, false));
    }

    rootSelection
      .attr("width", `${((this.svgWidth - this.svgX) / 1000).toFixed(4)}in`)
      .attr("height", `${((this.svgHeight - this.svgY ) / 1000).toFixed(4)}in`)
      .attr("viewBox", `${this.svgX.toFixed(4) - 100} ${this.svgY.toFixed(4) - 100} ${(this.svgWidth - this.svgX + 200).toFixed(4)} ${(this.svgHeight - this.svgY + 200).toFixed(4)}`)
  }

  _renderWires(svgSelection) {
    let liveSchemeData = this.props.validationData.output.liveSchemeGroups;

    svgSelection.selectAll('.wire').remove();

    var wireSelection = svgSelection.selectAll('.wire').data(liveSchemeData.wires).enter()
    .append('g')
      .attr("class", (wire)=>`wire ${wire.groupName} ${wire.wireID}`)
      .on("mouseover", (wire) => {
        this.props.onShowTooltip(true, wire.desc);
        if (this.isFaux){
          this.svgSelection.selectAll('g.wire.'+wire.wireID).attr('filter', 'url(#wirehover)');
          this.props.animateFauxDOM(200);
        }
        else {
          let rootEl = this.svgSelection["_groups"][0][0];
          let el = rootEl.getElementsByClassName(`wire ${wire.wireID}`)[0];
          if(el){
            el.setAttribute('filter', 'url(#wirehover)');
          }
        }
      })
      .on("mousemove", this._onMouseMove)
      .on("mouseout", (wire) => {
        this.props.onShowTooltip(false);
        if (this.isFaux) {
          this.svgSelection.selectAll('g.wire.'+wire.wireID).attr('filter', '');
          this.props.animateFauxDOM(200);
        }
        else {
          let rootEl = this.svgSelection["_groups"][0][0];
          let el = rootEl.getElementsByClassName(`wire ${wire.wireID}`)[0];
          if(el){
            el.setAttribute('filter', '');
          }
        }
      });

    this._renderCircles(wireSelection, false);
    wireSelection.append("path")
      .attr("class", (wire) => `wire ${wire.groupName}`)
      .attr("stroke-linecap", "round")
      .attr("stroke", (wire) => wire.color)
      .attr("stroke-width", 31.9444)
      .attr("fill", "none")
      .attr("d", (wire) => {
        // Building the path tag
        let pathString = `M ${wire.wayPoints[0][0]} ${wire.wayPoints[0][1]}`;
        this._updateViewBoxWithWirePoint(wire.wayPoints[0]);

        for (let i = 1; i < wire.wayPoints.length; i++) {
          pathString += ` L ${wire.wayPoints[i][0]} ${wire.wayPoints[i][1]}`;

          this._updateViewBoxWithWirePoint(wire.wayPoints[i]);
        }

        // Than rounding it's edges
        return roundPathCorners(pathString, 50, false);
      });

    this._renderCircles(wireSelection, true);
  }

  _renderCircles(selection, isWireEnd) {
    let getWayPoint = function(wire) {
        if (isWireEnd) {
          return wire.wayPoints[wire.wayPoints.length - 1];
        }

        return wire.wayPoints[0];
    };

    selection.append("circle")
      .attr("class", (wire) => `wire ${wire.groupName}`)
      .attr("cx", (wire) => getWayPoint(wire)[0])
      .attr("cy", (wire) => getWayPoint(wire)[1])
      .attr("r", 30)
      .attr("stroke", (wire) => wire.color)
      .attr("stroke-width", 7)
      .attr("fill", (wire) => wire.color);
  }

  _updateViewBoxWithWirePoint(point) {
    this.svgX = Math.min(this.svgX, point[0]);
    this.svgY = Math.min(this.svgY, point[1]);

    this.svgWidth  = Math.max(this.svgWidth, point[0]);
    this.svgHeight = Math.max(this.svgHeight, point[1]);
  }

  _renderComponents(svgSelection) {
    let liveSchemeData = this.props.validationData.output.liveSchemeGroups;

    let flatSchemeData = Object.values(liveSchemeData.components).reduce((flatArray, componentsArray) => {
      // Breadboard should be first
      if (componentsArray[0].name === 'BreadBoard') {
        flatArray.unshift.apply(flatArray, componentsArray);
      }
      else {
        flatArray.push.apply(flatArray, componentsArray);
      }
      return flatArray;
    }, []);

    this.svgSelection = svgSelection;

    var componentsSelection = svgSelection.selectAll('.component')
      .data(flatSchemeData, (component) => component.groupName);

    componentsSelection.exit().remove();

    let _setMouseActions = (selection) => {
      selection
      .on("mouseover", (comp) => this._onComponentHoverEnter(comp))
      .on("mouseout", (comp) => this._onComponentHoverExit(comp))
      .on("mousemove", this._onMouseMove)
      .on("click", (comp) => this.props.onComponentClicked(comp, this.isFaux,d3.event));
    }

    let addedComponentsSel = componentsSelection.enter().append('g')
      .attr('id', (comp) => comp.compID)
      .attr('class', (comp) => `component ${comp.name} ${comp.groupName}`)
      .attr('data-comp-name', (comp) => `${comp.name}`)
      .attr('transform', (c) => `translate(${c.position[0]},${c.position[1]}) rotate(${c.rotation}) scale(${c.scaleFactor})`);

    componentsSelection.selectAll('.component-image')
      .data(flatSchemeData, (comp)=> comp.groupName);

    if (window.isMobile) {
      addedComponentsSel.on('touchstart', this._onMouseMove);
    }

    if (this.isFaux) {
       let svgImageSel = addedComponentsSel.append('svg:image')
         .attr('id', (comp) => comp.compID+'-'+comp.groupName)
         .attr('class', (comp) => `component-image ${comp.groupName}`)
         .attr('width', (comp) => comp.viewBox[2])
         .attr('height', (comp) => comp.viewBox[3])
         .attr('preserveAspectRatio','none');

       _setMouseActions(svgImageSel);
     }
     else {
       _setMouseActions(addedComponentsSel);

       addedComponentsSel
       .append('svg:foreignObject')
         .attr('id', (comp) => comp.compID+'-'+comp.groupName)
         .attr('class', (comp) => `component-image ${comp.groupName}`)
         .attr('width', (comp) => comp.viewBox[2])
         .attr('height', (comp) => comp.viewBox[3])
         .attr('viewBox', (comp) => `${comp.viewBox[0]}, ${comp.viewBox[1]}, ${comp.viewBox[2]}, ${comp.viewBox[3]}`)
         .attr('style','line-height:0px;')
     }

    componentsSelection
      .attr('transform', (c) => {
        return `translate(${c.position[0]},${c.position[1]}) rotate(${c.rotation}) scale(${c.scaleFactor})`
      })
      // Refreshing attributes that have something to do with the id, cause each iteration
      // same components (with same groupname) receive new ids.
      .attr('id', (c) => c.compID)
      .on("mouseover", (comp) => this._onComponentHoverEnter(comp))
      .on("mouseout", (comp) => this._onComponentHoverExit(comp));

    componentsSelection.selectAll('.component-image').attr('id', (c)=> c.compID+'-'+c.groupName);
    componentsSelection.enter().each((comp) => {
      var imgTest = new Image();
      imgTest.onload = () => {
        if (this.isFaux) {
          svgSelection.selectAll('#'+comp.compID).select('.component-image')
          .attr('xlink:href', imgTest.src);
        }
        else {
          var select = svgSelection._groups[0][0]
          var el = select.querySelector(`[id="${comp.compID}-${comp.groupName}"]`)
          var imageEl = document.createElement('img');
          imageEl.setAttribute('width','100%');
          imageEl.setAttribute('src', imgTest.src);

          if (el.childNodes.length > 0) {
            el.removeChild(el.childNodes[0]);
          }
          el.appendChild(imageEl);
        }
      }
      imgTest.onerror = () => {
        console.log('failed loading image ' + imgTest.src);
      }

      var src = `${comp.image}`;

      if(!_.isUndefined(comp.properties) && comp.properties.hasOwnProperty('resistance')){
          src += '?props='+Object.values(comp.properties)[0];
      }

      imgTest.src = src;
    });
  

    for(let comp of flatSchemeData) {
      this._updateViewBoxWithComponent(comp);
    }

    this._animateOnFauxDOM(2000);
  }

  _loadSVG(comp, svgSelection) {
    let xmlhttp = new XMLHttpRequest();
    let that = this;
    xmlhttp.open('GET', `/SVGImage/proMode=true/${comp.ref}/svg.svg`, true);
    xmlhttp.onreadystatechange = function() {
      if (this.readyState != 4 || this.status != 200) {
        return;
      }

      var svg = xmlhttp.responseXML.documentElement;
      svg.removeAttribute('height');
      svg.removeAttribute('width');
      svg.removeChild(svg.getElementsByTagName('title')[0]);

      var select = svgSelection._groups[0][0]
      var el = select.querySelector(`[id="${comp.compID}-${comp.groupName}"]`);
      if (el.childNodes.length > 0) {
        el.removeChild(el.childNodes[0]);
      }
      el.appendChild(svg);

      that._animateOnFauxDOM(2000);
    };
    xmlhttp.send(null);
  }

  _connectAndLabelSVG(svg, comp) {
    let innerPartsDict = comp.innerParts.reduce((dict, part)=> {
      dict[part.name] = part.value;
      return dict;
    }, {});

    for (var g of svg.getElementsByTagName('g')) {
      let label = '';

      try {
        if (g.getAttribute('id') === 'Labels') {
          for (var innerPart of g.childNodes) {
            for (label of innerPart.childNodes) {
              if (label.nodeType === label.ELEMENT_NODE && label.tagName === 'text') {
                let labelData = label.firstChild.data;

                if (labelData.startsWith('$')) {
                  // Remove $ and digits from label
                  let labelText = labelData.replace(/\$|[0-9]/g, '');
                  this.countDict[labelText] = (this.countDict[labelText] || 0) + 1;

                  if (labelData in innerPartsDict) {
                    //label.id = 'tspan-';
                    //tspans[]
                    label.appendChild(this._createTspanElement(innerPartsDict[labelData]))
                  }

                  label.firstChild.data = labelText + this.countDict[labelText];
                }
              }
            }
          }
        }
        else if (g.getAttribute('id') === 'Connectors') {
          for (var gElem of g.childNodes) {
            if (gElem.nodeType === gElem.ELEMENT_NODE) {
              let gElemData = gElem.firstChild.data;
              // Ordinary labels without a g element
              if (gElemData.startsWith('circuito')
                  && gElemData in this.pinDict[comp['compID']]) {
                gElem.firstChild.data = this.pinDict[comp['compID']][gElemData];
              }
              else {
                // Our new pin lebel elements inside a g
                for (var elem of gElem.childNodes) {
                  if (elem.hasChildNodes()) {
                    let elemData = elem.firstChild.data;

                    if (elemData.startsWith('circuito')
                        && elemData in this.pinDict[comp['compID']]) {
                      elem.firstChild.data = this.pinDict[comp['compID']][elemData];
                      this._setPinLabelAlignemnt(gElem);
                    }
                    else {
                      gElem.setAttribute('display', 'none');
                    }
                  }
                }
              }
            }
          }
        }
      }
      catch(e) {
        if (label !== '' && label.firstChild) {
          console.error(`Missing label data in ${comp.name}.json regarding ${label.firstChild.data}: ${e.message}`);
        }
        else {
          console.error('Error while labeling ' + comp.name + ': ' + e.message);
        }
      }
    }
  }

  /**
   * create a second line for 'value' inside the text element
   */
  _createTspanElement(value) {
    let tspanElement = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
    tspanElement.setAttribute('x', '0');
    tspanElement.setAttribute('y', '12');
    tspanElement.appendChild(document.createTextNode(value));

    return tspanElement;
  }

  /**
    This function aligns the new pin labels with their pin symbol
    the pin element is a text element and a symbol gathered in a <g>
    actions:
    * calculate the BBox of the symbol
    * understand the relative location of the text element
    * adjust the text element location
  **/
  _setPinLabelAlignemnt(gElem) {
    let MARGIN = 10;
    let textX = 0, textY = 0, lineMaxX = 0, lineMaxY = 0,
        lineMinX = 9999, lineMinY = 9999;
    let loc = [0,0];
    let textElem = null;

    for (var elem of gElem.childNodes) {
      if (elem.nodeType === elem.ELEMENT_NODE) {
        if (elem.tagName === 'text') {
          textElem = elem;
          if (textElem.baseURI.includes('LCD') && textElem.innerHTML === '3.3v_DigitalIn_1') {
            console.log('hello!');
          }
          let xy = elem.getAttribute('transform').split('scale')[0].replace(')','')
                    .split('(')[1].split(' ');

          textX = parseFloat(xy[0]);
          textY = parseFloat(xy[1]);
        }
        else if (elem.tagName === 'line') {
          lineMaxX = Math.max(lineMaxX, parseFloat(elem.getAttribute('x1')), parseFloat(elem.getAttribute('x2')));
          lineMinX = Math.min(lineMinX, parseFloat(elem.getAttribute('x1')), parseFloat(elem.getAttribute('x2')));
          lineMaxY = Math.max(lineMaxY, parseFloat(elem.getAttribute('y1')), parseFloat(elem.getAttribute('y2')));
          lineMinY = Math.min(lineMinY, parseFloat(elem.getAttribute('y1')), parseFloat(elem.getAttribute('y2')));
        }
      }
    }

    // Figure out where is the label compared to the symbol - U, D, R ,or L
    if (Math.abs(textY - lineMinY) >= MARGIN) { // D
      loc = [(lineMaxX + lineMinX) /2 , textY];
      textElem.setAttribute('text-anchor', 'middle');
    }
    else if (Math.abs(lineMaxY -textY) >= MARGIN) { // U
      loc = [(lineMaxX + lineMinX) /2 , textY];
      textElem.setAttribute('text-anchor', 'middle');
    }
    else if(Math.abs(lineMaxX - textX) >= MARGIN) { // L
      loc = [lineMinX - 5, lineMaxY];
      textElem.setAttribute('text-anchor', 'end');
    }
    else if (Math.abs(textX - lineMinX) >= MARGIN) { // R
      loc = [lineMaxX + 5, lineMaxY];
      textElem.setAttribute('text-anchor', 'start');
    }

    textElem.setAttribute('transform', `translate(${loc[0]},${loc[1]})`);
  }

  _createSVGPinLabelsDict(wires) {
    let utilDict = {};
    let attributeCount = {};

    for (var wire of wires) {
      attributeCount[wire['connectionAttribute']] = attributeCount[wire['connectionAttribute']] || 0;

      if (!wire.isBus && !['GND', '5v', '3.3v', '12v'].includes(wire['connectionAttribute'])) {
        attributeCount[wire['connectionAttribute']]++;
      }

      let attribNum = '';
      if (attributeCount.hasOwnProperty(wire['connectionAttribute'])
          && attributeCount[wire['connectionAttribute']] > 0) {
        attribNum = '_' + attributeCount[wire['connectionAttribute']];
      }

      let attribText = wire['connectionAttribute'] + attribNum;

      let srcModel = wire['src'][0];
      let dstModel = wire['dst'][0];
      let srcPinName = wire['src'][2];
      let dstPinName = wire['dst'][2];

      utilDict[srcModel] = utilDict[srcModel] || {};
      utilDict[srcModel][srcPinName] = attribText;

      utilDict[dstModel] = utilDict[dstModel] || {};
      utilDict[dstModel][dstPinName] = attribText;
    }

    return utilDict;
  }

  // Calculating any root viewbox changes that might be caused by the components' size and location
  _updateViewBoxWithComponent(component) {
    let compLoc;
    let compX = parseFloat(component.position[0]);
    let compY = parseFloat(component.position[1]);
    let scaleFactor = component.scaleFactor;
    let rotation = component.rotation || 0;
    let viewBox = component.viewBox;

    if (rotation === 90) {
      compLoc = [compX - viewBox[3] * scaleFactor, compY,
                  compX, compY + viewBox[2] * scaleFactor];
    }
    else if (rotation === 180) {
      compLoc = [compX - viewBox[2] * scaleFactor, compY - viewBox[3] * scaleFactor,
                  compX, compY];
    }
    else if (rotation === 270) {
      compLoc = [compX, compY - viewBox[2] * scaleFactor,
                  compX + viewBox[2] * scaleFactor, compY];
    }
    else {
        compLoc = [compX, compY,
                    compX + viewBox[2] * scaleFactor, compY + viewBox[3] * scaleFactor];
    }

    this.svgX = Math.min(this.svgX, compLoc[0]);
    this.svgY = Math.min(this.svgY, compLoc[1]);

    this.svgWidth  = Math.max(this.svgWidth, compLoc[2]);
    this.svgHeight = Math.max(this.svgHeight, compLoc[3]);
  }

  _setHoverEffect(id, svgSelection, isRelatedWiresToo, isHover) {
    if (this.isFaux) {
        svgSelection.selectAll('#'+id).select('.component-image').attr('filter', isHover ? 'url(#componenthover)' : '');
        if (isRelatedWiresToo) {
          svgSelection.selectAll('.wire.'+id).attr('filter', isHover ? 'url(#wirehover)' : '');
        }
    }
    else {
      let baseEl = svgSelection["_groups"][0][0].querySelector(`[id="${id}"]`)
      if (baseEl) {
        let el = baseEl.querySelector(`.component-image`).parentElement;
        if(el){
          el.setAttribute('filter', isHover ? 'url(#componenthover)' : '');
        }
      }

      if (isRelatedWiresToo) {
        let rootEl = svgSelection["_groups"][0][0];
        let wireEl = rootEl.getElementsByClassName(`wire ${id}`)[0];
        if(wireEl){
          wireEl.setAttribute('filter', isHover ? 'url(#wirehover)' : '');
        }
      }
    }
  }

  _onComponentHoverEnter(comp) {
    if (this.currentHoverName === comp.displayName) {
      return;
    }

    this.currentHoverName = comp.displayName;
    this.props.onShowTooltip(true, this.currentHoverName);

    this._setHoverEffect(comp.compID, this.svgSelection, false, true);
  }

  _onComponentHoverExit(comp) {
    this.currentHoverName = '';
    this.props.onShowTooltip(false);

    this._setHoverEffect(comp.compID, this.svgSelection, false, false);
  }

  _onMouseMove() {
    let pos = {};
    if(typeof(d3.event) !== 'undefined' && d3.event.touches){
      pos.x = d3.event.touches[0].pageX;
      pos.y = d3.event.touches[0].pageY;
    }else{
      pos.x = d3.event.x;
      pos.y = d3.event.y;
    }

    this.props.onMouseMove(pos);

    this._animateOnFauxDOM(2000);
  }

  applyPanZoom(isPan){
    if (isPan) {
      // No tooltip for now
      this.currentHoverName = '';
      this.props.onShowTooltip(false);
    }

    let d3Transform = "translate(" + this.pan.x + "," + this.pan.y + ") scale(" + this.zoom + ")";

    // When there's no faux, we update the transform through css style, so we need to in px units
    let transformPX = "translate(" + this.pan.x + "px ," + this.pan.y + "px) scale(" + this.zoom + ")";

    d3.select(this.faux)
      .select(this.panZoomSelector)
      .attr("transform", d3Transform)
      .attr("style", this.isFaux ? '' : "transform:" + transformPX);
    this._animateOnFauxDOM(2000);
  }

  _resetZoom(){
    this.pan = {x:0, y:0};
    this.props.onResetZoom(false);
  }

  resetZoom(){
    this.panZoom = {
      k:1,
      x:0,
      y:0
    }
    this.trackPanZoomDebounceFn();
    this.handleZoomPan();

    analyticsSimple('Zoom Reset', 'SVG viewer', 'Click', 'Reset zoom', true);
  }

  _renderSVGFilter(id, radiusText) {
    return(
      <filter id={id}>
        <feFlood floodColor="rgb(0,170,255)" result="base" />
        <feMorphology result="bigger" in="SourceGraphic" operator="dilate" radius={radiusText}/>
        <feColorMatrix result="mask" in="bigger" type="matrix"
          values="0 0 0 0 0
                  0 0 0 0 0
                  0 0 0 0 0
                  0 0 0 1 0"/>
        <feComposite result="drop" in="base" in2="mask" operator="in" />
        <feBlend in="SourceGraphic" in2="drop" mode="normal" />
      </filter>
    )
  }

  render() {
    return(
      <div className='fullHeightWidth flex'>
        <svg className="svg-hover-filter">
          {this._renderSVGFilter("componenthover", "3.5")}
          {this._renderSVGFilter("wirehover", "25")}
        </svg>
        {this.isFaux ? this.props.chart : <div ref="svg" className="svg-container"></div> }
      </div>
    )
  }
}

SVGBuilder.displayName = 'SVGBuilder';

SVGBuilder.propTypes = {
  validationData: React.PropTypes.object,
  chart: React.PropTypes.object,
  onComponentClicked: React.PropTypes.func,
  svgHoverIds: React.PropTypes.object,
  zoom: React.PropTypes.number,
  onZoomIn: React.PropTypes.func,
  onZoomOut: React.PropTypes.func,
  onZoomPinch: React.PropTypes.func,
  onResetZoom: React.PropTypes.func,
  onShowTooltip: React.PropTypes.func,
  onMouseMove: React.PropTypes.func,
  builderSize: React.PropTypes.object,
}

export default withFauxDOM(SVGBuilder);
