import React from 'react';
import {connect} from 'react-redux';
import MainHeader from '../containers/main_header';
import SideBar from './sidebar';
import Notification from "../containers/notification"
import SocialLinks from './social_links';
import MainNavButtons from './main_nav_buttons';
import DesktopLoader from './desktop_loader';
import validateComponents from '../../requests/actions/helpers/validate_components';
import { getInitialComponentIds } from '../../utils';

// Main app component
class App extends React.Component {
  render () {
    return(
      <div className={`desktop-container ${this.props.isSidebarOpen ? 'slide' : ''}`}>
        <MainHeader onAppRootClicked={this.props.validate}/>
        <div className='desktop'>
          {this.props.children}
          {this.props.isDesktopLoading ? <DesktopLoader/> : null}
        </div>
        <Notification />
        <SideBar>
          <nav>
            <MainNavButtons onAppRootClicked={this.props.validate}/>
          </nav>
          <span>
            <SocialLinks/>
          </span>
        </SideBar>
      </div>
    );
  }
}

App.displayName = 'App';
App.propTypes = {
  children: React.PropTypes.element.isRequired,
  isDesktopLoading: React.PropTypes.bool,
  isSidebarOpen: React.PropTypes.bool,
  validate: React.PropTypes.func,
  location: React.PropTypes.object,
}

const mapStateToProps = ({app})=>{
  return{
    isDesktopLoading: app.isDesktopLoading,
    isSidebarOpen: app.isSidebarOpen,
  }
};

const mapDispatchToProps = {
  validate: ()=> 
    (dispatch, getStateFunc) => 
      validateComponents(dispatch, getStateFunc, getInitialComponentIds()),
}

export default connect(mapStateToProps, mapDispatchToProps)(App);